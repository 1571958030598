import { action, makeObservable, observable } from 'mobx';

import {
  IAddNote,
  IArchive,
  IChangeStatus,
  ICreateSubmissionPayload,
  IDeleteNote,
  IDropNoteCounter,
  IGetOne,
  IUpdateNote,
  TicketSubmissionService,
} from './service';
import { CommonListAdvancedStore } from '@platform/front-lib';
import type {
  INote,
  ISupportTicketSubmission,
} from '@platform/front-lib/dist/models';
import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';

export class TicketSubmissionsStore extends CommonListAdvancedStore<
  TicketSubmissionService,
  ISupportTicketSubmission
> {
  service = new TicketSubmissionService();

  error: Record<string, any> | null | string = null;

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  private reset() {
    this.dataMap = {};
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  cleanError = () => {
    this.isError = false;
    this.error = null;
  };

  createSubmission = async (
    payload: ICreateSubmissionPayload,
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.service.createSubmission(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
    });
  };

  changeStatus = async (payload: IChangeStatus, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.changeStatus(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[response._id] = response;
      this.dataByRequestId['default'] =
        this.dataByRequestId['default']?.map?.((item) => {
          if (item._id === response._id) {
            return response;
          }

          return item;
        }) || [];
      meta?.successFunc?.();
    });
  };

  dropNoteCounter = async (payload: IDropNoteCounter, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.dropNoteCounter(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[payload.entityId] = {
        ...this.dataMap[payload.entityId],
        countNewUserNotes: 0,
      };
      this.dataByRequestId['default'] =
        this.dataByRequestId['default']?.map?.((item) => {
          if (item._id === payload.entityId) {
            return { ...item, countNewUserNotes: 0 };
          }

          return item;
        }) || [];
      meta?.successFunc?.();
    });
  };

  getOne = async (payload: IGetOne, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.getOne(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[response._id] = response;
      meta?.successFunc?.();
    });
  };

  archive = async (payload: IArchive, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.archive(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.();
    });
  };

  addNote = async (payload: IAddNote, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.addNote(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      if (Array.isArray(this.dataMap[payload.entityId].notesRefs)) {
        this.dataMap[payload.entityId].notesRefs?.push?.(response);
        this.dataMap[payload.entityId].notesRefs?.slice?.();
        meta?.successFunc?.();
        return;
      }

      this.dataMap[payload.entityId].notesRefs = [response];
      meta?.successFunc?.();
    });
  };

  updateNote = async (payload: IUpdateNote, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.updateNote(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      const notes: INote[] = this.dataMap[payload.entityId].notesRefs || [];
      if (Array.isArray(notes)) {
        this.dataMap[payload.entityId].notesRefs = notes.map((item) => {
          if (item._id === payload.noteId) {
            return {
              ...item,
              ...response,
            };
          }

          return item;
        });

        meta?.successFunc?.();
      }
    });
  };
  deleteNote = async (payload: IDeleteNote, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.deleteNote(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      if (Array.isArray(this.dataMap[payload.entityId].notesRefs)) {
        this.dataMap[payload.entityId].notesRefs = this.dataMap[
          payload.entityId
        ].notesRefs?.filter((note) => note._id !== payload.noteId);

        meta?.successFunc?.();
      }
    });
  };

  // common

  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };

  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };

  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor() {
    super({
      sessionKey: 'tickets-store',
    });
    this.service = new TicketSubmissionService();
    makeObservable(this, {
      //action
      setLoaded: action,
      setError: action,
      setLoading: action,
      deleteNote: action,
      addNote: action,
      archive: action,
      getOne: action,
      changeStatus: action,
      createSubmission: action,
      cleanError: action,
      dropNoteCounter: action,
      //computed

      //observable
      error: observable,
      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      isError: observable,
    });
  }
}
