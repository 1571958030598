import { createStyles, makeStyles, lighten } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { APP_BAR_HEIGHT } from '../../../styles/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(3),
      gridArea: 'header',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
      },

      minHeight: APP_BAR_HEIGHT,
      background: theme.palette.brand.light,
      boxShadow: `0px 1px 0px ${
        theme.palette.brand.main
          ? lighten(theme.palette.brand.main, 0.85)
          : 'rgba(244, 105, 77, 0.25)'
      }`,
    },
    rootGrid: {
      minHeight: APP_BAR_HEIGHT,
      height: '100%',
    },
    logoLink: {
      marginTop: theme.spacing(0.5),
      display: 'block',
    },
    logoLinkImg: {
      width: 139,
      height: 29,
      [theme.breakpoints.up('md')]: {
        width: 186,
        height: 40,
      },
    },
    menuRoot: {
      // position: 'absolute !important' as 'absolute',
    },
    headerLinks: {
      display: 'flex',
      justifyContent: 'space-between',
      '& a:after': {
        content: "'|'",
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        color: 'grey',
      },
      '& a:last-child::after': {
        display: 'none',
      },
    },

    textIconLeftMargin: {
      marginLeft: theme.spacing(1),
    },
    topButton: {
      position: 'relative',
      color: theme.palette.brand.main,
    },
    topButtonText: {
      marginRight: theme.spacing(1.5),
      color: theme.palette.primary.main,
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
    },
    iconGridDot: {
      maxHeight: 24,
      color: theme.palette.brand.dark,
      marginRight: theme.spacing(3),
    },
    topButtonDivider: {
      position: 'absolute',
      top: 0,
      left: theme.spacing(5),

      height: '100%',
      width: 1,
      backgroundColor: 'grey',
    },
  }),
);

export default useStyles;
