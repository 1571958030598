import * as yup from 'yup';
import { AnvUtils, Constants } from '@platform/front-lib';
import { validationsMessages } from '../../../../constants';

export interface IRequestAccessModuleScope {
  jurisdiction?: string | null;
  school?: string | null;
  schoolDepartment?: string | null;
  organization?: string | null;
  organizationSecondary?: string | null;
  tags?: string[] | Record<string, unknown>[];
}

export interface IRequestAccessModule {
  userEmail: string;
  tool: string;
  module: string;
  accessScope: string;
  reasonForRequest: string;

  accessExpirationDate: null | string | Date;
  scope: IRequestAccessModuleScope;
}

interface IValidationSchemaModuleRequest {
  accessScope?: string;

  validateDepartmentField: boolean;
  minExpirationDate: any;
  maxExpirationDate: any;
}
export const validationSchema = (
  fm: (arg: any, values?: any) => string,
  {
    accessScope,
    validateDepartmentField,
    maxExpirationDate,
    minExpirationDate,
  }: IValidationSchemaModuleRequest,
) => {
  const [dateMinMessage, dateMinValue] = validationsMessages.dateMinVal(
    AnvUtils.formatWLocale(minExpirationDate),
  );
  const [dateMaxMessage, dateMaxValue] = validationsMessages.dateMaxVal(
    AnvUtils.formatWLocale(maxExpirationDate),
  );

  const shape: Record<string, any> = {
    userEmail: yup
      .string()
      .email(fm(Constants.validationsMessages.email()))
      .required(fm(Constants.validationsMessages.required())),
    tool: yup.string().required(fm(Constants.validationsMessages.required())),
    module: yup.string().required(fm(Constants.validationsMessages.required())),
    accessScope: yup
      .string()
      .required(fm(Constants.validationsMessages.required())),

    reasonForRequest: yup
      .string()
      .required(fm(Constants.validationsMessages.required())),
    accessExpirationDate: yup
      .date()
      .min(minExpirationDate, fm(dateMinMessage, dateMinValue))
      .max(maxExpirationDate, fm(dateMaxMessage, dateMaxValue))
      .typeError(fm(Constants.validationsMessages.time()))
      .required(),
  };

  let scopeObjectShape: null | Record<string, any> = null;

  if (accessScope === Constants.ACCESS_SCOPES.SCHOOL) {
    scopeObjectShape = {
      jurisdiction: yup.string().nullable(),
      organization: yup.string().nullable(),
      // public/private tag
      school: yup
        .string()
        .required(fm(Constants.validationsMessages.required())),
    };

    if (validateDepartmentField) {
      scopeObjectShape.schoolDepartment = yup
        .string()
        .required(fm(Constants.validationsMessages.required()));
    }
  }

  if (accessScope === Constants.ACCESS_SCOPES.AUTHORIZER) {
    scopeObjectShape = {
      jurisdiction: yup.string().nullable(),
      organization: yup
        .string()
        .required(fm(Constants.validationsMessages.required())),
    };
  }

  if (
    accessScope === Constants.ACCESS_SCOPES.ESC ||
    accessScope === Constants.ACCESS_SCOPES.EMO
  ) {
    scopeObjectShape = {
      organization: yup
        .string()
        .required(fm(Constants.validationsMessages.required())),
    };
  }

  if (accessScope === Constants.ACCESS_SCOPES.LEA) {
    scopeObjectShape = {
      jurisdiction: yup.string().nullable(),
      organization: yup
        .string()
        .required(fm(Constants.validationsMessages.required())),
    };
  }
  if (accessScope === Constants.ACCESS_SCOPES.DEPARTMENT) {
    scopeObjectShape = {
      jurisdiction: yup.string().nullable(),
      organization: yup
        .string()
        .required(fm(Constants.validationsMessages.required())),
    };
  }
  if (accessScope === Constants.ACCESS_SCOPES.SEA) {
    scopeObjectShape = {
      jurisdiction: yup.string().nullable(),
      organization: yup
        .string()
        .required(fm(Constants.validationsMessages.required())),
    };
  }

  if (scopeObjectShape) {
    shape.scope = yup
      .object()
      .shape(scopeObjectShape)
      .required(fm(Constants.validationsMessages.required()));
  }

  return yup.object().shape({
    ...shape,
  });
};
