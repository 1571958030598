import { formatDistanceStrict } from 'date-fns';

export const customContentItemRender: Record<string, any> = {
  averageResponseDuration: (value?: number | null) => {
    if (!value) {
      return '-';
    }

    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        {formatDistanceStrict(0, value * 1000)}
      </div>
    );
  },
  averageProcessingTime: (value?: number | null) => {
    if (!value) {
      return '-';
    }

    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        {formatDistanceStrict(0, value * 1000)}
      </div>
    );
  },
  averageResponseTime: (value?: number | null) => {
    if (!value) {
      return '-';
    }

    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        {formatDistanceStrict(0, value * 1000)}
      </div>
    );
  },
};
