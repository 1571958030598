import React from 'react';

import {
  Button,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Divider,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import AnvIcon from 'src/components/shared/icons';
import { useStores } from 'src/stores/root-store';
import { FormattedMessage, useIntl } from 'react-intl';
import { propsMixins } from 'src/styles/mixins';

interface IProps {
  withDivider?: boolean;
}

const AuthLanguageMenu: React.FC<IProps> = observer(
  ({ withDivider = true }) => {
    const { locale } = useIntl();

    const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
      null,
    );
    const openLang = !!anchorElLang;
    const classes = useStyles();
    const {
      authStores,
      uiStores: { languages },
    } = useStores();
    const { setActiveLocale } = authStores;
    const [defaultLanguage, setDefaultLanguage] = React.useState<string>('');

    React.useEffect(() => {
      languages
        ?.filter((lang) => lang.code === locale)
        .forEach((item) => {
          setDefaultLanguage(item.name);
        });
    }, [languages, locale]);

    const handleMenuLang = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElLang(event.currentTarget);
    };
    const handleCloseLang = () => {
      setAnchorElLang(null);
    };
    const handleChangeLang = (code: string, name: string) => () => {
      setActiveLocale(code);
      setDefaultLanguage(name);
      handleCloseLang();
    };

    return (
      <div className={classes.wrapper}>
        {withDivider && (
          <Divider className={classes.divider} variant="fullWidth" />
        )}
        <Button
          variant={'text'}
          size={'small'}
          startIcon={<AnvIcon color={'lightIcons'} icon={'web'} size={16} />}
          endIcon={
            <AnvIcon
              style={{
                transform: 'rotate(90deg)',
              }}
              color={'lightIcons'}
              icon={'chevron'}
              size={16}
            />
          }
          onClick={handleMenuLang}
          style={{ padding: 4, marginBottom: 12 }}
        >
          <FormattedMessage id="APP.LANGUAGE" defaultMessage="Language" />:{' '}
          {defaultLanguage}
        </Button>

        <Menu
          className={classes.menuRoot}
          id="language-appBar"
          anchorEl={anchorElLang}
          {...propsMixins.menu}
          open={openLang}
          onClose={handleCloseLang}
        >
          {languages?.map((lang) => {
            return (
              <MenuItem
                key={lang.code}
                onClick={handleChangeLang(lang.code, lang.name)}
              >
                {lang.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  },
);

export default AuthLanguageMenu;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: 'auto',
      textAlign: 'center',
    },
    divider: {
      marginBottom: theme.spacing(1.5),
    },
    menuRoot: {
      position: 'absolute !important' as 'absolute',
    },
  }),
);
