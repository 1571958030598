import React from 'react';
import clsx from 'clsx';
import { Typography, TypographyProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface IProps extends TypographyProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  className?: string;
  divider?: boolean;
}

export const TextH3: React.FC<IProps> = ({
  children,
  className,
  component,
  divider,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.textH3, {
        // @ts-ignore
        [className]: !!className,
        [classes.divider]: !!divider,
      })}
      component={component || 'h3'}
      {...other}
    >
      {children}
    </Typography>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    textH3: {
      fontSize: 26,
      lineHeight: '32px',
      fontWeight: 600,
      letterSpacing: '-0.02em',
    },
    divider: {
      borderBottom: `1px solid grey`,
    },
  }),
);
