import { FormsEntityTemplatesStoreClass } from '@platform/front-lib-forms/dist/forms/forms-lib/forms-entity-store/forms-entity.store';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AnvDialog,
  AnvIcon,
  AnvMenu,
  AnvUtils,
  Constants,
  DefaultIcons,
  ModalConfirm,
  TextBody,
  TextH5,
  TextParagraph,
  useMenu,
  useModal,
} from '@platform/front-lib';
import { Grid, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ACCESS_SCOPES } from '../../../../constants';

import { useStores } from '../../../../stores/root-store';
import type { IEntityIds } from '@platform/front-lib-forms/dist/forms/forms-builder/form';
import { IFormsTemplate } from '@platform/front-lib/dist/models';
import { IComplaintCategorySettingForm } from './types';
import {
  AnvSelectForms,
  FormsBuilder,
  FormsByCodeOrId2,
} from '@platform/front-lib-forms';

import { useStyles } from './styles';

interface IComplaintSettingFormTemplatePartProps {
  children?: React.ReactNode;

  categoryId: string;
}

const fieldName = 'formsTemplate';

const captionKeysSelectForms = {
  title: { id: 'SUPPORT.COMPLAINT.CATEGORIES.FORMS.MODAL.ADD_FORM.TITLE' },
  emptyList: {
    id: 'SUPPORT.COMPLAINT.CATEGORIES.SELECT_FORMS.MODAL.ADD_FORM.LIST.EMPTY',
  },
  actionPreviewMenu: {
    id: 'SUPPORT.COMPLAINT.CATEGORIES.SELECT_FORMS.MODAL.ADD_FORM.MENU.PREVIEW',
  },
  actionAddForm: {
    id:
      'SUPPORT.COMPLAINT.CATEGORIES.SELECT_FORMS.MODAL.ADD_FORM.ACTION.SUBMIT',
  },
  actionClose: {
    id: 'SUPPORT.COMPLAINT.CATEGORIES.SELECT_FORMS.MODAL.ADD_FORM.ACTION.CLOSE',
  },
};

export const captionKeysSelectFormsPreview = {
  titleModal: {
    id: 'SUPPORT.COMPLAINT.CATEGORIES.SELECT_FORMS.PREVIEW.MODAL_PREVIEW.TITLE',
  },
  actionClose: {
    id: 'SUPPORT.COMPLAINT.CATEGORIES.SELECT_FORMS.PREVIEW.ACTION.CLOSE',
  },
};

export const ComplaintSettingFormTemplatePart: React.FC<IComplaintSettingFormTemplatePartProps> = observer(
  ({ categoryId }) => {
    const classes = useStyles();

    const { formatMessage, locale } = useIntl();

    const {
      formsStore: { FormsDataStore, FormsTemplateStore, FormsComponentStore },
      metaStores,
      fileStore,
      uiStores,
      authStores: { token, profile },

      complaintStore: {
        entitiesByAccessStore,
        formsStore: { entity, historyStore, builder },
      },
    } = useStores();

    const { dataMap: entitiesByAccessDataMap } = entitiesByAccessStore;

    const { id: userId } = profile || {};

    const {
      addItem,
      updateItem,
      dataMap,
      getList,
      getOne: getOneFormsTemplate,
    } = entity;

    const {
      create,
      update,
      copyTemplate,
      getOne: getOneTemplate,
      isFetching: isFetchingBuilder,
    } = builder;

    // todo organization | school from watch
    const requestIdAvailable = `${categoryId}-available`;

    const { handleClose: handleCloseMenu, closeMenu } = useMenu();

    const {
      setValue,
      watch,
      register,
      formState: { errors },
    } = useFormContext<IComplaintCategorySettingForm>();

    const watchFormsTemplate: string = watch(fieldName);
    const watchEntityType = watch('entityType');
    const watchEducationEntityId = watch('entity');
    // const watchEntity = watch('entity');

    const entityIds: IEntityIds = React.useMemo(() => {
      return {
        entityId: watchEducationEntityId,
        subId: (watchEntityType === ACCESS_SCOPES.SCHOOL).toString(),
      };
    }, [watchEntityType, watchEducationEntityId]);

    const watchEntity = watchFormsTemplate && dataMap[watchFormsTemplate];

    React.useEffect(() => {
      if (!watchEntity && watchFormsTemplate) {
        getOneFormsTemplate({
          id: watchFormsTemplate,
        });
      }
    }, [watchEntity, watchFormsTemplate, getOneFormsTemplate]);

    const isDefaultEntitySchool = watchEntityType === ACCESS_SCOPES.SCHOOL;

    const entityName =
      watchEducationEntityId &&
      entitiesByAccessDataMap[watchEducationEntityId] &&
      AnvUtils.getNameByLangNullable(
        entitiesByAccessDataMap[watchEducationEntityId],
        'name',
        locale,
      );

    React.useEffect(() => {
      // rm  watchEntity possible fix AN-2420
      if (watchFormsTemplate && categoryId) {
        getOneTemplate(
          { entityId: categoryId, id: watchFormsTemplate },
          {
            successFunc: (response: any) => {
              addItem(response);
            },
          },
        );
      }
    }, [getOneTemplate, addItem, categoryId, watchFormsTemplate]);

    const {
      isOpen: isOpenListModal,
      handleOpenModal: openListModal,
      handleCloseModal: closeListModalPlain,
    } = useModal();
    const [isCloneMode, setIsCloneMode] = React.useState(false);
    const closeListModal = () => {
      closeListModalPlain();
      setIsCloneMode(false);
      setIsCloneMode(false);
    };

    const handleSetForm = (value: string) => {
      setValue(fieldName, value);
    };

    const getAvailableList = React.useCallback(() => {
      getList({ entityId: categoryId }, { requestId: requestIdAvailable });
    }, [requestIdAvailable, getList, categoryId]);

    // get list exists
    React.useEffect(() => {
      getAvailableList();
    }, [getAvailableList]);

    const handleCreateNewFormsTemplate = ({
      id,
      fields,
    }: {
      id?: string;
      fields: Partial<IFormsTemplate>;
    }) => {
      const successFunc = (response: any) => {
        // getAvailableList();

        if (!id) {
          addItem(response);
          handleSetForm(response?._id);
        } else {
          updateItem(response);
        }

        handleCloseModalFormsCreate();
      };

      if (id) {
        update(
          {
            entityId: categoryId,
            data: {
              ...fields,
              _id: id,
            },
          },
          { successFunc },
        );

        return;
      }

      create({ data: fields, entityId: categoryId }, { successFunc });
    };

    const handleSetValue = async (idArg: string | string[]) => {
      const id = Array.isArray(idArg) ? idArg?.[0] : idArg;

      if (isCloneMode) {
        const response: any = await copyTemplate(
          { id, entityId: categoryId },
          { requestId: requestIdAvailable },
        );

        if (response?._id) {
          addItem(response);

          handleSetForm(response?._id);
        }
      } else {
        handleSetForm(id);
      }

      closeListModal();
    };

    /* Create */

    const {
      isOpen: isOpenFormsCreate,
      handleCloseModal: handleCloseModalForms,
      handleOpenModal: handleOpenModalFormsCreate,
    } = useModal();

    const [idFormsEdit, setIdFormsEdit] = React.useState<string | null>(null);
    const handleOpenModalFormsEdit = (id: string) => {
      setIdFormsEdit(id);
      handleOpenModalFormsCreate();
    };
    const handleCloseModalFormsCreate = () => {
      setIdFormsEdit(null);
      handleCloseModalForms();
    };

    /** Remove  */
    const [selectedId, setSelectedId] = React.useState<null | string>(null);

    const handleSetSelectedId = (callback: () => void) => (
      id: string | null = null,
    ) => {
      setSelectedId(id);
      callback();
    };

    const {
      isOpen: isOpenRemoveModal,
      handleOpenModal: openRemoveModal,
      handleCloseModal: closeRemoveModal,
    } = useModal();

    const handleOpenRemoveModal = handleSetSelectedId(openRemoveModal);
    const handleCloseRemoveModal = handleSetSelectedId(closeRemoveModal);

    const confirmRemove = () => {
      if (!selectedId) {
        return;
      }

      setValue(fieldName, '');

      handleCloseRemoveModal(null);
    };

    /** Preview */
    const [previewId, setPreviewId] = React.useState<string | null>(null);

    const {
      handleCloseModal: handleCloseModalFormsPreview,
      handleOpenModal: handleOpenModalFormsPreview,
      isOpen: isOpenFormsPreview,
    } = useModal();

    const handleOpenPreview = React.useCallback(
      (id: string) => {
        handleCloseMenu();
        setPreviewId(id);
        handleOpenModalFormsPreview();
      },
      [handleCloseMenu, setPreviewId, handleOpenModalFormsPreview],
    );

    const handleClosePreview = () => {
      setPreviewId(null);
      handleCloseModalFormsPreview();
    };

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, {});
    };

    return (
      <>
        <input
          type="hidden"
          name={fieldName}
          ref={register()}
          // defaultValue={''}
        />

        <Grid container justify={'space-between'}>
          <Grid item>
            <TextH5
              className={classes.title}
              // color={!!formHelper(fieldName) ? 'error' : undefined}
            >
              <AnvIcon
                icon={'formsOutline'}
                color={'lightIcons'}
                size={24}
                alignSelfCenter
                offset={1}
              />
              <span>
                <FormattedMessage
                  id="SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.BLOCK.SUB_TITLE"
                  defaultMessage="Provided Form"
                />
              </span>
            </TextH5>
          </Grid>

          {!!formHelper(fieldName) && (
            <Grid item>
              <TextBody color={'error'}>
                <span>*</span>
                &nbsp;
                <FormattedMessage
                  id="APP_HUB.BUILD.TEMPLATE.FORM.FORMS.BLOCK.VALIDATION"
                  defaultMessage="Form is required"
                />
              </TextBody>
            </Grid>
          )}
        </Grid>

        {watchEntity && (
          <Grid
            container
            justify={'space-between'}
            alignItems={'center'}
            className={classes.formsContentWrapper}
          >
            <TextParagraph>{watchEntity.title}</TextParagraph>
            <Grid item>
              <AnvMenu closeMenu={closeMenu}>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    handleOpenPreview(watchEntity._id);
                  }}
                >
                  <AnvIcon icon={DefaultIcons.PREVIEW} />
                  {formatMessage({
                    id: `SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.MENU.PREVIEW`,
                    defaultMessage: 'Preview',
                  })}
                </MenuItem>

                {watchEntity.contentStatus ===
                  Constants.CONTENT_STATUS.REMOTE_DRAFT && (
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      handleOpenModalFormsEdit(watchEntity._id);
                    }}
                  >
                    <AnvIcon icon={DefaultIcons.EDIT} />
                    {formatMessage({
                      id: `SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.MENU.EDIT`,
                      defaultMessage: 'Edit',
                    })}
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    handleOpenRemoveModal(watchEntity._id);
                  }}
                >
                  <AnvIcon icon={DefaultIcons.DELETE} color={'error'} />
                  {formatMessage({
                    id: `SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.MENU.DELETE`,
                    defaultMessage: 'Delete',
                  })}
                </MenuItem>
              </AnvMenu>
            </Grid>
          </Grid>
        )}

        {!watchFormsTemplate && (
          <AnvMenu
            // anchorClassName={classes.buttonForSection}
            closeMenu={closeMenu}
            customIcon={<AnvIcon icon={'plus'} size={24} color="primary" />}
            endIcon={<AnvIcon icon={'chevronDown'} size={24} color="primary" />}
            labelRight={formatMessage({
              id: 'SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.ACTION.ADD_FORMS',
              defaultMessage: 'Add Forms',
            })}
            anchorComponent={'button'}
            size={'small'}
            anchorClassName={classes.actionButton}
          >
            <MenuItem
              disabled={!watchEducationEntityId}
              title={formatMessage({
                id:
                  'SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.ACTION.CHOOSE_FORM',
                defaultMessage: 'Choose a Form',
              })}
              onClick={() => {
                handleCloseMenu();
                openListModal();
              }}
            >
              <AnvIcon icon={'dataList'} size={24} color="primary" />
              {formatMessage({
                id:
                  'SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.ACTION.CHOOSE_FORM',
                defaultMessage: 'Choose a Form',
              })}
            </MenuItem>

            <MenuItem
              disabled={!watchEducationEntityId}
              title={formatMessage({
                id:
                  'SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.ACTION.CLONE_FORM',
                defaultMessage: 'Clone Form',
              })}
              onClick={() => {
                handleCloseMenu();
                openListModal();
                setIsCloneMode(true);
              }}
            >
              <AnvIcon icon={'surveyCopy'} size={24} color="primary" />
              {formatMessage({
                id:
                  'SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.ACTION.CLONE_FORM',
                defaultMessage: 'Clone Form',
              })}
            </MenuItem>

            <MenuItem
              disabled={!watchEducationEntityId}
              title={formatMessage({
                id:
                  'SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.ACTION.CREATE_FORM',
                defaultMessage: 'Create a Form',
              })}
              onClick={() => {
                handleCloseMenu();
                handleOpenModalFormsCreate();
              }}
            >
              <AnvIcon icon={'plus'} size={24} color="primary" />
              {formatMessage({
                id:
                  'SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.ACTION.CREATE_FORM',
                defaultMessage: 'Create a Form',
              })}
            </MenuItem>
          </AnvMenu>
        )}

        {isOpenListModal && (
          <AnvSelectForms
            userId={userId}
            formsDataStore={FormsDataStore}
            formsTemplatesStore={
              (entity as unknown) as FormsEntityTemplatesStoreClass
            }
            metaStores={metaStores}
            handleSubmit={handleSetValue}
            open={isOpenListModal}
            handleClose={closeListModal}
            initValues={watchFormsTemplate}
            uiStore={uiStores}
            fileStore={fileStore}
            multiple={false}
            onlyNotAddedData={!isCloneMode}
            entityIds={entityIds}
            captionKeys={captionKeysSelectForms}
            captionKeysPreview={captionKeysSelectFormsPreview}
            formsComponentStore={FormsComponentStore}
          />
        )}

        {isOpenFormsCreate && (
          <FormsBuilder
            historyStore={historyStore}
            isOperator={true}
            entityIds={entityIds}
            entityName={entityName}
            id={idFormsEdit || undefined}
            predefinedScopeType={'PRIVATE'}
            // defaultScopeType={'PRIVATE'}
            defaultVisibility={'NOT_VISIBLE'}
            defaultTool={Constants.TOOLS.COMPLAINT}
            token={token || ''}
            isFetching={isFetchingBuilder}
            customSubmit={handleCreateNewFormsTemplate}
            isDialogView={true}
            dialogIsOpen={isOpenFormsCreate}
            handleCloseDialog={handleCloseModalFormsCreate}
            FormsBuilderStore={builder}
            FormsComponentStore={FormsComponentStore}
            entitiesByAccessStore={entitiesByAccessStore}
            isDefaultEntitySchool={isDefaultEntitySchool}
            defaultEntity={watchEducationEntityId}
            // @ts-ignore
            metaStores={metaStores}
            fileStore={fileStore}
            uiStores={uiStores}
          />
        )}

        {isOpenRemoveModal && (
          <ModalConfirm
            isOpen={isOpenRemoveModal}
            text={
              <FormattedMessage
                id="SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.REMOVE_WARN"
                defaultMessage="Are you sure to remove this form?"
              />
            }
            cancelAction={handleCloseRemoveModal}
            handleClose={handleCloseRemoveModal}
            confirmAction={confirmRemove}
          />
        )}

        {previewId && (
          <AnvDialog
            open={!!(isOpenFormsPreview && previewId)}
            handleClose={handleClosePreview}
            title={formatMessage({
              id: 'SUPPORT.COMPLAINT.CATEGORIES.FORM.TEMPLATE.PREVIEW',
              defaultMessage: 'Preview',
            })}
            subTitle={dataMap[previewId]?.title}
          >
            <FormsByCodeOrId2
              metaStores={metaStores}
              fileStore={fileStore}
              id={previewId}
              userId={'uuu'}
              formsComponentStore={FormsComponentStore}
              formsDataStore={FormsDataStore}
              formsTemplatesStore={FormsTemplateStore}
              handleSubmit={() => {}}
              isTemplate={true}
              forceHideSubmitButton={true}
            />
          </AnvDialog>
        )}
      </>
    );
  },
);
