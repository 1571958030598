import { action, makeObservable, observable } from 'mobx';

import { ComplaintCategoriesService } from './service';
import { CommonListAdvancedStore } from '@platform/front-lib';
import type { IComplaintCategory } from '@platform/front-lib/dist/models';
import { IActionMeta } from '../../_helpers/interfaces';

export class ComplaintCategoryStore extends CommonListAdvancedStore<
  ComplaintCategoriesService,
  IComplaintCategory & { _id: string }
> {
  service = new ComplaintCategoriesService();
  error: Record<string, any> | null | string = null;

  customizationRecordsMap: Record<string, number> = {};

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  postGetListHook = (response?: any) => {
    if (response?.additionalData?.customizationRecords) {
      this.customizationRecordsMap =
        response.additionalData.customizationRecords;
    }
  };

  getOne = async (payload: { id: string }, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.getOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      if (response?._id) {
        this.dataMap[response._id] = response;
      }

      meta?.successFunc?.(response);
    });
  };

  private reset() {
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  cleanError = () => {
    this.isError = false;
    this.error = null;
  };

  // common
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor() {
    super({
      sessionKey: 'complaint-categories',
      disableAutoSave: true,
    });

    makeObservable(this, {
      getOne: action,
      // reset: action,
      cleanError: action,

      setError: action,
      setLoaded: action,
      setLoading: action,

      error: observable,
      isFetching: observable,
      isLoaded: observable,
      isError: observable,
    });
  }
}
