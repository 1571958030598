import { action, makeObservable, observable } from 'mobx';
import { CommonListAdvancedStore } from '@platform/front-lib';

import { UsersAccessRequestService } from './service';

export class UsersAccessRequestStore extends CommonListAdvancedStore<
  UsersAccessRequestService,
  any
> {
  service: UsersAccessRequestService;

  errorItem: null | Record<string, any> = null;

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  // common
  setLoading = (func?: () => void) => {
    this.errorItem = null;
    this.isError = false;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();

    // [error, success]
    return [true, false];
  };
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();

    // [error, success]
    return [null, true];
  };

  constructor(module: string) {
    super({ disableAutoSave: true });
    this.service = new UsersAccessRequestService(
      module,
      'user-access-requests',
    );

    makeObservable(this, {
      setLoading: action,
      setError: action,
      setLoaded: action,

      errorItem: observable,

      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      isError: observable,
    });
  }
}
