import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

interface IProps {
  children: any;
}
const AuthFormWrapper: React.FC<IProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
};

const formWidth = 480;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(2, 'auto'),
      padding: theme.spacing(4, 3, 5),
      maxWidth: formWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#fff',
      borderRadius: 6,

      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(4, 'auto'),
        padding: theme.spacing(4, 3, 5),
      },

      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(7, 'auto'),
        padding: theme.spacing(4, 7, 5),
      },
    },
  }),
);

export default AuthFormWrapper;
