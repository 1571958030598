export const propsMixins = {
  menu: {
    // disableScrollLock: true,
    // keepMounted: true,
    // anchorOrigin: {
    //   vertical: 'top' as number | 'bottom' | 'top' | 'center',
    //   horizontal: 'center' as number | 'center' | 'left' | 'right',
    // },
    // transformOrigin: {
    //   vertical: 'bottom' as number | 'bottom' | 'top' | 'center',
    //   horizontal: 'center' as number | 'center' | 'left' | 'right',
    // },
    getContentAnchorEl: null,

    anchorOrigin: {
      vertical: 'bottom' as number | 'bottom' | 'top' | 'center',
      horizontal: 'center' as number | 'center' | 'left' | 'right',
    },
    transformOrigin: {
      vertical: 'top' as number | 'bottom' | 'top' | 'center',
      horizontal: 'center' as number | 'center' | 'left' | 'right',
    },
  },
};
