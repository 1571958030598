import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      fontSize: 18,
      borderRadius: 6,
    },
    chipSuccess: {
      backgroundColor: '#BFEBF9',

      color: '#285E8A',
    },
  })
);
