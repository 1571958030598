import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(5, 0, 2, 0),
      width: '100%',
    },
  }),
);
