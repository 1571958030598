import { yupResolver } from '@hookform/resolvers/yup';
import { IModuleAccessRequest } from '@platform/front-lib/dist/models';

import { Grid } from '@material-ui/core';
import {
  AnvButton,
  AnvDatepicker,
  AnvDialog,
  AnvDialogActionsGrid,
  AnvListContainer,
  AnvListData,
  AnvSchoolName,
  AnvTextField,
  AnvUtils,
  Constants,
  IsLoadingComponent,
  useAccessRequestDates,
  useViewError,
} from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGeneralSettings } from '../../../hooks/use-access-request-renew-period';
import { useStores } from '../../../stores/root-store';
import { formErrorHelper } from '../../../utils';
import { validationSchema } from './vlidation';

const ACCESS_REQUEST_SETTINGS_KEYS = [
  'ACCESS_REQUEST_MAX_EXPIRATION_PERIOD',
  'ACCESS_REQUEST_DEFAULT_EXPIRATION_PERIOD',
];

interface RenewAccessRequestModalProps {
  open: boolean;
  handleClose: (refetch?: boolean) => void;

  accessRequestId: string;
}

const LABEL_MD = 3;

export const RenewAccessRequestDialog: React.FC<RenewAccessRequestModalProps> = observer(
  ({ open, handleClose, accessRequestId }) => {
    const { formatMessage, locale } = useIntl();
    const {
      accessStore: {
        dataMap,
        renewAccessRequest,
        isFetching,
        error,
        resetErrors,
      },
      metaStores: {
        generalSettingStore,
        requestStatusStore: {
          isLoaded: isLoadedRequestStatuses,
          getEntities: getRequestStatuses,
        },
        toolsStore: { getTool, dataMap: dataMapTools },
        accessScopeStore: {
          getAccessScopes,
          accessScopesMap: dataMapAccessScopes,
        },
        jurisdictionStore: { getJurisdictions, dataMap: dataMapJurisdictions },
        anvilarModulesStore: {
          getEntities: getAnvModules,
          dataMap: dataMapAnvModules,
        },
      },
    } = useStores();

    const { settings } = useGeneralSettings({
      generalSettingStore,
      key: ACCESS_REQUEST_SETTINGS_KEYS,
    });

    const {
      maxExpirationDate,
      minExpirationDate,
      initialFocusedDate,
    } = useAccessRequestDates(settings);

    const { ErrorView } = useViewError({
      dropErrors: resetErrors,
      errorItem: error,
    });

    const accessRequest = dataMap?.[accessRequestId];
    const tool = dataMapTools?.[accessRequest.toolCode];
    const anvilarModule = dataMapAnvModules?.[accessRequest.moduleCode];
    const accessScope = dataMapAccessScopes?.[accessRequest.accessScope];

    const jurisdictionId =
      accessRequest?.orgJurisdiction || accessRequest?.schoolJurisdiction;
    const jurisdiction = dataMapJurisdictions?.[jurisdictionId];

    React.useEffect(() => {
      if (!isLoadedRequestStatuses) {
        getRequestStatuses({});
      }
    }, [isLoadedRequestStatuses, getRequestStatuses]);

    React.useEffect(() => {
      if (!jurisdiction && !!jurisdictionId) {
        getJurisdictions({});
      }
    }, [jurisdiction, getJurisdictions, jurisdictionId]);

    React.useEffect(() => {
      if (!tool && !!accessRequest?.toolCode) {
        getTool({ _id: accessRequest.toolCode });
      }
    }, [tool, getTool, accessRequest?.toolCode]);

    React.useEffect(() => {
      if (!anvilarModule && !!accessRequest?.moduleCode) {
        getAnvModules({ getAll: true });
      }
    }, [accessRequest.moduleCode, anvilarModule, getAnvModules]);

    React.useEffect(() => {
      if (!accessScope && !!accessRequest?.accessScope) {
        getAccessScopes({});
      }
    }, [accessRequest.accessScope, accessScope, getAccessScopes]);

    const { control, errors, handleSubmit } = useForm({
      resolver: yupResolver(
        validationSchema({
          formatMessage,
          minExpirationDate,
          maxExpirationDate,
        }),
      ),
      defaultValues: {
        reasonForRequest: '',
        accessExpirationDate: initialFocusedDate,
      },
    });

    const onSubmit = (data: {
      reasonForRequest: string;
      accessExpirationDate: Date;
    }) => {
      const payload = preparePayload({
        accessRequest,
        jurisdictionId,
        ...data,
      });
      renewAccessRequest(
        { ...payload, accessRequestId },
        {
          successFunc: () => {
            handleClose(true);
          },
        },
      );
    };

    const formHelper = (field: string) => {
      return formErrorHelper(field, errors, {});
    };

    return (
      <AnvDialog
        title={formatMessage({
          id: 'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.TITLE',
          defaultMessage: 'Renew Access Request',
        })}
        open={open}
        handleClose={() => {
          handleClose(false);
        }}
      >
        <IsLoadingComponent isLoading={isFetching} />

        {ErrorView}

        <AnvListContainer noMargin>
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={formatMessage({
              id:
                'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.USER_INFO',
              defaultMessage: 'User Information',
            })}
            value={`${AnvUtils.formatFullName({
              entity: accessRequest?.requestedByRef,
            })} (${accessRequest?.requestedByRef?.email})`}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={formatMessage({
              id:
                'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.TOOL',
              defaultMessage: 'Anvilar Tool',
            })}
            value={AnvUtils.getNameByLangNullable(tool, 'name', locale) || ''}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={formatMessage({
              id:
                'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.MODULE',
              defaultMessage: 'Anvilar Module',
            })}
            value={
              AnvUtils.getNameByLangNullable(anvilarModule, 'name', locale) ||
              ''
            }
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={formatMessage({
              id:
                'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.ACCESS_LEVEL',
              defaultMessage: 'Access Level',
            })}
            value={
              AnvUtils.getNameByLangNullable(
                accessScope,
                'description',
                locale,
              ) || ''
            }
          />
          {!!jurisdiction && (
            <AnvListData
              direction={'row'}
              labelMd={LABEL_MD}
              label={formatMessage({
                id:
                  'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.JURISDICTION',
                defaultMessage: 'Jurisdiction',
              })}
              value={
                AnvUtils.getNameByLangNullable(jurisdiction, 'name', locale) ||
                ''
              }
            />
          )}
          {!!accessRequest.scopeSchool && (
            <AnvListData
              alignItems={'flex-start'}
              direction={'row'}
              labelMd={LABEL_MD}
              label={formatMessage({
                id: 'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.',
                defaultMessage: 'School',
              })}
              value={<AnvSchoolName school={accessRequest.scopeSchoolRef} />}
            />
          )}
          {!!accessRequest?.['schoolDepartment'] && (
            <AnvListData
              alignItems={'flex-start'}
              direction={'row'}
              labelMd={LABEL_MD}
              label={formatMessage({
                id:
                  'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.SCHOOL_DEPARTMENT',
                defaultMessage: 'School Department',
              })}
              value={
                <>
                  {(() => {
                    const pretendAsSchool = Object.assign(
                      {},
                      accessRequest['schoolDepartmentRef'],
                      {
                        address:
                          accessRequest['schoolDepartmentRef'][
                            'physicalAddress'
                          ],
                      },
                    ) as any;

                    return <AnvSchoolName school={pretendAsSchool} />;
                  })()}
                </>
              }
            />
          )}
          {!!accessRequest.scopeOrganization && (
            <AnvListData
              alignItems={'flex-start'}
              direction={'row'}
              labelMd={LABEL_MD}
              label={formatMessage({
                id:
                  'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.ORGANIZATION',
                defaultMessage: 'Organization',
              })}
              value={
                <>
                  {(() => {
                    const pretendAsSchool = Object.assign(
                      {},
                      accessRequest.scopeOrganizationRef,
                      {
                        address:
                          accessRequest.scopeOrganizationRef?.physicalAddress,
                      },
                    ) as any;

                    return <AnvSchoolName school={pretendAsSchool} />;
                  })()}
                </>
              }
            />
          )}

          {!!accessRequest.accessExpirationDate && (
            <AnvListData
              alignItems={'flex-start'}
              direction={'row'}
              labelMd={LABEL_MD}
              label={formatMessage({
                id:
                  'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.EXPIRATION',
                defaultMessage: 'Expiration',
              })}
              value={AnvUtils.formatDate(accessRequest.accessExpirationDate, {
                formatStr: Constants.DATE_FORMATS.DEFAULT_FORMAT,
              })}
            />
          )}
        </AnvListContainer>
        <form
          style={{
            paddingTop: 16, // TODO
          }}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <AnvDatepicker
            control={control}
            label={
              <FormattedMessage
                id="SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.EXPIRATION_DATE"
                defaultMessage="Expiration Date"
              />
            }
            required
            name="accessExpirationDate"
            minDate={minExpirationDate}
            maxDate={maxExpirationDate}
            initialFocusedDate={initialFocusedDate}
            helperText={formHelper('accessExpirationDate') as string}
            error={!!formHelper('accessExpirationDate')}
          />
          <AnvTextField
            name={'reasonForRequest'}
            control={control}
            required
            id="reasonForRequest"
            type="reasonForRequest"
            label={
              <FormattedMessage
                id="SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.LABEL.REASON"
                defaultMessage="Reason for Request"
              />
            }
            multiline
            rows={3}
            variant="filled"
            fullWidth
            helperText={formHelper('reasonForRequest')}
            error={!!formHelper('reasonForRequest')}
          />
          <AnvDialogActionsGrid paddingTopOffset={48}>
            <Grid item xs={12}>
              <AnvButton
                variant={'contained'}
                color={'primary'}
                fullWidth
                type={'submit'}
                disabled={isFetching}
              >
                {formatMessage({
                  id:
                    'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.ACTION.RENEW',
                  defaultMessage: 'Renew Access Request',
                })}
              </AnvButton>
            </Grid>
            <Grid item xs={12}>
              <AnvButton
                variant={'text'}
                customColor={'muted'}
                fullWidth
                onClick={() => {
                  handleClose(false);
                }}
              >
                {formatMessage({
                  id:
                    'SUPPORT.ACCESS_REQUESTS.RENEW_ACCESS_REQUEST_MODAL.ACTION.CLOSE',
                  defaultMessage: 'Close',
                })}
              </AnvButton>
            </Grid>
          </AnvDialogActionsGrid>
        </form>
      </AnvDialog>
    );
  },
);

function preparePayload({
  accessRequest,
  jurisdictionId,
  reasonForRequest,
  accessExpirationDate,
}: {
  accessRequest: IModuleAccessRequest;
  accessExpirationDate: Date;
  jurisdictionId: string;
  reasonForRequest: string;
}) {
  const payload: Record<string, any> = {
    firstName: accessRequest.requestedByRef?.firstName,
    lastName: accessRequest.requestedByRef?.lastName,
    userEmail: accessRequest.requestedByRef?.email,
    requestedBy: accessRequest.requestedBy,

    accessScope: accessRequest.accessScope,
    module: accessRequest.moduleCode,
    reasonForRequest: reasonForRequest,
    scope: {
      jurisdiction: jurisdictionId,
    },

    tool: accessRequest.toolCode,
    accessExpirationDate,
  };

  if (accessRequest.scopeOrganization) {
    payload.scope['organization'] = accessRequest.scopeOrganization;
  }
  if (accessRequest.scopeSchool) {
    payload.scope['school'] = accessRequest.scopeSchool;
  }
  if (accessRequest?.['schoolDepartment']) {
    payload.scope['schoolDepartment'] = accessRequest['schoolDepartment'];
  }

  return payload;
}
