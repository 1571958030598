import * as yup from 'yup';

import { validationsMessages } from '../../../../constants';

export const validationSchema = (fm: (arg: any, values?: any) => string) => {
  const shape: Record<string, any> = {
    jurisdiction: yup.string().required(fm(validationsMessages.required())),
    entityType: yup.string().required(fm(validationsMessages.required())),
    entity: yup.string().required(fm(validationsMessages.required())),
    severity: yup.string().required(fm(validationsMessages.required())),
    // anonymityProtection: yup.boolean().nullable(),
    instructions: yup.object().nullable(),
    formsTemplate: yup.string().required(fm(validationsMessages.required())),
  };

  return yup.object().shape(shape);
};
