import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { generatePath, NavLink, useParams } from 'react-router-dom';

import { addDays, addMonths } from 'date-fns';

import {
  AnvIcon,
  AnvPaginationMui,
  AnvTableWrapper,
  NoDataText,
  AnvBreadcrumbs,
  useCommonTable,
  AnvButton,
  useModal,
  AnvTable,
  useCheckIsTableView,
  AnvTitle,
  AnvButtonGroup,
  IsLoadingComponent,
} from '@platform/front-lib';

import { useAccessRequestRenewPeriod } from '../../../hooks/use-access-request-renew-period';
import { ArchiveAccessRequestModal } from '../archive-access-request.modal';
import { RenewAccessRequestDialog } from '../renew-access-request-modal';

import { prepareColumns } from './common';
import { Actions } from './common/actions';
import AccessRequestModal from './details/AccessRequestModal';
import { CreateAccessRequestModal } from './details/create-access-request-modal';
import {
  getItemById,
  getNameByLangNullable,
  prepareOptions,
} from '../../../utils';
import {
  EXPIRED_SOON_STATUS,
  PAGINATION_LIMIT,
  ROUTES,
} from '../../../constants';

import { useStores } from '../../../stores/root-store';
import { drawerOperatorMap } from '../../../constants/drawer-map';
import { TIcon } from '../../../components/shared/icons';

const archiveRouteTab = 'archived';

interface IAccessPageProps {
  disabledScopes?: string[];
  excludedScopes?: string[];
}

const requestId = 'access-request-list';

export const defaultFilterState = {
  structureName: '',
  toolCode: '',
  moduleCode: '',
  accessScope: '',
  scope: '',
  requestedBy: '',
  createdAt: null,
  accessExpirationDate: null,
  reviewedBy: '',
  requestStatus: 'REVIEW',
};

const AccessList: React.FC<IAccessPageProps> = observer(
  ({ disabledScopes, excludedScopes }) => {
    const { tab = null } = useParams<{ tab: string | undefined }>();
    const isArchived = tab === archiveRouteTab;

    const { formatMessage, locale } = useIntl();

    const { metaStores, accessStore } = useStores();

    const {
      generalSettingStore: { generalSettings },
      accessScopeStore: {
        data: accessScopes,
        isLoaded: isLoadedAccessScopes,

        getAccessScopes,
      },
      anvilarModulesStore: {
        data: anvilarModules,
        isLoaded: isLoadedAnvilarModules,
        getEntities: getAnvilarModules,
      },
      toolsStore: {
        data: anvilarTools,
        isLoaded: isLoadedTools,
        getTools,
        dataMap: toolMap,
      },
      accessMetaStatusesStore: {
        data: statuses,
        isLoaded: isLoadedStatuses,
        getEntities: getAccessRequestStatuses,
      },
    } = metaStores;

    const {
      getList: getAccessRequests,
      setPage,
      setFilter,
      setSort,
      getListData,
    } = accessStore;

    const {
      pagination,
      dataList: entities,
      isFetchingList: isLoading,
      isLoadedList: isLoaded,
      storeSorting,
      storeFilter,
    } = getListData(requestId);

    const accessRequestRenewPeriod = useAccessRequestRenewPeriod();
    const {
      isOpen: isOpenDetailsDialog,
      handleOpenModal: openDetailsDialog,
      handleCloseModal: closeDetailsDialog,
    } = useModal();

    const {
      isOpen: isOpenCreateARDialog,
      handleOpenModal: handleOpeCreateARDialog,
      handleCloseModal: handleCloseCreateARDialog,
    } = useModal();

    const {
      isOpen: isOpenRenewARDialog,
      handleOpenModal: openRenewARDialog,
      handleCloseModal: closeRenewARDialog,
    } = useModal();

    const {
      isOpen: isOpenArchiveDialog,
      handleOpenModal: openArchiveDialog,
      handleCloseModal: closeArchiveDialog,
    } = useModal();

    const {
      filterMemo,
      memoQueryFilter,
      sorting = '-createdAt',
      page,
      filterState,
      handleChangeFilterDate,
      handleChangeFilter,
      handleChangePhone,
      handleChangePage,
    } = useCommonTable({
      defaultFilterState,
      pagination,
      setPage,
      setFilter,
      setSort,
      storeSorting,
      storeFilter,
      requestId,
    });

    const [selectedRequestId, setSelectedRequestId] = React.useState<
      string | null
    >(null);

    const handleOpenArchiveDialog = React.useCallback(
      (id: string) => {
        setSelectedRequestId(id);
        openArchiveDialog();
      },
      [openArchiveDialog],
    );

    const handleCloseArchiveDialog = (refetch?: boolean) => {
      setSelectedRequestId(null);
      closeArchiveDialog();
      if (refetch) {
        getListCb();
      }
    };

    const handleOpenRenewDialog = React.useCallback(
      (id: string) => {
        setSelectedRequestId(id);
        openRenewARDialog();
      },
      [openRenewARDialog],
    );

    const handleCloseRenewDialog = (refetch?: boolean) => {
      setSelectedRequestId(null);
      closeRenewARDialog();
      if (refetch) {
        getListCb();
      }
    };

    const handleSelectRequest = React.useCallback(
      (id: string) => {
        setSelectedRequestId(id);
        openDetailsDialog();
      },
      [openDetailsDialog],
    );
    const handleCloseDetailsDialog = () => {
      setSelectedRequestId(null);
      closeDetailsDialog();
    };

    const handleUnselectRequest = (reloadList: boolean = false) => {
      if (reloadList) {
        getListCb();
      }
      handleCloseDetailsDialog();
    };

    const selectedEntity =
      selectedRequestId && getItemById(entities, selectedRequestId);

    const getListCb = React.useCallback(() => {
      getAccessRequests(
        {
          page: +page,
          sort: sorting,
          filter: filterMemo,
          limit: PAGINATION_LIMIT,
          archive: isArchived,
        },
        {
          requestId,
        },
      );
    }, [page, sorting, filterMemo, isArchived, getAccessRequests]);
    /** get entities */
    React.useEffect(() => {
      getListCb();
    }, [getListCb]);

    React.useEffect(() => {
      if (!isLoadedStatuses) {
        getAccessRequestStatuses();
      }
    }, [isLoadedStatuses, getAccessRequestStatuses]);

    React.useEffect(() => {
      if (!isLoadedTools) {
        getTools({
          filter: {
            disabled: false,
          },
        });
      }
    }, [isLoadedTools, getTools]);

    React.useEffect(() => {
      if (!isLoadedAnvilarModules && isLoadedTools) {
        getAnvilarModules({ getAll: true });
      }
    }, [isLoadedAnvilarModules, isLoadedTools, getAnvilarModules]);

    React.useEffect(() => {
      if (!isLoadedAccessScopes) {
        getAccessScopes();
      }
    }, [isLoadedAccessScopes, getAccessScopes]);

    const {
      minExpirationDate,
      maxExpirationDate,
      initialFocusedDate,
    } = useAccessRequestDates(generalSettings);

    const statusOptions = React.useMemo(() => {
      const statusCopy = [...(statuses || [])];

      statusCopy.push(EXPIRED_SOON_STATUS(formatMessage));

      return prepareOptions(statusCopy, {
        field: 'caption',
        locale,
        sortByLabel: true,
        fallbackField: 'code',
      });
    }, [statuses, formatMessage, locale]);

    const accessScopesOptions = React.useMemo(() => {
      return prepareOptions(accessScopes, {
        field: 'description',
        locale,
        sortByLabel: true,
        fallbackField: 'code',
        disabledArray: disabledScopes || [],
        excludedArray: excludedScopes || [],
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessScopes, locale]);
    //
    const anvilarModulesOptions = React.useMemo(() => {
      if (!(Object.keys(toolMap).length || anvilarModules?.length)) {
        return [];
      }

      const prepareModules = anvilarModules
        ?.map((module) => {
          return {
            ...module,
            restricted: module.restricted,
            toolCode: module.toolCode,
            toolName:
              getNameByLangNullable(
                toolMap?.[module?.toolCode || ''],
                'name',
                locale,
              ) || module.toolCode,
            toolPosition: toolMap?.[module?.toolCode || '']?.position,
          };
        })
        .filter((module) => {
          return (
            module?.restricted &&
            (!memoQueryFilter?.toolCode ||
              memoQueryFilter?.toolCode === module.toolCode)
          );
        })
        .sort((a: Record<string, any>, b: Record<string, any>) => {
          if (a.toolPosition !== b.toolPosition) {
            return a.toolPosition - b.toolPosition;
          }
          return a.position - b.position;
        });

      return prepareOptions(prepareModules, {
        field: 'name',
        locale,
        fallbackField: 'moduleCode',
        captionFunc: (item) => {
          return item.toolName;
        },
      });
    }, [locale, anvilarModules, toolMap, memoQueryFilter?.toolCode]);

    const anvilarToolsOptions = React.useMemo(() => {
      return prepareOptions(anvilarTools, {
        field: 'name',
        locale,
        sortByLabel: true,
        fallbackField: 'code',
      });
    }, [anvilarTools, locale]);

    const columns = React.useMemo(() => {
      return prepareColumns({
        locale,
        anvilarToolsOptions,
        anvilarModulesOptions,
        accessScopesOptions,
        statusOptions,
      });
    }, [
      accessScopesOptions,
      anvilarModulesOptions,
      anvilarToolsOptions,
      locale,
      statusOptions,
    ]);
    const TableActionComponentCb = React.useCallback(
      ({ data }) => {
        return (
          <Actions
            data={data}
            handleSelectRequest={handleSelectRequest}
            checkIsRenewAvailable={
              accessRequestRenewPeriod?.checkIsRenewAvailable
            }
            handleOpenRenewDialog={handleOpenRenewDialog}
            handleOpenArchiveDialog={handleOpenArchiveDialog}
          />
        );
      },
      [
        handleOpenArchiveDialog,
        handleSelectRequest,
        handleOpenRenewDialog,
        accessRequestRenewPeriod?.checkIsRenewAvailable,
      ],
    );

    const onSuccessARCreated = () => {
      handleCloseCreateARDialog();
      handleUnselectRequest(true);
    };
    const data = React.useMemo(() => entities || [], [entities]);

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoaded,
      tableDataLength: entities?.length || 0,
      memoQueryFilter,
    });

    if (!isLoaded) {
      return (
        <>
          <AnvBreadcrumbs
            isOperator
            items={[
              {
                component: (
                  <>
                    <AnvIcon
                      size={24}
                      icon={drawerOperatorMap.accessRequests.icon as TIcon}
                      offset={1}
                    />
                    <FormattedMessage
                      tagName={'span'}
                      id={drawerOperatorMap.accessRequests.nameI18n}
                      defaultMessage={drawerOperatorMap.accessRequests.name}
                    />
                  </>
                ),
              },
            ]}
          />
          <IsLoadingComponent isLoading={isLoading} />
        </>
      );
    }

    return (
      <>
        <AnvBreadcrumbs
          isOperator
          items={[
            {
              component: (
                <>
                  <AnvIcon
                    size={24}
                    icon={drawerOperatorMap.accessRequests.icon as TIcon}
                    offset={1}
                  />
                  <FormattedMessage
                    tagName={'span'}
                    id={drawerOperatorMap.accessRequests.nameI18n}
                    defaultMessage={drawerOperatorMap.accessRequests.name}
                  />
                </>
              ),
            },
          ]}
        />
        <IsLoadingComponent isLoading={isLoading} />
        <AnvTableWrapper>
          <AnvTitle
            direction={'row'}
            alignItems={'center'}
            justify={'space-between'}
            spacing={2}
            tableTitle={
              <FormattedMessage
                id="COMMON.ACCESS.TABLE.TITLE"
                defaultMessage="Request Access List"
              />
            }
            rightActions={
              <AnvButton
                variant={'contained'}
                size={'small'}
                startIcon={<AnvIcon icon={'plus'} size={16} />}
                onClick={handleOpeCreateARDialog}
              >
                <FormattedMessage
                  id="COMMON.ACCESS.TABLE.ACTION.CREATE"
                  defaultMessage="Create access request"
                />
              </AnvButton>
            }
          >
            <AnvButtonGroup disableElevation variant="outlined" size={'small'}>
              <AnvButton
                //@ts-ignore
                component={NavLink}
                to={generatePath(ROUTES.ACCESS_REQUEST, {})}
                color={isArchived ? undefined : 'primary'}
                variant={isArchived ? 'outlined' : 'contained'}
                inactive={!isArchived}
              >
                {formatMessage({
                  id: 'SUPPORT.ACCESS_REQUESTS.LIST.TAB.ACTIVE',
                  defaultMessage: 'Active',
                })}
              </AnvButton>
              <AnvButton
                //@ts-ignore
                component={NavLink}
                to={generatePath(ROUTES.ACCESS_REQUEST, {
                  tab: archiveRouteTab,
                })}
                color={isArchived ? 'primary' : undefined}
                variant={isArchived ? 'contained' : 'outlined'}
                inactive={isArchived}
              >
                {formatMessage({
                  id: 'SUPPORT.ACCESS_REQUESTS.LIST.TAB.ARCHIVED',
                  defaultMessage: 'Archived',
                })}
              </AnvButton>
            </AnvButtonGroup>
          </AnvTitle>

          <AnvTable
            requestId={requestId}
            listenScrollBar
            isShowTable={isShowTable}
            filterMemo={filterMemo}
            setFilter={setFilter}
            filterState={filterState}
            handleChangeFilterDate={handleChangeFilterDate}
            handleChangeFilter={handleChangeFilter}
            handleChangePhone={handleChangePhone}
            isLoadedList={isLoaded}
            //common
            data={data}
            columns={columns}
            querySort={sorting}
            setSort={setSort}
            TableActionComponent={TableActionComponentCb}
          />
          <AnvPaginationMui
            pagination={pagination}
            totalPages={pagination?.totalPages}
            handleChangePage={handleChangePage}
            page={+page}
            loading={isLoading}
          />
          {isLoaded && data.length === 0 && (
            <div style={{ marginTop: 32 }}>
              <NoDataText
                message={
                  <FormattedMessage
                    id={'APP.COMMON.ACCESS_LIST.NO_DATA.TEXT'}
                    defaultMessage="There is no requests in this list"
                  />
                }
              />
            </div>
          )}
          {!!selectedEntity && isOpenDetailsDialog && (
            <AccessRequestModal
              entity={selectedEntity}
              statuses={statuses}
              handleClose={handleUnselectRequest}
              minExpirationDate={minExpirationDate}
              maxExpirationDate={maxExpirationDate}
              initialFocusedDate={initialFocusedDate}
            />
          )}

          {isOpenCreateARDialog && (
            <CreateAccessRequestModal
              open={isOpenCreateARDialog}
              handleClose={handleCloseCreateARDialog}
              onSuccess={onSuccessARCreated}
            />
          )}

          {isOpenRenewARDialog && selectedRequestId && (
            <RenewAccessRequestDialog
              accessRequestId={selectedRequestId}
              handleClose={handleCloseRenewDialog}
              open={isOpenRenewARDialog}
            />
          )}

          {isOpenArchiveDialog && selectedRequestId && (
            <ArchiveAccessRequestModal
              open={isOpenArchiveDialog}
              handleClose={handleCloseArchiveDialog}
              accessRequestId={selectedRequestId}
            />
          )}
        </AnvTableWrapper>
      </>
    );
  },
);

export default AccessList;

export function useAccessRequestDates(generalSettings: Record<string, any>) {
  const ACCESS_REQUEST_SETTINGS_KEYS = {
    ACCESS_REQUEST_MAX_EXPIRATION_PERIOD:
      'ACCESS_REQUEST_MAX_EXPIRATION_PERIOD',
    ACCESS_REQUEST_DEFAULT_EXPIRATION_PERIOD:
      'ACCESS_REQUEST_DEFAULT_EXPIRATION_PERIOD',
  };

  const accessRequestMaxExpirationPeriod =
    generalSettings?.[
      ACCESS_REQUEST_SETTINGS_KEYS.ACCESS_REQUEST_MAX_EXPIRATION_PERIOD
    ]?.value;
  const accessRequestDefaultExpirationPeriod =
    generalSettings?.[
      ACCESS_REQUEST_SETTINGS_KEYS.ACCESS_REQUEST_DEFAULT_EXPIRATION_PERIOD
    ]?.value;

  const minExpirationDate = addDays(new Date(), 1);
  const maxExpirationDate = React.useMemo(() => {
    return addMonths(new Date(), +accessRequestMaxExpirationPeriod);
  }, [accessRequestMaxExpirationPeriod]);

  const initialFocusedDate = React.useMemo(() => {
    return addMonths(new Date(), +accessRequestDefaultExpirationPeriod);
  }, [accessRequestDefaultExpirationPeriod]);

  return {
    minExpirationDate,
    maxExpirationDate,
    initialFocusedDate,
  };
}
