export const TOOL_CODE = 'SUPPORT';

export const TOOL_MODULES = {};

export const TOOLS = {
  DIRECTORY: 'DIRECTORY',
  COMPLAINT: 'COMPLAINT',
  SUPPORT: 'SUPPORT',
  PULSE_SURVEY: 'PULSE_SURVEY',
};

export const SUPPORT_TICKET_NOTES_TAB = 'notes';
