import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { capitalize, Grid } from '@material-ui/core';

import {
  AnvBreadcrumbs,
  AnvIcon,
  AnvTableTitle,
  AnvTableWrapper,
  AnvUtils,
} from '@platform/front-lib';

import { TOOLS } from '../../constants';
import { AnvDashboardCard } from './card/card';

import { useStores } from '../../stores/root-store';
import { AnvCardTitle } from './card/card-title';
import { AnvCardContent } from './content';
import { camelCaseToHumanableTitle } from '../../utils';
import { customContentItemRender } from './custom-item-render';
import { AnvCardContentSkeleton } from './content/card-content.skeleton';
import { IDashboardContentItem } from './card/card.intefaces';

const Dashboard: React.FC = observer(() => {
  const {
    supportStore: {
      dashboardStore: { data, isFetching: isFetchingData, getDashboardInfo },
    },
    metaStores: {
      toolsStore: { dataMap: toolsMap },
    },
  } = useStores();

  const entities = operatorCards;

  React.useEffect(() => {
    getDashboardInfo();
  }, [getDashboardInfo]);

  return (
    <>
      <AnvBreadcrumbs
        isOperator
        items={[
          {
            component: (
              <>
                <AnvIcon size={24} icon={'viewDashboard'} offset={1} />
                <FormattedMessage
                  tagName={'span'}
                  id="APP.COMMON.SUPPORT.DRAWER.DASHBOARD"
                  defaultMessage="Dashboard"
                />
              </>
            ),
          },
        ]}
      />

      <AnvTableWrapper>
        <AnvTableTitle tableTitle={'Dashboard'} />

        <Grid container direction="row" spacing={3}>
          {entities.map((item) => {
            const dataContent = data?.[item.key];

            const content: IDashboardContentItem[] = [];
            if (dataContent) {
              const contentPart = prepareContentItems({
                dataContent,
                itemKey: item.key,
              });

              content.push(...contentPart);
            }

            return (
              <Grid item key={item.key}>
                <AnvDashboardCard item={item} toolsMap={toolsMap}>
                  <AnvCardTitle item={item} toolsMap={toolsMap} />

                  {isFetchingData ? (
                    <AnvCardContentSkeleton />
                  ) : (
                    <AnvCardContent content={content} />
                  )}
                </AnvDashboardCard>
              </Grid>
            );
          })}
        </Grid>
      </AnvTableWrapper>
    </>
  );
});

export default Dashboard;

function prepareContentItems({
  dataContent,
  subItems = false,
  itemKey,
}: {
  dataContent: Record<string, any>;
  itemKey: string;
  subItems?: boolean;
}): IDashboardContentItem[] {
  const content: IDashboardContentItem[] = [];

  Object.keys(dataContent)?.forEach((contentItem) => {
    if (contentItem === 'new') {
      content.push({
        label: (
          <FormattedMessage
            id={`SUPPORT.DASHBOARD.LABEL.NEW.${AnvUtils.camelCaseToSnakeUpperCase(
              itemKey,
            )}`}
            defaultMessage={`New ${capitalize(itemKey)}`}
          />
        ),
      });

      const subItemsPart = prepareContentItems({
        dataContent: dataContent?.[contentItem],
        subItems: true,
        itemKey,
      });

      content.push(...subItemsPart);

      return null;
    }

    if (contentItem === 'activeTickets') {
      content.push({
        label: (
          <FormattedMessage
            id={`SUPPORT.DASHBOARD.LABEL.${AnvUtils.camelCaseToSnakeUpperCase(
              contentItem,
            )}`}
            defaultMessage={camelCaseToHumanableTitle(contentItem)}
          />
        ),
      });

      const subItemsPart = prepareContentItems({
        dataContent: dataContent?.[contentItem],
        subItems: true,
        itemKey,
      });

      content.push(...subItemsPart);

      return null;
    }

    const plainValue = dataContent?.[contentItem];
    const value: any =
      customContentItemRender?.[contentItem]?.(plainValue) || plainValue;

    content.push({
      label: (
        <FormattedMessage
          id={`SUPPORT.DASHBOARD.LABEL.${AnvUtils.camelCaseToSnakeUpperCase(
            contentItem,
          )}`}
          defaultMessage={camelCaseToHumanableTitle(contentItem)}
        />
      ),
      value: value,
      subItem: subItems,
    });
  });

  return content;
}

const operatorCards = [
  {
    key: 'users',
    title: 'Users',
    nameI18n: 'SUPPORT.DRAWER.USERS',
    icon: 'avatar',

    theme: {
      brandColor: '#FBC357',
      brandColorLight: '#FFF6E6',
      brandColorDark: '#AE4740',
    },
  },
  {
    key: 'accessRequests',
    title: 'Access Requests',
    nameI18n: 'SUPPORT.DRAWER.ACCESS_REQUEST',
    icon: 'moduleAccess',

    theme: {
      brandColor: '#FBC357',
      brandColorLight: '#FFF6E6',
      brandColorDark: '#AE4740',
    },
  },
  {
    key: 'invitations',
    title: 'Invitations',
    nameI18n: 'SUPPORT.DRAWER.INVITATIONS',

    icon: 'listBrowsing',

    theme: {
      brandColor: '#FBC357',
      brandColorLight: '#FFF6E6',
      brandColorDark: '#AE4740',
    },
  },
  {
    key: 'supportTicketsDashboard',
    title: 'Support',
    nameI18n: 'SUPPORT.DRAWER.SUPPORT_TICKETS',

    icon: 'support',

    theme: {
      brandColor: '#FBC357',
      brandColorLight: '#FFF6E6',
      brandColorDark: '#AE4740',
    },
  },
  {
    key: 'complaints',
    title: 'Complaints',
    nameI18n: 'SUPPORT.DRAWER.COMPLAINTS',

    icon: 'complaint',
    tool: TOOLS.COMPLAINT,

    theme: {
      brandColor: '#F4694D',
      brandColorLight: '#FDE9E4',
      brandColorDark: '#AE4740',
    },
  },

  {
    key: 'directory-connect',
    title: 'Directory Connect',
    nameI18n: 'SUPPORT.DRAWER.DIRECTORY_CONNECT',
    icon: 'orgTracker',
    tool: TOOLS.DIRECTORY,

    theme: {
      brandColor: '#F4694D',
      brandColorLight: '#FDE9E4',
      brandColorDark: '#AE4740',
    },
  },
  {
    key: 'pulse_survey',
    title: 'Pulse Survey',
    nameI18n: 'SUPPORT.DRAWER.PULSE_SURVEY',
    icon: 'survey',
    tool: TOOLS.PULSE_SURVEY,

    theme: {
      brandColor: '#F4694D',
      brandColorLight: '#FDE9E4',
      brandColorDark: '#AE4740',
    },
  },
];
