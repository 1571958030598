import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(3),
    },
    content: {
      marginBottom: theme.spacing(5),
    },
    dataItem: {
      fontFamily: 'Inter',
      marginBottom: theme.spacing(1),
    },
    dataLabel: {
      '&&': {
        fontSize: 13,
        lineHeight: '22px',

        color: theme.palette.text.secondary,
      },
    },
    dataValue: {
      '&&': {
        fontSize: 16,
        lineHeight: '21px',

        color: theme.palette.text.body,
      },
    },
    reviewCommentControl: {
      marginBottom: theme.spacing(3),
    },
    footerActions: {
      // marginTop: theme.spacing(3),
    },
  }),
);
