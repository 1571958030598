import { action, makeAutoObservable, observable } from 'mobx';
import { AnvUtils } from '@platform/front-lib';

import { IActionMeta } from '../_helpers/interfaces';

import DashboardService from '../../service/support/dashboard.service';

interface IDashboardData {
  [index: string]: any;
}

const { historyPushPagination, preparePaginationFilterData } = AnvUtils;

class DashboardStore {
  @observable
  error: Record<string, any> | null | string = null;
  @observable
  errorItem: Record<string, any> | null | string = null;
  @observable
  data: IDashboardData = {};

  @observable
  sort: string = '';
  @observable
  archiveFilter: boolean = false;
  @observable
  filter: any[] | Record<string, any> = {};

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @action
  private reset() {
    this.data = {};
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  @action
  resetError = () => {
    this.error = null;
    this.errorItem = null;
  };

  @action
  getDashboardInfo = async (payload?: any, meta?: IActionMeta) => {
    this.setLoading();

    this.errorItem = null;
    const [error, response] = await DashboardService.getTestData();

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoaded(() => {
      this.data = response;
      meta?.successFunc?.(response);
    });
  };

  @action
  setSort = (sort: string, { history }: Record<string, any>) => {
    if (history) {
      historyPushPagination({ payload: { sort }, history });
    }

    this.sort = sort;
  };
  @action
  setFilter = (
    filter: any[] | Record<string, any>,
    { history, ignorePage }: Record<string, any>,
  ) => {
    let filterParam = preparePaginationFilterData(filter, { ignorePage });
    if (history) {
      historyPushPagination({
        payload: filterParam,
        history,
        isFilters: true,
      });
    }

    this.filter = filter;
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new DashboardStore();
