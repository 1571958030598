import _get from 'lodash/get';
import { DEFAULT_LANGUAGE } from 'src/constants';
import copy from 'copy-to-clipboard';

interface IFuncFieldByLocale {
  (entity: Record<string, any>, field?: string, locale?: string): string;
}
interface IFuncFieldByLocaleNullable {
  (entity: Record<string, any> | any, field?: string, locale?: string):
    | string
    | null;
}

export const isObject = (object: any): boolean =>
  typeof object === 'object' && object !== null;

export const camelCaseToHumanable = (string: string): string => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1 $2').toLowerCase();
};
export const camelCaseToHumanableTitle = (string: string): string => {
  return toTitleCase(camelCaseToHumanable(string));
};

export function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const lowers = [
  'A',
  'An',
  'The',
  'And',
  'But',
  'Or',
  'For',
  'Nor',
  'As',
  'At',
  'By',
  'For',
  'From',
  'In',
  'Into',
  'Near',
  'Of',
  'On',
  'Onto',
  'To',
  'With',
];

export function toTitleCase(
  str: string,
  includeAllCaps: boolean | undefined = false,
  includeMinorWords: boolean | undefined = false,
) {
  let i, j;
  str = str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    if (!/[a-z]/.test(txt) && /[A-Z]/.test(txt) && !includeAllCaps) {
      return txt;
    } else {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  });

  if (includeMinorWords) {
    return str;
  } else {
    // Certain minor words should be left lowercase unless
    // they are the first or last words in the string

    for (i = 0, j = lowers.length; i < j; i++)
      str = str.replace(
        new RegExp('\\s' + lowers[i] + '\\s', 'g'),
        function (txt: string) {
          return txt.toLowerCase();
        },
      );

    return str;
  }
}

export const getNameByLang: IFuncFieldByLocale = (
  entity,
  field = 'name',
  locale,
) => {
  return (
    (locale && _get(entity, [field, locale])) ||
    _get(entity, [field, DEFAULT_LANGUAGE]) ||
    field
  );
};
export const getNameByLangNullable: IFuncFieldByLocaleNullable = (
  entity,
  field = 'name',
  locale,
) => {
  return (
    (locale && _get(entity, [field, locale])) ||
    _get(entity, [field, DEFAULT_LANGUAGE]) ||
    null
  );
};

export function firstLetterUppercase(string: string | undefined): string {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getQueryVariable(query = '', variable: any) {
  const vars = query.replace('?', '').split('&');

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');

    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }

  return null;
}

export function getItemById(
  entities: Record<string, any>[] | null,
  id: string | number | null | undefined,
) {
  if (!entities || !id) {
    return null;
  }

  return entities?.find((item) => (item._id || item.id) === id);
}

export function formatZipCode(zipCode?: string | null) {
  if (!zipCode) {
    return zipCode;
  }

  if (zipCode?.length <= 5) {
    return zipCode;
  }

  const position = 5;
  return zipCode.slice(0, position) + '-' + zipCode.slice(position);
}

export function formatPhoneNumber(
  phoneNumberString: string | null,
  { intlCode = '+1' } = {},
) {
  if (!phoneNumberString) {
    return '';
  }

  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCodeRes = (match[1] ? '+1 ' : '') || intlCode;
    return [intlCodeRes, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}

export const arrayExcludeArray = (array: string[], arrayExclude: string[]) => {
  return array.filter((el) => !arrayExclude.includes(el));
};

export const isValidHex = (color: string | null | undefined): boolean => {
  if (!color) return false;

  // Validate hex values
  if (color.substring(0, 1) === '#') color = color.substring(1);

  switch (color.length) {
    case 3:
      return /^[0-9A-F]{3}$/i.test(color);
    case 6:
      return /^[0-9A-F]{6}$/i.test(color);
    case 8:
      return /^[0-9A-F]{8}$/i.test(color);
    default:
      return false;
  }
};

export function isHTML(str: string) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

export function checkStringHTML(str: string) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

export const isValidObjectID = (ObjectID: string) => {
  const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
  return checkForHexRegExp.test(ObjectID);
};
export const isValidEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const copyToClipboard = (text: string) => () => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');

  textField.remove();
};

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {}

  document.body.removeChild(textArea);
}
export const copyTextToClipboard = (text: string) => () => {
  const result = copy(text);
  if (result) {
    return;
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text);
};

export function getLastArrayItem(arr: any[]) {
  if (!arr) {
    return null;
  }

  return arr[arr.length - 1];
}
