import React from 'react';
import clsx from 'clsx';

import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { Box, Grid, Link, useTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { OperatorNavbarCenter } from '@platform/front-lib';

import { useStores } from '../../../stores/root-store';

import AnvHeaderLogo from '../../shared/icons/components/AnvHeaderLogo';

import { envConst, ROUTES } from '../../../constants';

import useStyles from '../header/styles';
import ContainerAuthStyle from './style';

const ContainerAuth: React.FC = observer(({ children }) => {
  const style = ContainerAuthStyle();

  const theme = useTheme();
  const screenMD = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const {
    authStores: { isLoggedIn },
  } = useStores();

  const logoSize = screenMD
    ? { width: 186, height: 40 }
    : { width: 139, height: 29 };

  return (
    <Box
      className={clsx(style.wrapper, {
        [style.wrapperFull]: !isLoggedIn,
      })}
    >
      <AppBar position={'fixed'} className={classes.root}>
        <Grid
          container
          justify={'space-between'}
          alignItems={'center'}
          className={classes.rootGrid}
        >
          <Grid item style={{ display: 'flex' }}>
            {isLoggedIn ? (
              <Link
                className={classes.logoLink}
                component={NavLink}
                to={ROUTES.DASHBOARD}
              >
                <AnvHeaderLogo {...logoSize} />
              </Link>
            ) : (
              <Link className={classes.logoLink} href={envConst.launchpadUrl}>
                <AnvHeaderLogo {...logoSize} />
              </Link>
            )}
          </Grid>

          <OperatorNavbarCenter />

          <Grid
            item
            style={{
              width: logoSize.width,
            }}
          />
        </Grid>
      </AppBar>

      {children}
    </Box>
  );
});

export default ContainerAuth;
