import { CancelToken } from 'axios';
import { AnvUtils, axiosInstance, Constants } from '@platform/front-lib';
import type { IGetList } from '@platform/front-lib/dist/service/interfaces';

const axios = axiosInstance;

const {
  envConst: { apiUrl },
} = Constants;

export class EntitiesByAccessService {
  apiUrl = apiUrl + '/complaint';

  async getList(params: IGetList, source: { token?: CancelToken }) {
    return AnvUtils.axiosCatch(
      axios.get(`${this.apiUrl}/data-by-access`, {
        params,
        cancelToken: source.token,
      }),
    );
  }
}
