import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeAutoObservable, observable } from 'mobx';

import { TicketReasonService } from './service';

export class TicketReasonsStore {
  service = new TicketReasonService();
  @observable
  error: Record<string, any> | null | string = null;
  @observable
  data: Record<string, any>[] | null = null;
  @observable
  dataMap: Record<string, any> = {};

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @action
  private reset() {
    this.data = null;
    this.dataMap = {};
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  @action
  cleanError = () => {
    this.isError = false;
    this.error = null;
  };

  @action
  getEntities = async (meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.getEntities();

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data = response;
      response?.forEach((item: Record<string, any>) => {
        this.dataMap[item._id] = item;
      });
      meta?.successFunc?.(response);
    });
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor() {
    makeAutoObservable(this);
  }
}
