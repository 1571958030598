import { axiosCatch } from '../../../service/utils';
import { axiosInstance } from '@platform/front-lib';

const axios = axiosInstance;

export interface ICreateSubmissionPayload {
  content: string;
  files: File[];
  fileTitles: Record<string, any>;
  ticketReason: string;
}

export interface IChangeStatus {
  entityId: string;
  fromStatus: string;
  toStatus: string;
}

export interface IArchive {
  entityId: string;
}

export interface IAddNote {
  entityId: string;
  content: string;
  notifyUser: boolean;
}
export interface IUpdateNote {
  entityId: string;
  content: string;
  noteId: string;
}
export interface IDeleteNote {
  entityId: string;
  noteId: string;
}
export interface IGetOne {
  entityId: string;
}
export interface IDropNoteCounter {
  entityId: string;
}

export class TicketSubmissionService {
  private apiUrl =
    process.env.REACT_APP_API_TOOL_ENDPOINT + '/support/ticket-submissions';

  async createSubmission(payload: ICreateSubmissionPayload) {
    const { files, ...data } = payload;

    const formData = new FormData();

    files.forEach((file: any) => {
      formData.append('files[]', file);
    });

    formData.append('data', JSON.stringify(data, null, 2));

    return axiosCatch(axios.post(`${this.apiUrl}`, formData, {}));
  }

  async getList(params: Record<string, any>) {
    return axiosCatch(axios.get(`${this.apiUrl}`, { params }));
  }

  async changeStatus({ entityId, toStatus, fromStatus }: IChangeStatus) {
    return axiosCatch(
      axios.patch(`${this.apiUrl}/${entityId}`, {
        from: fromStatus,
        to: toStatus,
      }),
    );
  }

  async getOne({ entityId }: IGetOne) {
    return axiosCatch(axios.get(`${this.apiUrl}/${entityId}`, {}));
  }

  async archive({ entityId }: IArchive) {
    return axiosCatch(axios.patch(`${this.apiUrl}/${entityId}/archive`, {}));
  }

  async addNote({ entityId, content, notifyUser }: IAddNote) {
    return axiosCatch(
      axios.post(`${this.apiUrl}/${entityId}/note`, { content, notifyUser }),
    );
  }
  async updateNote({ noteId, entityId, content }: IUpdateNote) {
    return axiosCatch(
      axios.patch(`${this.apiUrl}/${entityId}/note/${noteId}`, { content }),
    );
  }

  async dropNoteCounter({ entityId }: IDropNoteCounter) {
    return axiosCatch(axios.patch(`${this.apiUrl}/${entityId}/notes-counter`));
  }

  async deleteNote({ entityId, noteId }: IDeleteNote) {
    return axiosCatch(
      axios.delete(`${this.apiUrl}/${entityId}/note/${noteId}`, {}),
    );
  }
}
