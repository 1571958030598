import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

// import logoXS from "src/assets/img/Frame_345.svg";
import logoXS from 'src/assets/img/logo-big--support.svg';

const ContainerAuthStyle = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      padding: theme.spacing(0, 1),
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 64px)',
      width: '100%',
      // background: theme.palette.primary.main,
      background: theme.palette.generalTheme.layout.contentBackground,

      [theme.breakpoints.up('md')]: {
        background: `url(${logoXS}) no-repeat ${theme.palette.generalTheme.layout.contentBackground}`,
        backgroundPosition: 'calc(50% - -80px) calc(100% - 57px)',
      },
    },

    wrapperFull: {
      minHeight: '100vh',
    },
  }),
);

export default ContainerAuthStyle;
