import React from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormattedMessage, useIntl } from 'react-intl';
import { TFormatMessage } from '../../../@types/intl/intl';
import * as yup from 'yup';

import { Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';

import {
  AnvAutocomplete2,
  AnvButton,
  AnvModalInfo,
  AnvTextField,
  useModal,
} from '@platform/front-lib';

import { validationsMessages } from '../../../constants';
import { IProfileOperatorBaseForm } from './_interfaces';
import { useStores } from '../../../stores/root-store';
import { formErrorHelper, prepareOptions } from '../../../utils';

import { observer } from 'mobx-react-lite';

import useStylesCommon from '../styles';

interface IProfileBaseProps {}

const validationSchema = (
  fm: TFormatMessage,
  { isPhoneChanged = false }: { isPhoneChanged: boolean },
) => {
  const shape: Record<string, any> = {
    firstName: yup.string().required(fm(validationsMessages.required())),
    lastName: yup.string().required(fm(validationsMessages.required())),
  };

  if (isPhoneChanged) {
    shape.code = yup
      .string()
      .test('phone', fm(validationsMessages.required()), function () {
        if (!this.parent.phone) {
          return true;
        }

        return !!this.parent.code;
      });
  }

  return yup.object().shape(shape);
};

export const ProfileBase: React.FC<IProfileBaseProps> = observer(() => {
  const { locale, formatMessage } = useIntl();
  const classesCommon = useStylesCommon();
  const theme = useTheme();
  const screenSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [phoneState, setPhoneState] = React.useState(false);

  const {
    authStores: { profile },
    profileOperatorStore: {
      profileBaseStore: {
        profileData,
        isFetching,
        error: storeError,
        update,
        get,
      },
    },
    metaStores: {
      jurisdictionStore: { data: jurisdictions, isLoaded, getJurisdictions },
    },
  } = useStores();

  const profileId = profile?.id;

  React.useEffect(() => {
    get();
  }, [profileId, get]);

  React.useEffect(() => {
    if (!isLoaded) {
      getJurisdictions();
    }
  }, [isLoaded, getJurisdictions]);

  const {
    errors,
    handleSubmit,
    control,
    reset,
  } = useForm<IProfileOperatorBaseForm>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',

      phone: '',
      code: '',
      operatorJurisdictions: [],
    },
    resolver: yupResolver(
      validationSchema(formatMessage, {
        isPhoneChanged: phoneState,
      }),
    ),
  });

  const handleDiscard = () => {
    if (profileData) {
      reset(profileData);
    }
  };

  const phoneWatch = useWatch({
    name: 'phone',
    control,
    defaultValue: profileData?.phone ? profileData?.phone : '',
  });

  const isPhoneChanged = phoneWatch !== (profileData?.phone || '');
  React.useEffect(() => {
    if (isPhoneChanged !== phoneState) {
      setPhoneState(isPhoneChanged);
    }
  }, [isPhoneChanged, phoneState, setPhoneState]);

  React.useEffect(() => {
    if (profileData) {
      reset(profileData);
    }
  }, [profileData, reset]);

  const { handleToggleModal, isOpen } = useModal();

  const handleUpdate = (fields: IProfileOperatorBaseForm) => {
    // delete fields.operatorJurisdictions;

    update(
      {
        ...fields,
      },
      {
        successFunc: () => {
          handleToggleModal();
        },
      },
    );
  };

  const formHelper = (field: string) => {
    return formErrorHelper(field, errors, storeError);
  };

  const jurisdictionOptions = prepareOptions(jurisdictions, {
    sortByLabel: true,
    field: 'name',
    locale,
  });

  const isFormDisabled = isFetching;

  return (
    <>
      <form onSubmit={handleSubmit(handleUpdate)} noValidate>
        <div className={classesCommon.form}>
          <Grid container spacing={screenSM ? 0 : 2}>
            <Grid item xs={12} sm={6}>
              <AnvTextField
                disabled={isFormDisabled}
                control={control}
                name={'firstName'}
                required
                id="firstName"
                label={
                  <FormattedMessage
                    id="LAUNCHPAD.AUTH.SIGN_UP.FORM.FIRST_NAME.LABEL"
                    defaultMessage="First Name"
                  />
                }
                fullWidth
                helperText={formHelper('firstName')}
                error={!!formHelper('firstName')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AnvTextField
                disabled={isFormDisabled}
                control={control}
                name={'lastName'}
                required
                id="lastName"
                label={
                  <FormattedMessage
                    id="LAUNCHPAD.AUTH.SIGN_UP.FORM.LAST_NAME.LABEL"
                    defaultMessage="Last Name"
                  />
                }
                fullWidth
                helperText={formHelper('lastName')}
                error={!!formHelper('lastName')}
              />
            </Grid>
          </Grid>

          <AnvTextField
            disabled={isFormDisabled}
            control={control}
            name={'middleName'}
            id="middleName"
            label={
              <FormattedMessage
                id="LAUNCHPAD.AUTH.SIGN_UP.FORM.MIDDLE_NAME.LABEL"
                defaultMessage="Middle Name"
              />
            }
            fullWidth
            helperText={formHelper('middleName')}
            error={!!formHelper('middleName')}
          />

          <TextField
            disabled
            name={'email'}
            required
            id="email"
            value={profile?.email}
            label={
              <FormattedMessage
                id="LAUNCHPAD.AUTH.SIGN_UP.FORM.EMAIL.LABEL"
                defaultMessage="Email"
              />
            }
            fullWidth
            helperText={formHelper('email')}
            error={!!formHelper('email')}
          />

          <AnvAutocomplete2
            multiple
            control={control}
            name={'operatorJurisdictions'}
            options={jurisdictionOptions}
            disabled
            label={
              <FormattedMessage
                id="APP.COMMON.LABEL.JURISDICTION"
                defaultMessage="U.S. State or Territory"
              />
            }
            fullWidth
            helperText={formHelper('operatorJurisdictions')}
            error={!!formHelper('operatorJurisdictions')}
          />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AnvButton
              fullWidth
              variant={'contained'}
              color={'primary'}
              type={'submit'}
            >
              <FormattedMessage
                id="APP.SAVE_CHANGES"
                defaultMessage="Save Changes"
              />
            </AnvButton>
          </Grid>

          <Grid item xs={12}>
            <AnvButton
              fullWidth
              variant={'outlined'}
              customColor={'error'}
              onClick={handleDiscard}
              disabled={!profileData}
            >
              <FormattedMessage
                id="APP.DISCARD_CHANGES"
                defaultMessage="Discard Changes"
              />
            </AnvButton>
          </Grid>
        </Grid>
      </form>

      <AnvModalInfo handleClose={handleToggleModal} open={isOpen}>
        <FormattedMessage
          id="LAUNCHPAD.PROFILE.BASE.MODAL.SUCCESS.TEXT"
          defaultMessage="Profile successfully changed"
        />
      </AnvModalInfo>
    </>
  );
});
