import { CancelToken } from 'axios';

import { axiosInstance } from '@platform/front-lib';
import { axiosCatch } from '../../../service/utils';
import {
  IComplaintCategorySettingCreate,
  IComplaintCategorySettingDelete,
  IComplaintCategorySettingUpdate,
} from './types';

const axios = axiosInstance;

export class ComplaintCategoriesSettingsService {
  private apiUrl =
    process.env.REACT_APP_API_TOOL_ENDPOINT + '/complaint/categories';

  async getList(
    { id, ...params }: { id: string; [index: string]: any },
    source: { token?: CancelToken },
  ) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/${id}/settings`, {
        params,
        cancelToken: source.token,
      }),
    );
  }

  async getOne({ id, settingId }: { id: string; settingId: string }) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/${id}/settings/${settingId}`, {}),
    );
  }

  async createOne({ id, data }: IComplaintCategorySettingCreate) {
    return axiosCatch(axios.post(`${this.apiUrl}/${id}/settings`, data));
  }

  async updateOne({ id, settingId, data }: IComplaintCategorySettingUpdate) {
    return axiosCatch(
      axios.patch(`${this.apiUrl}/${id}/settings/${settingId}`, data),
    );
  }

  async deleteOne({ id, settingId }: IComplaintCategorySettingDelete) {
    return axiosCatch(
      axios.delete(`${this.apiUrl}/${id}/settings/${settingId}`),
    );
  }
}
