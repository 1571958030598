export const DEFAULT_LANGUAGE = 'en';
export const PAGINATION_LIMIT = 20;
export const AUTOCOMPLETE_SERVER_LIMIT = 20;

export const AUTOCOMPLETE_HISTORY_LIMIT = 5;

export const INPUT_THROTTLE_TIME = 500;

export const PATH_TO_MODULES = '/modules';

export const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';
export const AUTH_TOKEN_REFRESH_KEY = 'AUTH_TOKEN_REFRESH_KEY';
export const AUTH_PROFILE_KEY = 'AUTH_PROFILE_KEY';
export const AUTH_MODULES_KEY = 'AUTH_MODULES_KEY';
export const LANGUAGES_KEY = 'LANGUAGES_KEY';
export const TRANSLATIONS_KEY = 'TRANSLATIONS_KEY';

export const INVITATION_STATUSES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  EXTENDED: 'EXTENDED',
};

export const ACCESS_SCOPES = {
  SCHOOL: 'SCHOOL',
  AUTHORIZER: 'AUTHORIZER',
  EMO: 'EMO',
  ESC: 'ESC',
  SEA: 'SEA',
  LEA: 'LEA',
  CMO: 'CMO',
  DEPARTMENT: 'DEPARTMENT',
};

export const ORGANIZATION_TYPE_CODES = {
  AUTHORIZER: 'AUTHORIZER',
  EMO: 'EMO',
  ESC: 'ESC',
  SEA: 'SEA',
  LEA: 'LEA',
  HEA: 'HEA',

  CMO: 'CMO',
  DEPARTMENT: 'DEPARTMENT',
};

export const imagesMimeTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
];

interface IDateFormats {
  [key: string]: string;
}

export const CONTENT_STATUS = {
  LOCAL_DRAFT: '1',
  REMOTE_DRAFT: '2',
  DELETED: '3',
  PUBLISHED: '4',
  ARCHIVED: '5',
  EXPIRED: '6',
};

export const DATE_FORMATS: IDateFormats = {
  DEFAULT_FORMAT: 'MM/dd/yyyy',
  DEFAULT_FORMAT_WITH_TIME: 'MM/dd/yyyy kk:mm',
  DEFAULT_FORMAT_REVERSE: 'yyyy-MM-dd',
  TIME: 'kk:mm',
  SHORT_LETTER_MONTH_FORMAT: 'MMM d, yyyy',
  LONG_LETTER_MONTH_FORMAT: 'MMMM do, yyyy',
};
export const REQUEST_STATUSES = {
  APPROVED: 'APPROVED',
  REVIEW: 'REVIEW',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  DENIED: 'DENIED',
  REVOKED: 'REVOKED',
};

export const transitionByModule: any = {
  BUILD: {
    USER_REQUEST: {
      REVIEW: [REQUEST_STATUSES.APPROVED, REQUEST_STATUSES.DENIED],
      APPROVED: [REQUEST_STATUSES.REVOKED],
      EXPIRED: [REQUEST_STATUSES.APPROVED, REQUEST_STATUSES.CANCELLED],
    },
    BUILDER_REQUEST: {
      REVIEW: [REQUEST_STATUSES.CANCELLED],
      APPROVED: [REQUEST_STATUSES.REVOKED],
      EXPIRED: [REQUEST_STATUSES.APPROVED, REQUEST_STATUSES.CANCELLED],
    },
  },
  VIEW: {
    BUILDER_REQUEST: {
      REVIEW: [REQUEST_STATUSES.APPROVED, REQUEST_STATUSES.DENIED],
    },
    USER_REQUEST: {
      REVIEW: [REQUEST_STATUSES.CANCELLED],
    },
  },
};

export const outlinedButtonStatus = [
  REQUEST_STATUSES.DENIED,
  REQUEST_STATUSES.REVOKED,
  REQUEST_STATUSES.CANCELLED,
];
