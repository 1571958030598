import { IGetList } from 'src/service/interfaces';
import { axiosCatch } from 'src/service/utils';
import { axiosInstance } from '@platform/front-lib';

const axios = axiosInstance;

class UserService {
  private apiUrl = process.env.REACT_APP_API_TOOL_ENDPOINT + '/users';

  async getAll(data?: IGetList) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/list`, {
        params: data,
      }),
    );
  }

  async getOne(data?: IGetList) {
    return axiosCatch(axios.get(`${this.apiUrl}/${data?.id}`));
  }

  async getUserSettings(data?: IGetList) {
    return axiosCatch(axios.get(`${this.apiUrl}/${data?.id}/settings`));
  }

  async setUserSettings(data: any) {
    const { userId, ...payload } = data;
    return axiosCatch(
      axios.patch(`${this.apiUrl}/${userId}/settings`, payload, {}),
    );
  }

  //todo interface
  async deleteOne(data: { _id: string }) {
    return axiosCatch(axios.delete(`${this.apiUrl}/${data._id}`, {}));
  }

  async deleteUser(data: { id: string }) {
    return axiosCatch(axios.delete(`${this.apiUrl}/${data.id}`, {}));
  }
  async changePassword(data: { id: string; password: string }) {
    return axiosCatch(axios.post(`${this.apiUrl}/change-password`, data));
  }
  // async activateAccount(data: { id: string; password: string }) {
  //   return axiosCatch(axios.post(`${this.apiUrl}/activate/${data.id}`, data));
  // }

  // export const changePassword = (data: { id: string; password: string }) => {
  //   return axiosCatch(
  //     axios.post(`${envConst.apiFileUrl}/users/change-password`, data, {
  //       headers: {
  //         Accept: 'application/json',
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //     })
  //   );
  // };
  //
  // export const deleteUser = (data: { id: string }) => {
  //   return axiosCatch(
  //     axios.delete(`${envConst.apiFileUrl}/users/${data.id}`, {
  //       headers: {
  //         Accept: 'application/json',
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //     })
  //   );
  // };
}

export default new UserService();
