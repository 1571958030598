import React from 'react';

import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { FormattedNumber } from 'react-intl';

import { IDashboardContentItem } from '../card/card.intefaces';
import { useStylesContent } from './styles';

interface IProps {
  content?: IDashboardContentItem[];
}

export const AnvCardContent: React.FC<IProps> = ({ content }) => {
  const classes = useStylesContent();

  return (
    <div className={classes.contentWrapper}>
      {content?.map?.((item: any, index) => {
        return (
          <Grid
            key={index}
            container
            item
            direction={'row'}
            xs={12}
            spacing={0}
            justify={'space-between'}
            className={clsx(classes.contentItemWrapper, {
              [classes.itemSubItemWrapper]: !!item.subItem,
              'sub-item': item.subItem,
              'root-item': !!item.subItem,
            })}
          >
            <Grid
              item
              xs={item.subItem ? 8 : 9}
              className={clsx(classes.label, {
                [classes.labelSubItem]: !!item.subItem,
              })}
            >
              {item.label}
            </Grid>
            <Grid
              item
              xs={3}
              className={clsx(classes.value, {
                [classes.valueSubItem]: !!item.subItem,
              })}
            >
              {typeof item.value === 'number' ? (
                <FormattedNumber value={item.value} />
              ) : (
                item.value
              )}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};
