import React from 'react';

import { observer } from 'mobx-react-lite';

const RedirectSourceSystemFail = observer(() => {
  return (
    <div className="login__container">
      <div className="login__form"></div>
    </div>
  );
});

export default RedirectSourceSystemFail;
