import { axiosInstance } from '@platform/front-lib';
import { axiosCatch } from '../../../service/utils';

const axios = axiosInstance;

export class TicketStatusService {
  private apiUrl =
    process.env.REACT_APP_API_TOOL_ENDPOINT + '/support/ticket-statuses';

  async getEntities() {
    return axiosCatch(axios.get(`${this.apiUrl}`, {}));
  }
}
