import {
  FormsBuilderStoreClass,
  FormsTemplatesHistoryStoreClass,
} from '@platform/front-lib-forms';

import { ComplaintCategoryStore } from './categories/store';
import { ComplaintCategorySettingsStore } from './categories-settings/store';
import { EntitiesByAccessStore } from './entities-by-access';
import { FormsEntityTemplatesStore } from './forms';
import { CFormsTemplateServiceCommon } from './forms/service/common';
import { FormsTemplatesHistoryService } from './forms/forms-history/forms-history.service';

export const complaintStore = {
  entitiesByAccessStore: new EntitiesByAccessStore(),
  complaintCategoriesStore: new ComplaintCategoryStore(),
  complaintCategorySettingsStore: new ComplaintCategorySettingsStore(),

  formsStore: {
    builder: new FormsBuilderStoreClass({
      FormsTemplateService: new CFormsTemplateServiceCommon({
        tool: 'complaint',
      }),
    }),

    entity: new FormsEntityTemplatesStore({
      tool: 'complaint',
    }),

    historyStore: new FormsTemplatesHistoryStoreClass(
      FormsTemplatesHistoryService,
    ),
  },
};
