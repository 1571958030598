import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const useStylesCommon = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    commonWrapper: {
      maxWidth: 560,

      [theme.breakpoints.up('sm')]: {
        margin: '0 auto',
        width: 560,
      },
    },
    titleWrapper: {
      [theme.breakpoints.up('sm')]: {
        margin: '0 auto',
        width: 560,
      },
    },
    contentWrapper: {
      paddingBottom: theme.spacing(4),

      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),

      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },

    tabs: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        '& > .MuiButtonBase-root': {
          '&:hover': {
            backgroundColor: theme.palette.brand.main,
          },
        },
      },
    },
    form: {
      marginBottom: theme.spacing(6),
    },
  }),
);

export default useStylesCommon;
