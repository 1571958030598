import React from 'react';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { CssBaseline } from '@material-ui/core';

import { useStores } from 'src/stores/root-store';

import {
  AnvDefaultLayout,
  AnvDrawerOperator,
  AnvFooter,
  Constants,
  GdprBar,
  OperatorNavbarCenter,
  useInterval,
} from '@platform/front-lib';

import AnvAppBarSupport from '../header';
import { getCookieToken } from '../../../utils/cookie';

const { drawerOperator } = Constants;

const ContainerLayout: React.FC = observer(({ children }) => {
  const {
    uiStores,
    authStores: {
      token,
      profile,
      isLoggedIn,
      updateDataFromCookie,
      logOut,
      setActiveLocale,
    },
    metaStores,
  } = useStores();

  const {
    generalSettingStore: { getGeneralSettings },
    toolsStore: {
      getTools,
      data: tools,
      dataMap: toolsMapClean,
      isLoaded: isLoadedTools,
    },
  } = metaStores;

  const { drawerOpen, toggleDrawerOpen, languages } = uiStores;

  /////////////////////////

  const toolsMap = React.useMemo(() => {
    return toJS(toolsMapClean);
    // force update props for drawer
    // eslint-disable-next-line
  }, [tools, toolsMapClean]);

  React.useEffect(() => {
    getGeneralSettings({});
  }, [getGeneralSettings]);

  React.useEffect(() => {
    if (!isLoadedTools && isLoggedIn) {
      getTools({
        filter: {
          disabled: false,
        },
      });
    }
  }, [isLoadedTools, isLoggedIn, getTools]);

  useInterval(() => {
    if (
      getCookieToken({ isOperator: true }) !== token &&
      (getCookieToken({ isOperator: true }) || token)
    ) {
      updateDataFromCookie();
    }
  }, 2000);

  const header = (
    <AnvAppBarSupport
      profile={profile}
      isLoggedIn={isLoggedIn}
      toggleDrawerOpen={toggleDrawerOpen}
      drawerOpen={drawerOpen}
      logOut={logOut}
      customCenterTitle={<OperatorNavbarCenter />}
    />
  );

  const drawer = (
    <AnvDrawerOperator
      drawerData={drawerOperator}
      toolsMap={toolsMap}
      profile={profile}
      logOut={logOut}
      drawerOpen={drawerOpen}
      toggleDrawerOpen={toggleDrawerOpen}
      languages={languages}
      setActiveLocale={setActiveLocale}
    />
  );

  const footer = (
    <AnvFooter
      justify={'space-between'}
      languages={uiStores.languages}
      setActiveLocale={setActiveLocale}
      metaStores={metaStores}
      showStaticLinks={false}

    />
  );

  return (
    <>
      {isLoggedIn ? (
        <AnvDefaultLayout
          header={header}
          drawer={drawer}
          footer={footer}
          drawerOpen={drawerOpen}
        >
          {children}
        </AnvDefaultLayout>
      ) : (
        <div>
          <CssBaseline />
          {children}
        </div>
      )}

      <GdprBar />
    </>
  );
});

export default ContainerLayout;
