import { action, makeAutoObservable, observable } from 'mobx';
import { AnvUtils } from '@platform/front-lib';

import { IActionMeta, IPagination } from '../_helpers/interfaces';
import { defaultPagination } from '../_helpers/defaults';

import UserService from '../../service/users';

interface IPayloadDelete {
  _id: string;
}

const { historyPushPagination, preparePaginationFilterData } = AnvUtils;

//todo user interface
class UsersStore {
  @observable
  error: Record<string, any> | null | string = null;
  @observable
  dataMap: Record<string, Record<string, any>> = {};
  @observable
  data: Record<string, any>[] | null = null;
  @observable
  pagination: IPagination = defaultPagination;
  @observable
  sort: string = '';
  @observable
  filter: any[] | Record<string, any> = {};

  @observable
  detailedMap: Record<string, Record<string, any>> = {};

  @observable
  usersSettingsMap: Record<string, Record<string, any>> = {};

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @action
  private reset() {
    this.data = null;
    this.dataMap = {};
    this.detailedMap = {};
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  @action
  getEntities = async (payload: { [index: string]: any }) => {
    this.setLoading();

    // todo module
    const [error, response] = await UserService.getAll(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      const { docs, distinct, ...pagination } = response;
      this.pagination = pagination;
      this.data = docs || [];
      this.data?.forEach?.((item) => {
        this.dataMap[item._id] = item;
      });
    });
  };

  @action
  getEntity = async (payload: { [index: string]: any }) => {
    this.setLoading();

    const [error, response] = await UserService.getOne(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.detailedMap[response._id] = response;
    });
  };

  @action
  getUserSettings = async (payload: { [index: string]: any }) => {
    this.setLoading();

    const [error, response] = await UserService.getUserSettings(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.usersSettingsMap[payload.id] = response;
    });
  };

  @action
  setUserSettings = async (payload: any, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await UserService.setUserSettings(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.();
      this.usersSettingsMap[payload.userId] = response;
    });
  };

  @action
  delete = async (payload: IPayloadDelete, meta: IActionMeta) => {
    this.setLoading();

    // todo module
    const [error, response] = await UserService.deleteOne(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      meta.successFunc?.();
    });
  };

  @action
  changePassword = async (
    payload: { id: string; password: string },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await UserService.changePassword(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
        return [error, response];
      });
    }

    return this.setLoaded(() => {
      meta?.successFunc?.();

      return [error, response];
    });
  };

  // @action
  // activateAccount = async (
  //   payload: { id: string; password:string },
  //   meta?: IActionMeta,
  // ) => {
  //   this.setLoading();
  //
  //   const [error, response] = await UserService.activateAccount(payload);
  //
  //   if (error || response?.error) {
  //     return this.setError(() => {
  //       this.error = error || response?.error;
  //       return [error, response];
  //     });
  //   }
  //
  //   return this.setLoaded(() => {
  //     meta?.successFunc?.();
  //
  //     return [error, response];
  //   });
  // };

  @action
  setPage = (page: number, { history }: Record<string, any>) => {
    if (history) {
      historyPushPagination({ payload: { page }, history });
    }

    this.pagination.page = page;
  };
  @action
  setSort = (sort: string, { history }: Record<string, any>) => {
    if (history) {
      historyPushPagination({ payload: { sort }, history });
    }

    this.sort = sort;
  };
  @action
  setFilter = (
    filter: any[] | Record<string, any>,
    { history }: Record<string, any>,
  ) => {
    let filterParam = preparePaginationFilterData(filter);
    if (history) {
      historyPushPagination({
        payload: filterParam,
        history,
        isFilters: true,
      });
    }

    this.filter = filter;
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    return func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isSaving = false;
    this.isError = true;

    return func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isSaving = false;
    this.isLoaded = true;
    this.isError = false;

    return func?.();
  };

  constructor() {
    makeAutoObservable(this);
  }
}
export default new UsersStore();
