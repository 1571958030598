import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  AnvAutocomplete2,
  AnvButton,
  AnvDialog,
  AnvDialogActions,
  AnvDialogBody,
  AnvDialogSubTitle,
  AnvDialogTitle,
  AnvSelect,
  AnvTextCKEditor,
  AnvUtils,
  Constants,
  useCommonOffsetsStyles,
  useDebounce,
} from '@platform/front-lib';

import type {
  IComplaintCategory,
  IComplaintCategorySetting,
} from '@platform/front-lib/dist/models';

import { useStores } from '../../../../stores/root-store';

import { IComplaintCategorySettingForm } from './types';
import { validationSchema } from './yup.schema';
import { ComplaintSettingFormTemplatePart } from './forms-template-part';

interface IComplaintSettingFormProps {
  children?: React.ReactNode;
  open: boolean;
  handleClose: () => void;

  categoryEntity?: IComplaintCategory;

  id?: string | null;
  existsValues?: Partial<IComplaintCategorySetting>;

  getEntities: () => void;
}

const { ACCESS_SCOPES } = Constants;
const entitiesByAccessRequestId = 'complaint-categories';

export const ComplaintSettingForm: React.FC<IComplaintSettingFormProps> = observer(
  ({
    categoryEntity,
    open,
    handleClose,
    existsValues = {} as Partial<IComplaintCategorySetting>,
    id,
    getEntities,
  }) => {
    const { formatMessage, locale } = useIntl();

    const classesOffset = useCommonOffsetsStyles();

    const {
      uiStores: { languages },
      profileOperatorStore: {
        profileBaseStore: {
          profileData,
          isLoaded: isProfileLoaded,
          get: getProfile,
        },
      },
      metaStores: {
        accessScopeStore: {
          data: accessScopes,
          getAccessScopes,
          isLoaded: isLoadedAccessScopes,
        },
        jurisdictionStore: {
          data: jurisdictions,
          getList: getJurisdictionList,
          isLoadedList: isLoadedJurisdictionList,
        },

        reviewSeveritiesStore: {
          getList: getSeverities,
          data: severities,
          isLoadedList: isLoadedSeverities,
        },
      },
      complaintStore: {
        formsStore: {
          builder: { getOne: getOneTemplate },
          entity: { updateItem },
        },
        entitiesByAccessStore: {
          getList: getEntitiesByAccess,
          getListMetaDataByRequestId: getEntitiesByAccessMetaDataByRequestId,
        },
        complaintCategorySettingsStore: {
          createOne,
          updateOne,

          isFetching,
          cleanError,

          error,
        },
      },
    } = useStores();

    React.useEffect(() => {
      return () => {
        cleanError();
      };
    }, [cleanError]);

    // entitiesByAccessMap

    const {
      dataList: entitiesByAccess,
      isFetchingList: isFetchingListEntitiesByAccess,
      // isLoadedList: isLoadedListListEntitiesByAccess,
      total: entitiesByAccessTotal,
    } = getEntitiesByAccessMetaDataByRequestId(entitiesByAccessRequestId);

    const [orgInputValue, setOrgInputValue] = React.useState('');

    const orgInputDelayed = useDebounce(
      orgInputValue,
      Constants.INPUT_THROTTLE_TIME,
    );

    const entitiesOptions = React.useMemo(() => {
      return AnvUtils.prepareOptions(entitiesByAccess, {
        field: 'name',
        locale,

        captionBottomFunc: (item) => {
          if (item.isSchool) {
            return AnvUtils.prepareSchoolAddressCaption({
              // address: item.address,
              school: item,
              locale,
              classes: {},
            });
          }

          return '';
        },
      });
    }, [entitiesByAccess, locale]);
    ///

    // load data
    React.useEffect(() => {
      if (!isProfileLoaded) {
        getProfile({});
      }
    }, [isProfileLoaded, getProfile]);

    React.useEffect(() => {
      if (!isLoadedAccessScopes) {
        getAccessScopes({});
      }
    }, [isLoadedAccessScopes, getAccessScopes]);

    const entityTypesOptions = React.useMemo(() => {
      // const accessScopesFiltered = accessScopes?.filter((item) => {
      //   return [ACCESS_SCOPES.LEA, ACCESS_SCOPES.SEA].includes(item.code);
      // });

      return AnvUtils.prepareOptions(accessScopes, {
        field: 'description',
        locale,
      });

      // options.push({
      //   value: 'school',
      //   label: formatMessage({
      //     id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.ENTITY_TYPE.SCHOOL',
      //     defaultMessage: 'School',
      //   }),
      // });

      // return options;
    }, [accessScopes, locale]);

    React.useEffect(() => {
      if (!isLoadedSeverities) {
        getSeverities({});
      }
    }, [isLoadedSeverities, getSeverities]);

    const severitiesOptions = React.useMemo(() => {
      return AnvUtils.prepareOptions(severities, {
        field: 'name',
        locale,
      });
    }, [severities, locale]);

    React.useEffect(() => {
      if (!isLoadedJurisdictionList) {
        getJurisdictionList({});
      }
    }, [isLoadedJurisdictionList, getJurisdictionList]);
    //
    const jurisdictionOptions = React.useMemo(() => {
      const categoryJurisdiction = categoryEntity?.jurisdiction;
      const operatorJurisdictions = profileData?.operatorJurisdictions;

      const filteredByOperator = operatorJurisdictions?.length
        ? jurisdictions.filter((item) => {
            return operatorJurisdictions?.includes(item._id);
          })
        : jurisdictions;

      const filteredByCategory = categoryJurisdiction
        ? filteredByOperator.filter((item) => {
            return item._id === categoryJurisdiction;
          })
        : filteredByOperator;

      return AnvUtils.prepareOptions(filteredByCategory, {
        field: 'name',
        locale,
      });
    }, [
      jurisdictions,
      categoryEntity,
      profileData?.operatorJurisdictions,
      locale,
    ]);
    //

    const methods = useForm<IComplaintCategorySettingForm>({
      resolver: yupResolver(validationSchema(formatMessage)),
      defaultValues: {
        complaintCategory: categoryEntity?._id || '',
        jurisdiction: existsValues.jurisdiction || '',
        entityType: existsValues.entityType || '',
        formsTemplate: existsValues.formsTemplate || '',
        severity: existsValues.severity || '',
        entity: existsValues.school || existsValues.organization || '',
        // anonymityProtection: existsValues.anonymityProtection || false,
        instruction: existsValues.instruction || {},
      },
    });

    const {
      handleSubmit,
      trigger,
      control,
      setValue,
      watch,
      formState: { errors },
    } = methods;

    const watchJurisdiction = watch('jurisdiction');
    const watchEntityType = watch('entityType');

    // get entities
    React.useEffect(() => {
      if (!(watchJurisdiction && watchEntityType)) {
        return;
      }

      const data: Record<string, any> = {
        getWithTotal: true,
        filter: {
          jurisdiction: { eq: watchJurisdiction },
        },
      };

      if (watchEntityType === ACCESS_SCOPES.SCHOOL) {
        data.filter.organizationType = { is: null };
      } else {
        data.filter.organizationType = { eq: watchEntityType };
      }

      if (orgInputDelayed) {
        data.name = orgInputDelayed;
        data.locale = locale;
      }

      getEntitiesByAccess(data, { requestId: entitiesByAccessRequestId });
    }, [
      getEntitiesByAccess,
      watchJurisdiction,
      watchEntityType,
      locale,
      orgInputDelayed,
    ]);
    // get entities end

    const onSubmit = handleSubmit((fields) => {
      const isSchool = fields.entityType === ACCESS_SCOPES.SCHOOL;

      const school = isSchool ? fields.entity : undefined;
      const organization = !isSchool ? fields.entity : undefined;

      const payload = {
        ...fields,
        school,
        organization,
        entity: undefined,

        instruction: {
          ...(fields.instruction || {}),
          en: fields.instruction.en || '',
        },

        complaintCategory: categoryEntity?._id,

        // anonymityProtection: fields.anonymityProtection || false,

        reviewPeriod: fields.reviewPeriod || categoryEntity?.reviewPeriod || 0,
        reviewed: fields.reviewed || categoryEntity?.reviewed || 0,
      };

      const successFunc = () => {
        handleClose();
        getEntities?.();

        getOneTemplate(
          { id: fields.formsTemplate },
          {
            successFunc: (response: any) => {
              updateItem(response);
            },
          },
        );
      };

      const meta = { successFunc };

      if (id) {
        updateOne(
          {
            settingId: id,
            id: categoryEntity?._id || '',
            data: payload,
          },
          meta,
        );

        return;
      }

      createOne(
        {
          id: categoryEntity?._id || '',
          data: payload,
        },
        meta,
      );
      // todo create or update
    });

    const isFormDisabled = isFetching;

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, error);
    };

    return (
      <AnvDialog open={open} handleClose={handleClose} disableEnforceFocus>
        <AnvDialogTitle>
          <FormattedMessage
            id={'SUPPORT.COMPLAINT.CATEGORIES.FORM.TITLE'}
            defaultMessage="Category Settings"
          />

          <AnvDialogSubTitle>
            {AnvUtils.getNameByLangNullable(
              categoryEntity,
              'description',
              locale,
            )}
          </AnvDialogSubTitle>
        </AnvDialogTitle>

        <AnvDialogBody>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <AnvSelect
                disabled={!!id}
                name={'jurisdiction'}
                control={control}
                label={formatMessage({
                  id: 'SUPPORT.COMPLAINT.CATEGORIES.FORM.LABEL.JURISDICTION',
                  defaultMessage: 'Jurisdiction',
                })}
                required
                handleOnChange={(e) => {
                  setOrgInputValue('');
                  setValue('entity', '');
                }}
                options={jurisdictionOptions} // todo by operator jurisdictions
                fullWidth
                helperText={formHelper('jurisdiction')}
                error={!!formHelper('jurisdiction')}
              />

              <AnvSelect
                disabled={!!id}
                name={'entityType'}
                control={control}
                label={formatMessage({
                  id: 'SUPPORT.COMPLAINT.CATEGORIES.FORM.LABEL.ENTITY_TYPE',
                  defaultMessage: 'Entity Type',
                })}
                required
                handleOnChange={(e) => {
                  setOrgInputValue('');
                  setValue('entity', '');
                }}
                options={entityTypesOptions}
                fullWidth
                helperText={formHelper('entityType')}
                error={!!formHelper('entityType')}
              />

              <AnvAutocomplete2
                control={control}
                disabled={!!id || !(watchJurisdiction && watchEntityType)}
                name={'entity'}
                label={formatMessage({
                  id: 'SUPPORT.COMPLAINT.CATEGORIES.FORM.LABEL.ENTITY',
                  defaultMessage: 'Entity',
                })}
                loading={isFetchingListEntitiesByAccess}
                options={entitiesOptions}
                fullWidth
                required
                total={entitiesByAccessTotal}
                onInputChange={(event, newInputValue) => {
                  setOrgInputValue(newInputValue);
                }}
                helperText={formHelper('entity')}
                error={!!formHelper('entity')}
              />

              <AnvSelect
                disabled={isFormDisabled}
                name={'severity'}
                control={control}
                label={formatMessage({
                  id: 'SUPPORT.COMPLAINT.CATEGORIES.FORM.LABEL.SEVERITY',
                  defaultMessage: 'Severity',
                })}
                required
                options={severitiesOptions}
                fullWidth
                helperText={formHelper('severity')}
                error={!!formHelper('severity')}
              />

              {/*<div className={classesOffset.mb_2}>*/}
              {/*  <AnvSwitch*/}
              {/*    disabled={isFormDisabled}*/}
              {/*    name={'anonymityProtection'}*/}
              {/*    control={control}*/}
              {/*    label={formatMessage({*/}
              {/*      id:*/}
              {/*        'SUPPORT.COMPLAINT.CATEGORIES.FORM.LABEL.ANONYMITY_PROTECTION',*/}
              {/*      defaultMessage: 'Anonymity Protection',*/}
              {/*    })}*/}
              {/*  />*/}
              {/*</div>*/}

              {languages?.map((lang) => (
                <>
                  <AnvTextCKEditor
                    disabled={isFormDisabled}
                    key={`instruction.${lang.code}`}
                    control={control}
                    trigger={trigger}
                    name={`instruction.${lang.code}`}
                    placeholder={formatMessage(
                      {
                        id:
                          'SUPPORT.COMPLAINT.CATEGORIES.FORM.LABEL.INSTRUCTION',
                        defaultMessage: `Instruction {lang}`,
                      },
                      {
                        lang: lang.name,
                      },
                    )}
                    helperText={formHelper(`instruction.${lang.code}`)}
                    error={!!formHelper(`instruction.${lang.code}`)}
                  />
                  <div style={{ height: 16 }} />
                </>
              ))}

              {categoryEntity?._id && (
                <ComplaintSettingFormTemplatePart
                  categoryId={categoryEntity._id}
                />
              )}
            </form>
          </FormProvider>
        </AnvDialogBody>

        <AnvDialogActions>
          <div className={classesOffset.mb_2}>
            <AnvButton
              disabled={isFormDisabled}
              fullWidth
              variant={'contained'}
              onClick={onSubmit}
            >
              <FormattedMessage
                id={'SUPPORT.COMPLAINT.CATEGORIES.FORM.ACTION.SAVE'}
                defaultMessage="Save"
              />
            </AnvButton>
          </div>

          <div>
            <AnvButton
              disabled={isFormDisabled}
              fullWidth
              customColor={'muted'}
              onClick={handleClose}
            >
              <FormattedMessage
                id={'SUPPORT.COMPLAINT.CATEGORIES.FORM.ACTION.CANCEL'}
                defaultMessage="Cancel"
              />
            </AnvButton>
          </div>
        </AnvDialogActions>
      </AnvDialog>
    );
  },
);
