import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Hidden, Link, ListItemIcon, useTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import useStyles from './styles';
import { ROUTES } from '../../../constants';
import { AnvIcon } from '@platform/front-lib';
import { propsMixins } from '../../../styles/mixins';
import AnvHeaderLogo from '../../shared/icons/components/AnvHeaderLogo';

interface IAnvAppBar {
  // profile: IProfile | null;
  profile: any;
  isLoggedIn: boolean | undefined;
  toggleDrawerOpen: () => void;
  drawerOpen: boolean;
  logOut: () => void;
  customCenterTitle?: React.ReactNode | string | null;
}

const AnvAppBarSupport: React.FC<IAnvAppBar> = ({
  profile,
  isLoggedIn,
  toggleDrawerOpen,
  drawerOpen,
  logOut,
  customCenterTitle,
}) => {
  const history = useHistory();

  const theme = useTheme();
  const screenMD = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const [auth, setAuth] = React.useState<any>(false);
  const [
    anchorElProfile,
    setAnchorElProfile,
  ] = React.useState<null | HTMLElement>(null);

  const openProfile = !!anchorElProfile;
  const handleMenuProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  useEffect(() => {
    setAuth(isLoggedIn);
  }, [isLoggedIn]);

  const handleLogOut = () => {
    handleCloseProfile();
    logOut();
  };
  const handleProfileRoute = () => {
    handleCloseProfile();
    history.push(ROUTES.PROFILE_BASE);
  };

  const logoSize = screenMD
    ? { width: 186, height: 40 }
    : { width: 139, height: 29 };

  return (
    <AppBar position={'fixed'} className={classes.root}>
      <Grid
        container
        justify={'space-between'}
        alignItems={'center'}
        className={classes.rootGrid}
      >
        <Grid item style={{ display: 'flex' }}>
          <Hidden smDown>
            <IconButton onClick={toggleDrawerOpen} style={{ marginRight: 8 }}>
              <AnvIcon
                icon={'menu'}
                size={30}
                style={{ color: theme.palette.brand.main }}
              />
            </IconButton>
          </Hidden>

          <Link
            className={classes.logoLink}
            component={NavLink}
            to={ROUTES.DASHBOARD}
          >
            <AnvHeaderLogo {...logoSize} />
          </Link>
        </Grid>
        {customCenterTitle && customCenterTitle}
        <Grid container item spacing={1} zeroMinWidth style={{ width: 'auto' }}>
          <Grid item>
            {auth && (
              <Hidden smDown>
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenuProfile}
                    color="inherit"
                  >
                    <AnvIcon icon={'user'} size={24} />
                  </IconButton>

                  <span>{`${profile?.firstName} ${profile?.lastName}`}</span>
                  <Menu
                    className={classes.menuRoot}
                    id="menu-appbar"
                    anchorEl={anchorElProfile}
                    {...propsMixins.menu}
                    open={openProfile}
                    onClose={handleCloseProfile}
                  >
                    <MenuItem onClick={handleProfileRoute}>
                      <ListItemIcon>
                        <AnvIcon
                          icon={'avatar'}
                          size={24}
                          color={'lightIcons'}
                        />
                      </ListItemIcon>

                      <FormattedMessage
                        id="APP.PROFILE"
                        defaultMessage="Profile"
                      />
                    </MenuItem>
                    <MenuItem onClick={handleLogOut}>
                      <ListItemIcon>
                        <AnvIcon
                          icon={'logOut'}
                          size={24}
                          color={'lightIcons'}
                        />
                      </ListItemIcon>

                      <FormattedMessage
                        id="APP.LOG_OUT"
                        defaultMessage="Log Out"
                      />
                    </MenuItem>
                  </Menu>
                </div>
              </Hidden>
            )}
            <Hidden mdUp>
              <IconButton onClick={toggleDrawerOpen}>
                <AnvIcon
                  icon={drawerOpen ? 'closeCross' : 'menu'}
                  size={24}
                  style={{
                    color: drawerOpen
                      ? theme.palette.brand.dark
                      : theme.palette.brand.main,
                  }}
                />
              </IconButton>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default AnvAppBarSupport;
