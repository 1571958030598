import { IsLoadingComponent, ModalConfirm } from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useStores } from '../../stores/root-store';

interface IArchiveAccessRequestModal {
  open: boolean;
  handleClose: (refetch?: boolean) => void;

  accessRequestId: string;
}

export const ArchiveAccessRequestModal: React.FC<IArchiveAccessRequestModal> = observer(
  ({ open, handleClose, accessRequestId }) => {
    const { formatMessage } = useIntl();

    const {
      accessStore: { archiveAccessRequest, isFetching },
    } = useStores();

    const confirmAction = () => {
      archiveAccessRequest(
        { accessRequestId },
        {
          successFunc: () => {
            handleClose(true);
          },
        },
      );
    };

    return (
      <ModalConfirm
        handleClose={handleClose}
        isOpen={open}
        cancelAction={handleClose}
        confirmAction={confirmAction}
        disableActions={isFetching}
        text={
          <>
            <IsLoadingComponent isLoading={isFetching} />
            {formatMessage({
              id: 'SUPPORT.ACCESS_REQUESTS_ARCHIVE_MODAL.TEXT',
              defaultMessage:
                'Are you sure you want to archive this access request?',
            })}
          </>
        }
      />
    );
  },
);
