import { action, makeObservable, observable } from 'mobx';
import { CommonListAdvancedStore, Constants } from '@platform/front-lib';

import { EntitiesByAccessService } from './service';

const { DEFAULT_REQUEST_ID } = Constants;

export class EntitiesByAccessStore extends CommonListAdvancedStore<
  EntitiesByAccessService,
  any
> {
  service: EntitiesByAccessService;

  errorItem: null | Record<string, any> = null;

  totalHaveRights?: number;
  singleRightsFor?: string | null;

  postGetListHook = (response: any, requestId?: string) => {
    if (response.data) {
      const requestIdComputed = requestId || DEFAULT_REQUEST_ID;

      this.dataByRequestId[requestIdComputed] = response.data;

      this.totalMap[requestIdComputed] = response.total || 0;

      if (typeof response.totalHaveRights !== 'undefined') {
        this.totalHaveRights = response.totalHaveRights;
        this.singleRightsFor = response.singleRightsFor;
      }
    }
  };

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  // common
  setLoading = (func?: () => void) => {
    this.errorItem = null;
    this.isError = false;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();

    // [error, success]
    return [true, false];
  };
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();

    // [error, success]
    return [null, true];
  };

  constructor() {
    super({ disableAutoSave: true });
    this.service = new EntitiesByAccessService();

    makeObservable(this, {
      setLoading: action,
      setError: action,
      setLoaded: action,

      errorItem: observable,

      totalHaveRights: observable,
      singleRightsFor: observable,

      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      isError: observable,
    });
  }
}
