import React from 'react';
import clsx from 'clsx';
import { Typography, TypographyProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface IProps extends TypographyProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  className?: string;
  divider?: boolean;
}

export const TextH5: React.FC<IProps> = ({
  children,
  className,
  component,
  divider,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.textH5, {
        // @ts-ignore
        [className]: !!className,
        [classes.divider]: !!divider,
      })}
      component={component || 'h5'}
      {...other}
    >
      {children}
    </Typography>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    textH5: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '-0.02em',
    },
    divider: {
      borderBottom: `1px solid grey`,
    },
  }),
);
