import { AnvUtils, Constants } from '@platform/front-lib';
import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';

export const validationSchema = ({
  formatMessage,
  minExpirationDate,
  maxExpirationDate,
}: {
  formatMessage: any;
  minExpirationDate: any;
  maxExpirationDate: any;
}) => {
  return yup.object().shape({
    reasonForRequest: yup
      .string()
      .required(formatMessage(Constants.validationsMessages.required())),
    accessExpirationDate: yup
      .date()
      .min(
        minExpirationDate,
        formatMessage(
          ...(Constants.validationsMessages.dateMinVal(
            AnvUtils.formatWLocale(minExpirationDate),
          ) as [MessageDescriptor, any]),
        ),
      )
      .max(
        maxExpirationDate,
        formatMessage(
          ...(Constants.validationsMessages.dateMaxVal(
            AnvUtils.formatWLocale(maxExpirationDate),
          ) as [MessageDescriptor, any]),
        ),
      )
      .typeError(formatMessage(Constants.validationsMessages.time()))
      .required(),
  });
};
