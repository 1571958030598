import { axiosCatch } from '../utils';

import { envConst } from '../../constants';
import { axiosInstance } from '@platform/front-lib';
import {
  IGetCodeForPhoneParams,
  IUpdateProfileBaseParams,
} from '../../stores/profile-operator/base';

const axios = axiosInstance;

class ProfileBaseService {
  private authUrl = envConst.apiUrl + '/profile';

  async get() {
    return axiosCatch(axios.get(`${this.authUrl}`));
  }

  async sendCode(data?: IGetCodeForPhoneParams) {
    return axiosCatch(axios.post(`${this.authUrl}/phone-change-sms`, data));
  }

  async updateBase(data: IUpdateProfileBaseParams) {
    return axiosCatch(axios.patch(`${this.authUrl}`, data, {}));
  }
}

export default new ProfileBaseService();
