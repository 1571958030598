import React from 'react';

import { UiStore, MetaStores, FileStore } from '@platform/front-lib';
import {
  FormsDataStore,
  FormsTemplateStore,
  FormsComponentStore,
} from '@platform/front-lib-forms';
import { AccessStoreEnhanced } from './access/store';

// import FileStore from './file/file-store';

import { SupportStores } from './support';

import AuthStore from './common/auth';
import UsersStore from './users';
import InvitationsStore from './invitations';
import { ProfileOperatorStore } from './profile-operator';
import { supportTicketsStore } from './support-tickets';
import { complaintStore } from './complaint';
import { UsersAccessRequestStore } from './users-access-requests';

export const rootStore = {
  authStores: new AuthStore(),
  uiStores: UiStore,
  accessStore: new AccessStoreEnhanced(),

  formsStore: {
    FormsTemplateStore,
    FormsDataStore,
    FormsComponentStore,
  },
  metaStores: MetaStores,
  fileStore: new FileStore(),
  complaintStore: complaintStore,
  usersStore: UsersStore,
  usersAccessRequestStore: new UsersAccessRequestStore('users'),
  invitationStore: InvitationsStore,
  supportStore: SupportStores,
  profileOperatorStore: ProfileOperatorStore,
  supportTicketsStore,
};

export const StoresContext = React.createContext(rootStore);

export const useStores = () => React.useContext(StoresContext);
