import React from 'react';

import {
  IComplaintCategory,
  IComplaintCategorySetting,
  IFormatMessage,
} from '@platform/front-lib/dist/models';
import { Row } from 'react-table';
import { AnvUtils, Constants, ISelectOption } from '@platform/front-lib';
import { IComplaintReviewSeverity } from '@platform/front-lib/dist/models/complaint/complaint-review-severity';

export const prepareColumns = ({
  formatMessage,
  locale,

  jurisdictionOptions,
  entityTypes,
  entityTypesMap,
  severitiesMap,
  severitiesOptions,
}: {
  formatMessage: IFormatMessage;
  locale: string;

  jurisdictionOptions: ISelectOption[];
  entityTypes?: ISelectOption[];
  entityTypesMap?: Record<string, string | JSX.Element>;

  severitiesMap: Record<string, IComplaintReviewSeverity>;
  severitiesOptions: ISelectOption[];
}) => {
  const COLUMNS = {
    ENTITY: {
      id: 'school_organization',
      width: 'auto',
      Header: () =>
        formatMessage({
          id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.TH.ENTITY',
          defaultMessage: 'Entity',
        }),
      Cell: ({ row }: { row: Row<IComplaintCategorySetting> }) => {
        const educationalEntity =
          row.original?.schoolRef || row.original?.organizationRef;

        return (
          <div>
            {AnvUtils.getNameByLangNullable(educationalEntity, 'name', locale)}
          </div>
        );
      },
    },
    ENTITY_TYPE: {
      id: 'entityType',
      width: 'auto',
      filterable: true,
      filterOptions: entityTypes,
      filterType: Constants.FILTER_TYPES.select,
      // filterMinLength: 2,
      Header: () =>
        formatMessage({
          id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.TH.ENTITY_TYPE',
          defaultMessage: 'Entity Type',
        }),
      Cell: ({ row }: { row: Row<IComplaintCategorySetting> }) => {
        const entityType = row.original?.entityType;

        const entityTypesMapAA = entityType && entityTypesMap?.[entityType];

        if (entityTypesMapAA) {
          return entityTypesMapAA;
        }

        return <div>{entityType}</div>;
      },
    },
    JURISDICTION: {
      id: 'jurisdiction',
      accessor: 'jurisdiction',
      width: 'auto',
      filterable: true,
      filterType: Constants.FILTER_TYPES.select,
      filterOptions: jurisdictionOptions,
      Header: () =>
        formatMessage({
          id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.TH.JURISDICTION',
          defaultMessage: 'Jurisdiction',
        }),
      Cell: ({ row }: { row: Row<IComplaintCategory> }) => {
        const category = row.original;

        return (
          <div>
            {AnvUtils.getNameByLangNullable(
              category?.jurisdictionRef,
              'name',
              locale,
            )}
          </div>
        );
      },
    },
    SEVERITY: {
      id: 'severity',
      accessor: 'severity',
      width: 'auto',
      filterable: true,
      filterType: Constants.FILTER_TYPES.select,
      filterOptions: severitiesOptions,
      Header: () =>
        formatMessage({
          id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.TH.SEVERITY',
          defaultMessage: 'Severity',
        }),
      Cell: ({ row }: { row: Row<IComplaintCategorySetting> }) => {
        const severity = row.original.severity;

        return (
          <div>
            {AnvUtils.getNameByLangNullable(
              severitiesMap[severity],
              'name',
              locale,
            )}
          </div>
        );
      },
    },
  };

  return [
    // COLUMNS.ORG_NAME,
    COLUMNS.ENTITY,
    COLUMNS.ENTITY_TYPE,
    // COLUMNS.NAME,
    COLUMNS.JURISDICTION,
    COLUMNS.SEVERITY,
  ];
};
