import { ISelectOption } from '@platform/front-lib';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DATE_FORMATS, FILTER_TYPES } from '../../../../constants';
import { formatWLocale, getNameByLangNullable } from '../../../../utils';
import CellMilestones from '../cells/CellMilestones';
import CellScope from '../cells/CellScope';

export const prepareColumns = ({
  locale,
  anvilarToolsOptions,
  anvilarModulesOptions,
  accessScopesOptions,
  statusOptions,
}: {
  locale: string;
  anvilarToolsOptions: ISelectOption[];
  anvilarModulesOptions: ISelectOption[];
  accessScopesOptions: ISelectOption[];
  statusOptions: ISelectOption[];
}) => {
  return [
    {
      sortable: true,
      filterable: true,
      filterType: FILTER_TYPES.select,
      filterOptions: anvilarToolsOptions,
      Header: (
        <FormattedMessage id="COMMON.ACCESS.LABEL.TOOL" defaultMessage="Tool" />
      ),
      id: 'toolCode',
      accessor: 'toolCodeRef',
      Cell: ({ row }: Record<string, any>) => {
        return (
          getNameByLangNullable(row?.original?.toolCodeRef, 'name', locale) ||
          row?.original?.toolCode
        );
      },
    },
    {
      sortable: true,
      filterable: true,
      filterType: FILTER_TYPES.select,
      filterOptions: anvilarModulesOptions,
      Header: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.MODULE"
          defaultMessage="Module"
        />
      ),
      id: 'moduleCode',
      accessor: 'anvilarModule',
      Cell: ({ cell }: Record<string, any>) => {
        return (
          getNameByLangNullable(cell?.value, 'name', locale) ||
          cell?.value?.moduleCode ||
          null
        );
      },
    },

    {
      sortable: true,
      filterable: true,
      filterType: FILTER_TYPES.select,
      filterOptions: accessScopesOptions,
      Header: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.ACCESS_SCOPE"
          defaultMessage="Access Scope"
        />
      ),
      id: 'accessScope',
      accessor: 'accessScopeRef',
      Cell: ({ cell }: Record<string, any>) => {
        return getNameByLangNullable(cell?.value, 'description', locale);
      },
    },
    {
      sortable: true,
      filterable: true,
      filterMinLength: 2,
      Header: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.SCOPE"
          defaultMessage="Scope"
        />
      ),
      accessor: 'structureName',
      Cell: ({ row }: Record<string, any>) => {
        const entity = row?.original;

        return <CellScope entity={entity} />;
      },
    },

    {
      sortable: true,
      filterable: true,
      filterMinLength: 2,
      Header: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.REQUESTED_BY"
          defaultMessage="Requested By"
        />
      ),
      id: 'requestedBy',
      accessor: 'requestedByRef',
      Cell: ({ cell }: Record<string, any>) => {
        const user = cell?.value;
        if (!user) {
          return '---';
        }
        return `${user?.lastName} ${user?.firstName}`;
      },
    },

    {
      sortable: true,
      Header: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.MILESTONES"
          defaultMessage="Milestones"
        />
      ),
      accessor: 'createdAt',
      Cell: ({ row }: Record<string, any>) => {
        const entity = row?.original || {};

        return <CellMilestones entity={entity} />;
      },
    },
    {
      sortable: true,
      filterable: true,
      filterType: FILTER_TYPES.date,
      Header: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.EXPIRATION"
          defaultMessage="Expiration"
        />
      ),
      accessor: 'accessExpirationDate',
      Cell: ({ cell }: Record<string, any>) => {
        const isExpired = +new Date(cell?.value) < Date.now();
        return (
          <span color={isExpired ? 'error' : 'textSecondary'}>
            {formatWLocale(cell?.value, DATE_FORMATS.DEFAULT_FORMAT)}
          </span>
        );
      },
    },
    {
      sortable: true,
      filterable: true,
      filterType: FILTER_TYPES.select,
      filterOptions: statusOptions,
      Header: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.STATUS"
          defaultMessage="Status"
        />
      ),
      id: 'requestStatus',
      accessor: 'requestStatusRef',
      Cell: ({ cell }: Record<string, any>) => {
        return getNameByLangNullable(cell?.value, 'caption', locale);
      },
    },
  ];
};
