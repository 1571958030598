import Cookies from 'js-cookie';

import { extractRootDomain } from './http';
import {
  AUTH_PROFILE_KEY,
  AUTH_TOKEN_KEY,
  AUTH_TOKEN_REFRESH_KEY,
  envConst,
} from '../constants';
import LocalStorage from '../stores/_helpers/storage';

export function removeCookieTokenAndProfile({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';

  Cookies.remove('anvilar-refresh' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });
  Cookies.remove('anvilar-token' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });
  Cookies.remove('anvilar-profile' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });
}

export function getCookieToken({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';

  const token = Cookies.get('anvilar-token' + operatorPostfix);

  if (!token && process.env.NODE_ENV !== 'production') {
    return LocalStorage.get(AUTH_TOKEN_KEY);
  }

  return token;
}

export function getCookieTokenRefresh({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';
  const token = Cookies.get('anvilar-refresh' + operatorPostfix);

  if (!token && process.env.NODE_ENV !== 'production') {
    return LocalStorage.get(AUTH_TOKEN_REFRESH_KEY);
  }

  return token;
}

export function getCookieProfile({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';

  try {
    const profile = Cookies.get('anvilar-profile' + operatorPostfix);

    if (!profile && process.env.NODE_ENV !== 'production') {
      return LocalStorage.getJSON(AUTH_PROFILE_KEY + operatorPostfix);
    }

    if (!profile) {
      return null;
    }

    return JSON.parse(profile);
  } catch (err) {
    return null;
  }
}
