import React from 'react';
import { makeStyles, Paper, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  APP_BAR_HEIGHT,
  APP_BAR_MOBILE_HEIGHT,
  BORDER_RADIUS,
  BREADCRUMBS_HEIGHT,
  FOOTER_HEIGHT,
} from 'src/styles/constants';
import clsx from 'clsx';

interface IProps {
  title?: any;
  isToolHeader?: boolean;
  noPadding?: boolean;
  withBreadcrumbs?: boolean;
  toolCode?: string;
}
interface IStyleProps {
  props: Record<string, any> | undefined;
  theme: Theme;
}
const ContentWrapperRestyle: React.FC<IProps> = ({
  noPadding = false,
  withBreadcrumbs = false,
  children,
}) => {
  const stylesProps = {
    noPadding,
    withBreadcrumbs,
  };
  const theme = useTheme();

  const classes = useStyles({ theme, props: stylesProps });

  return (
    <Paper
      className={clsx(classes.wrapper, {
        [classes.wrapperNoPadding]: noPadding,
      })}
    >
      {children}
    </Paper>
  );
};

const useStyles = makeStyles({
  wrapperNoPadding: {
    '&&': {
      padding: 0,
    },
  },

  wrapper: ({ theme, props }: IStyleProps) => ({
    minHeight: `calc(100vh - ${
      APP_BAR_HEIGHT +
      FOOTER_HEIGHT +
      (props?.withBreadcrumbs ? BREADCRUMBS_HEIGHT + theme.spacing(2) : 0) +
      theme.spacing(4.5)
    }px)`,
    maxWidth: 986,
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.palette.background.paper,
    margin: '0 auto',
    // margin: theme.spacing(3, 3, 1.5, 3),
    // marginTop: props?.withBreadcrumbs ? theme.spacing(2) : theme.spacing(3),
    padding: props?.noPadding ? 0 : theme.spacing(3, 4),
    // overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 4, 2),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 1.5, 0),
      borderRadius: 0,
      minHeight: `calc(100vh - ${
        APP_BAR_MOBILE_HEIGHT + FOOTER_HEIGHT + theme.spacing(4.5)
      }px)`,
    },
  }),
  wrapperTool: ({ theme }: IStyleProps) => ({
    minHeight: `calc(100vh - ${
      APP_BAR_HEIGHT + FOOTER_HEIGHT + BREADCRUMBS_HEIGHT + theme.spacing(7.5)
    }px)`,
    [theme.breakpoints.down('xs')]: {
      minHeight: `calc(100vh - ${
        APP_BAR_HEIGHT + FOOTER_HEIGHT + BREADCRUMBS_HEIGHT + theme.spacing(4.5)
      }px)`,
    },
  }),
});
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     wrapperNoPadding: {
//       '&&': {
//         padding: 0,
//       },
//     },
//
//     wrapper: {
//       minHeight: `calc(100vh - ${
//         APP_BAR_HEIGHT +
//         FOOTER_HEIGHT +
//         (props?.withBreadcrumbs ? BREADCRUMBS_HEIGHT : 0) +
//         theme.spacing(4.5)
//       }px)`,
//       borderRadius: BORDER_RADIUS,
//       backgroundColor: theme.palette.background.paper,
//       margin: theme.spacing(3, 3, 1.5, 3),
//       padding: theme.spacing(4),
//       // overflow: 'hidden',
//
//       [theme.breakpoints.down('sm')]: {
//         padding: theme.spacing(2, 2, 4, 2),
//       },
//       [theme.breakpoints.down('xs')]: {
//         margin: theme.spacing(0, 0, 1.5, 0),
//         borderRadius: 0,
//         minHeight: `calc(100vh - ${
//           APP_BAR_MOBILE_HEIGHT + FOOTER_HEIGHT + theme.spacing(4.5)
//         }px)`,
//       },
//     },
//     wrapperTool: {
//       minHeight: `calc(100vh - ${
//         APP_BAR_HEIGHT + FOOTER_HEIGHT + BREADCRUMBS_HEIGHT + theme.spacing(7.5)
//       }px)`,
//       [theme.breakpoints.down('xs')]: {
//         minHeight: `calc(100vh - ${
//           APP_BAR_HEIGHT +
//           FOOTER_HEIGHT +
//           BREADCRUMBS_HEIGHT +
//           theme.spacing(4.5)
//         }px)`,
//       },
//     },
//   }),
// );

export default ContentWrapperRestyle;
