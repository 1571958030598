import { generatePath } from 'react-router-dom';

export const ROUTE_PREFIX = '';

const DASHBOARD = `${ROUTE_PREFIX}/dashboard`;
const PROFILE_ROOT = `${ROUTE_PREFIX}/profile`;
const PROFILE_PREFIX = `${ROUTE_PREFIX}/profile/:tab?`;
const PROFILE_BASE = `${ROUTE_PREFIX}/profile/base-info`;
const PROFILE_SECURITY = `${ROUTE_PREFIX}/profile/security`;

const USERS = `${ROUTE_PREFIX}/users`;
const SUPPORT = `${ROUTE_PREFIX}/support`;
const ACCESS_REQUEST = `${ROUTE_PREFIX}/access-requests/:tab?`;
const INVITATIONS = `${ROUTE_PREFIX}/invitations`;
const COMPLAINTS = `${ROUTE_PREFIX}/complaint`;
const DIRECTORY_CONNECT = `${ROUTE_PREFIX}/directory_connect`;

export const USER_DETAILS_TABS_PARAMS = {
  INFO: 'info',
  ADDRESS: 'address',
  LAUNCHPAD_SETTINGS: 'launchpad-settings',
  ACCESS_REQUESTS: 'access-requests',
} as const;

export type TKEYS_USER_DETAILS_TABS_PARAMS = keyof typeof USER_DETAILS_TABS_PARAMS;
export type TVALUES_USER_DETAILS_TABS_PARAMS = typeof USER_DETAILS_TABS_PARAMS[TKEYS_USER_DETAILS_TABS_PARAMS];

export const USER_DETAILS_TABS = (id: string) => ({
  [USER_DETAILS_TABS_PARAMS.INFO]: {
    fm: {
      id: 'SUPPORT.USER_DETAILS.TAB.PERSONAL_INFO',
      defaultMessage: 'Personal Information',
    },
    to: generatePath(ROUTES.USER_DETAILS, {
      id,
      tab: USER_DETAILS_TABS_PARAMS.INFO,
    }),
  },
  [USER_DETAILS_TABS_PARAMS.ADDRESS]: {
    fm: {
      id: 'SUPPORT.USER_DETAILS.TAB.ADDRESS',
      defaultMessage: 'Address',
    },
    to: generatePath(ROUTES.USER_DETAILS, {
      id,
      tab: USER_DETAILS_TABS_PARAMS.ADDRESS,
    }),
  },
  [USER_DETAILS_TABS_PARAMS.LAUNCHPAD_SETTINGS]: {
    fm: {
      id: 'SUPPORT.USER_DETAILS.TAB.LAUNCHPAD_SETTINGS',
      defaultMessage: 'Launchpad Settings',
    },
    to: generatePath(ROUTES.USER_DETAILS, {
      id,
      tab: USER_DETAILS_TABS_PARAMS.LAUNCHPAD_SETTINGS,
    }),
  },
  [USER_DETAILS_TABS_PARAMS.ACCESS_REQUESTS]: {
    fm: {
      id: 'SUPPORT.USER_DETAILS.TAB.ACCESS_REQUESTS',
      defaultMessage: 'Access Requests',
    },
    to: generatePath(ROUTES.USER_DETAILS, {
      id,
      tab: USER_DETAILS_TABS_PARAMS.ACCESS_REQUESTS,
    }),
  },
});

export const ROUTES = {
  USER_AUTH: ROUTE_PREFIX + '/auth',
  AUTH_FAIL: ROUTE_PREFIX + '/auth-fail',
  AUTH_FORBIDDEN: ROUTE_PREFIX + '/forbidden',

  ROOT: ROUTE_PREFIX,
  DASHBOARD: DASHBOARD,
  PROFILE_ROOT,
  PROFILE_PREFIX,
  PROFILE_BASE,
  PROFILE_SECURITY,
  SUPPORT: `${SUPPORT}/:entityId?/:tab?`,

  USERS,
  USER_DETAILS: `${USERS}/:id/:tab`,

  ACCESS_REQUEST,
  INVITATIONS,

  COMPLAINTS,
  COMPLAINT_CATEGORIES: `${COMPLAINTS}/categories`,
  COMPLAINT_CATEGORIES_SETTINGS: `${COMPLAINTS}/categories/:id/settings`,

  DIRECTORY_CONNECT,
  AUTH_LOGOUT: ROUTE_PREFIX + '/logout',
  AUTH_SIGN_IN: ROUTE_PREFIX + '/sign-in',
};
