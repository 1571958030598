import React from 'react';

import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';

import { TextH5 } from '@platform/front-lib';
import { getNameByLangNullable } from '../../../utils';
import { useStylesCardTitle } from './card-title.styles';

interface IProps {
  [key: string]: any;
}

export const AnvCardTitle: React.FC<IProps> = ({ item, toolsMap }) => {
  const classes = useStylesCardTitle();

  const { locale, formatMessage } = useIntl();

  const getLabel = ({ item, toolsMap }: any) => {
    if (!item || !toolsMap) {
      return '';
    }

    if (item?.tool && toolsMap[item?.tool]) {
      return getNameByLangNullable(toolsMap[item?.tool], 'name', locale) || '';
    }

    return formatMessage({ id: item.nameI18n, defaultMessage: item.title });
  };

  const label = getLabel({ item, toolsMap });

  return (
    <Grid
      container
      direction={'column'}
      wrap={'nowrap'}
      className={classes.wrapper}
    >
      <TextH5>{label}</TextH5>
    </Grid>
  );
};
