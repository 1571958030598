import React from 'react';
import clsx from 'clsx';
import { Typography, TypographyProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface IProps extends TypographyProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  className?: string;
}

export const TextH1: React.FC<IProps> = ({
  children,
  className,
  component,
  align = 'center',
  ...other
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.textH1, {
        // @ts-ignore
        [className]: !!className,
      })}
      component={component || 'h1'}
      {...other}
    >
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    textH1: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 36,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '37px',

      [theme.breakpoints.up('md')]: {
        fontSize: 40,
        lineHeight: '48px',
      },

      // fontSize: 36,
      // lineHeight: "46px",
      // fontWeight: 800,
      // color: theme.palette.text.primary,
      // letterSpacing: "-0.02em",
    },
  }),
);
