import React from 'react';

import {
  IComplaintCategory,
  IFormatMessage,
} from '@platform/front-lib/dist/models';
import { Row } from 'react-table';
import { AnvUtils, Constants, ISelectOption } from '@platform/front-lib';

export const prepareColumns = ({
  formatMessage,
  locale,
  customizationRecordsMap,
  jurisdictionOptions,
}: {
  formatMessage: IFormatMessage;
  locale: string;
  customizationRecordsMap: Record<string, number>;
  jurisdictionOptions: ISelectOption[];
}) => {
  const COLUMNS = {
    DESCRIPTION: {
      id: 'description',
      accessor: 'description',
      width: 'auto',
      // filterable: true,
      // filterMinLength: 2,
      Header: () =>
        formatMessage({
          id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.TH.DESCRIPTION',
          defaultMessage: 'Description',
        }),
      headerString: formatMessage({
        id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.TH.DESCRIPTION',
        defaultMessage: 'Description',
      }),
      Cell: ({ row }: { row: Row<IComplaintCategory> }) => {
        const category = row.original;

        return (
          <div>
            {AnvUtils.getNameByLangNullable(category, 'description', locale)}
          </div>
        );
      },
    },
    JURISDICTION: {
      id: 'jurisdiction',
      accessor: 'jurisdiction',
      width: 'auto',

      filterable: true,
      filterType: Constants.FILTER_TYPES.select,
      filterOptions: jurisdictionOptions,
      // filterMinLength: 2,
      Header: () =>
        formatMessage({
          id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.TH.JURISDICTION',
          defaultMessage: 'Jurisdiction',
        }),
      headerString: formatMessage({
        id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.TH.JURISDICTION',
        defaultMessage: 'Jurisdiction',
      }),
      Cell: ({ row }: { row: Row<IComplaintCategory> }) => {
        const category = row.original;

        return (
          <div>
            {AnvUtils.getNameByLangNullable(
              category?.jurisdictionRef,
              'name',
              locale,
            )}
          </div>
        );
      },
    },
    CUSTOMIZATION_RECORDS: {
      id: 'customizationRecords',
      width: 'auto',
      Header: () =>
        formatMessage({
          id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.TH.CUSTOMIZATION_RECORDS',
          defaultMessage: 'Customization Records',
        }),
      headerString: formatMessage({
        id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.TH.CUSTOMIZATION_RECORDS',
        defaultMessage: 'Customization Records',
      }),
      Cell: ({ row }: { row: Row<IComplaintCategory> }) => {
        const category = row.original;

        return (
          <div>
            {(category._id && customizationRecordsMap[category._id]) || 0}
          </div>
        );
      },
    },
  };

  return [
    // COLUMNS.ORG_NAME,
    COLUMNS.DESCRIPTION,
    COLUMNS.JURISDICTION,
    COLUMNS.CUSTOMIZATION_RECORDS,
  ];
};
