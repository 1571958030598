import { MenuItem } from '@material-ui/core';
import {
  AnvIcon,
  AnvMenu,
  IListActionsComponent,
  useMenu,
} from '@platform/front-lib';
import { IModuleAccessRequest } from '@platform/front-lib/dist/models';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { REQUEST_ACCESS_TYPES } from '../../../../constants';

interface IProps extends IListActionsComponent {
  data: IModuleAccessRequest & { _id: string };
  handleSelectRequest: (id: string) => void;
  handleOpenRenewDialog: (id: string) => void;
  handleOpenArchiveDialog: (id: string) => void;
  checkIsRenewAvailable?: (date: Date) => boolean;
}

const RENEW_ALLOWED_STATUSES = [
  REQUEST_ACCESS_TYPES.APPROVED,
  REQUEST_ACCESS_TYPES.EXPIRED,
];

const ARCHIVE_ALLOWED_STATUSES = [REQUEST_ACCESS_TYPES.EXPIRED];

export const Actions: React.FC<IProps> = observer(
  ({
    data,
    handleSelectRequest,
    handleOpenRenewDialog,
    handleOpenArchiveDialog,
    checkIsRenewAvailable,
  }) => {
    const { handleClose: handleCloseMenu, closeMenu } = useMenu();
    const { formatMessage } = useIntl();

    if (!data) {
      return null;
    }

    const isRenewAvailable =
      data?.accessExpirationDate && checkIsRenewAvailable
        ? checkIsRenewAvailable(new Date(data?.accessExpirationDate)) &&
          RENEW_ALLOWED_STATUSES.includes(data?.requestStatus)
        : false;

    const isArchiveAvailable =
      ARCHIVE_ALLOWED_STATUSES.includes(data?.requestStatus) && !data.archived;

    return (
      <AnvMenu closeMenu={closeMenu}>
        <MenuItem
          color={'primary'}
          className="btn-icon button-actions"
          title={formatMessage({
            id: 'COMMON.ACCESS.ACTION.VIEW',
            defaultMessage: 'View Details',
          })}
          onClick={() => {
            handleSelectRequest(data._id);

            handleCloseMenu();
          }}
        >
          <AnvIcon icon={'eyeOutline'} color={'inherit'} />
          <span>
            <FormattedMessage
              id="COMMON.ACCESS.ACTION.VIEW"
              defaultMessage="View Details"
            />
          </span>
        </MenuItem>{' '}
        {isRenewAvailable && (
          <MenuItem
            color={'primary'}
            className="btn-icon button-actions"
            title={formatMessage({
              id: 'SUPPORT.ACCESS_REQUESTS.LIST.ACTION.RENEW',
              defaultMessage: 'Renew Access Request',
            })}
            onClick={() => {
              handleOpenRenewDialog(data._id);

              handleCloseMenu();
            }}
          >
            <AnvIcon icon={'plus'} color={'inherit'} />
            <span>
              <FormattedMessage
                id="SUPPORT.ACCESS_REQUESTS.LIST.ACTION.RENEW"
                defaultMessage="Renew Access Request"
              />
            </span>
          </MenuItem>
        )}
        {isArchiveAvailable && (
          <MenuItem
            color={'primary'}
            className="btn-icon button-actions"
            title={formatMessage({
              id: 'SUPPORT.ACCESS_REQUESTS.LIST.ACTION.ARCHIVE',
              defaultMessage: 'Archive',
            })}
            onClick={() => {
              handleOpenArchiveDialog(data._id);

              handleCloseMenu();
            }}
          >
            <AnvIcon icon={'archive'} color={'inherit'} />
            <span>
              <FormattedMessage id="SUPPORT.ACCESS_REQUESTS.LIST.ACTION.ARCHIVE" defaultMessage="Archive" />
            </span>
          </MenuItem>
        )}
      </AnvMenu>
    );
  },
);
