import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DATE_FORMATS } from '../../../../constants';

import { formatWLocale } from '../../../../utils';

interface IProps {
  entity: Record<string, any>;
  allowExpirationDate?: boolean;
}

const CellMilestones: React.FC<IProps> = ({
  entity,
  allowExpirationDate = false,
}) => {
  const {
    createdAt,
    finalReviewDate,
    initialReviewDate,
    accessExpirationDate,
  } = entity;

  return (
    <div>
      {createdAt && (
        <div className="ws-nw">
          <span className="text-muted">
            <FormattedMessage
              id="APP.COMMON.LABEL.CREATED"
              defaultMessage="Created"
            />
            :{' '}
          </span>
          <span className="ml-2 mr-3">
            {formatWLocale(createdAt, DATE_FORMATS.DEFAULT_FORMAT)}
          </span>
        </div>
      )}
      {initialReviewDate && (
        <div className="ws-nw">
          <span className="text-muted">
            <FormattedMessage
              id="COMMON.ACCESS.LABEL.INITIAL_REVIEW"
              defaultMessage="Initial Review"
            />
            :{' '}
          </span>
          <span className="ml-2 mr-3">
            {formatWLocale(initialReviewDate, DATE_FORMATS.DEFAULT_FORMAT)}
          </span>
        </div>
      )}
      {finalReviewDate && (
        <div className="ws-nw">
          <span className="text-muted">
            <FormattedMessage
              id="COMMON.ACCESS.LABEL.FINAL_REVIEW"
              defaultMessage="Final Review"
            />
            :{' '}
          </span>
          <span className="ml-2 mr-3">
            {formatWLocale(finalReviewDate, DATE_FORMATS.DEFAULT_FORMAT)}
          </span>
        </div>
      )}{' '}
      {allowExpirationDate && accessExpirationDate && (
        <div className="ws-nw">
          <span className="text-muted">
            <FormattedMessage id="COMMON.ACCESS.LABEL.EXPIRATION_DATE" defaultMessage="Expiration Date" />:{' '}
          </span>

          <span className="ml-2 mr-3">
            {formatWLocale(accessExpirationDate, DATE_FORMATS.DEFAULT_FORMAT)}
          </span>
        </div>
      )}
    </div>
  );
};

export default CellMilestones;
