import React from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from './styles';

interface IAnvListContainerProps {}

export const AnvListContainer: React.FC<IAnvListContainerProps> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      direction={'column'}
      className={classes.content}
    >
      {children}
    </Grid>
  );
};
