import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrapper: {
      display: 'inline-block',
      verticalAlign: 'text-top',
    },

    withBackground: {
      '&::before': {
        content: "''",
        position: 'absolute',
        // z-index: -1,
        bottom: 4,
        left: 4,
        width: 15,
        height: 13,
        background: theme.palette.primary.light,
        borderRadius: 2,
        transform: 'rotate(-9.74deg)',
      },
    },

    inherit: {
      color: 'inherit',
    },
    white: {
      color: theme.palette.common.white,
    },
    lightIcons: {
      color: '#9DADAF',
    },
    black: {
      color: theme.palette.common.black,
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    info: {
      color: theme.palette.info.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    textPrimary: {
      color: theme.palette.text.primary,
    },
    textSecondary: {
      color: theme.palette.text.secondary,
    },
  }),
);
