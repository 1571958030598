import { IAclRequestStatus } from '@platform/front-lib/dist/models';
import { TFormatMessage } from '../@types/intl/intl';

export const REQUEST_ACCESS_TYPES = {
  SUBMITTED: 'SUBMITTED',
  REVIEW: 'REVIEW',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  REVOKED: 'REVOKED',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
};

export const EXPIRED_SOON_STATUS = (fm: TFormatMessage): IAclRequestStatus => ({
  _id: 'EXPIRED_SOON',
  code: 'EXPIRED_SOON',
  caption: {
    en: fm({
      id: 'SUPPORT.ACCESS_REQUESTS.REQUEST_STATUS.NAME.EXPIRED_SOON',
      defaultMessage: 'Expired soon',
    }),
  },
});
