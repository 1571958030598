import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Cookies from 'js-cookie';
import { extractRootDomain } from '../utils';
import { envConst } from '../constants';

axios.defaults.withCredentials = true;

const instanceOperator = axios.create({});
const instanceRefresh = axios.create({});

const refreshAuthLogic = (failedRequest: any) =>
  instanceRefresh
    .post(
      `${process.env.REACT_APP_API_TOOL_ENDPOINT}/auth/refresh-token-operator`,
    )
    .then((tokenRefreshResponse) => {
      const { access_token: accessToken, refresh_token: refreshTokenString } =
        tokenRefreshResponse.data || {};

      if (accessToken && refreshTokenString) {
        Cookies.set('anvilar-token-operator', accessToken, {
          domain: extractRootDomain(envConst.launchpadUrl),
        });

        Cookies.set('anvilar-refresh-operator', refreshTokenString, {
          domain: extractRootDomain(envConst.launchpadUrl),
        });
      }

      return Promise.resolve();
    })
    .catch((err) => {
      console.log('refreshAuthLogic--err--', { err });
    });

createAuthRefreshInterceptor(instanceOperator, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
  statusCodes: [401],
});

export const axiosInstanceOperator = instanceOperator;
