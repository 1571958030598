import React from 'react';
import {
  AnvButton,
  AnvDialog,
  AnvDialogActions,
  AnvDialogBody,
  AnvDialogTitle,
  AnvListContainer,
  AnvListData,
} from '@platform/front-lib';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  formatPhoneNumber,
  formatWLocale,
  getNameByLangNullable,
} from '../../utils';
import { DATE_FORMATS } from '../../constants';

interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  entity: Record<string, any>;
}

const LABEL_MD = 4;

const DetailInformationModal: React.FC<IProps> = ({
  isOpen,
  handleCloseModal,
  entity,
}) => {
  const { locale, formatMessage } = useIntl();

  return (
    <AnvDialog handleClose={handleCloseModal} open={isOpen}>
      <AnvDialogTitle>
        <FormattedMessage
          id="SUPPORT.INVITATIONS.DETAILS.LABEL.INVITATION_DETAILS"
          defaultMessage="Invitation details"
        />
      </AnvDialogTitle>

      <AnvDialogBody>
        <AnvListContainer>
          {/* Invitation for */}
          <AnvListData
            omitColon
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <span style={{ fontWeight: 600 }}>
                <FormattedMessage
                  id="SUPPORT.INVITATIONS.DETAILS.LABEL.INVITATION_FOR"
                  defaultMessage="Invitation for"
                />
              </span>
            }
            value={''}
          />

          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.NAME"
                defaultMessage="Name"
              />
            }
            value={`${entity.firstName} ${
              (entity.middleName && entity.middleName) || ''
            } ${entity.lastName}`}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.GENDER"
                defaultMessage="Gender"
              />
            }
            value={
              getNameByLangNullable(entity.genderRef, 'description', locale) ||
              ''
            }
          />

          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.EMAIL"
                defaultMessage="Email"
              />
            }
            value={entity.email}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.PHONE"
                defaultMessage="Phone"
              />
            }
            value={formatPhoneNumber(entity.phone)}
          />
          {/* Invitation for */}

          {/* Invitation by */}
          <AnvListData
            omitColon
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <span style={{ fontWeight: 600 }}>
                <FormattedMessage
                  id="SUPPORT.INVITATIONS.LABEL.INVITED_BY"
                  defaultMessage="Invited by"
                />
              </span>
            }
            value={''}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.NAME"
                defaultMessage="Name"
              />
            }
            value={`${entity.invitedByRef.firstName} ${
              (entity.invitedByRef.middleName &&
                entity.invitedByRef.middleName) ||
              ''
            } ${entity.invitedByRef.lastName}`}
          />
          {/* Invitation by */}
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.REASON"
                defaultMessage="Reason"
              />
            }
            value={
              getNameByLangNullable(
                entity.invitationReasonRef,
                'content',
                locale,
              ) || ''
            }
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.TOOL"
                defaultMessage="Tool"
              />
            }
            value={
              getNameByLangNullable(entity.toolCodeRef, 'name', locale) || ''
            }
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.MODULE"
                defaultMessage="Module"
              />
            }
            value={
              getNameByLangNullable(entity.anvilarModuleRef, 'name', locale) ||
              ''
            }
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.EXPIRED"
                defaultMessage="Expired"
              />
            }
            value={
              entity?.expired
                ? formatMessage({ id: 'APP.YES', defaultMessage: 'Yes' })
                : formatMessage({ id: 'APP.NO', defaultMessage: 'No' })
            }
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.BAD_EMAIL"
                defaultMessage="Bad email"
              />
            }
            value={
              entity?.badEmail
                ? formatMessage({ id: 'APP.YES', defaultMessage: 'Yes' })
                : formatMessage({ id: 'APP.NO', defaultMessage: 'No' })
            }
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.DECLINED"
                defaultMessage="Declined"
              />
            }
            value={
              entity?.declined
                ? formatMessage({ id: 'APP.YES', defaultMessage: 'Yes' })
                : formatMessage({ id: 'APP.NO', defaultMessage: 'No' })
            }
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.EXTENDED"
                defaultMessage="Extended"
              />
            }
            value={
              entity?.extended
                ? formatMessage({ id: 'APP.YES', defaultMessage: 'Yes' })
                : formatMessage({ id: 'APP.NO', defaultMessage: 'No' })
            }
          />
          {/* Milestones */}
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.MILESTONES"
                defaultMessage="Milestones"
              />
            }
            value={''}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.CREATED_AT"
                defaultMessage="Created at"
              />
            }
            value={formatWLocale(
              entity?.createdAt,
              DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
            )}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.RESPONDED_AT"
                defaultMessage="Responded at"
              />
            }
            value={formatWLocale(
              entity?.respondedAt,
              DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
            )}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.UPDATED_AT"
                defaultMessage="Updated at"
              />
            }
            value={formatWLocale(
              entity?.updatedAt,
              DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
            )}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="SUPPORT.INVITATIONS.LABEL.EXPIRED_AT"
                defaultMessage="Expired at"
              />
            }
            value={formatWLocale(
              entity?.expiredAt,
              DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
            )}
          />
          {/* Milestones */}
        </AnvListContainer>
      </AnvDialogBody>
      <AnvDialogActions>
        <AnvButton
          fullWidth
          variant={'text'}
          customColor={'muted'}
          onClick={() => {
            handleCloseModal();
          }}
        >
          <FormattedMessage id="APP.CLOSE" defaultMessage="Close" />
        </AnvButton>
      </AnvDialogActions>
    </AnvDialog>
  );
};

export default DetailInformationModal;
