import { Grid } from '@material-ui/core';
import React from 'react';
import { useStylesContent } from './styles';
import { Skeleton } from '@material-ui/lab';
import { randomIntFromInterval } from '../../../utils';

interface IProps {
  content?: any[];
}

export const AnvCardContentSkeleton: React.FC<IProps> = ({
  content = [null, null, null, null],
}) => {
  const classes = useStylesContent();

  return (
    <div className={classes.contentWrapper}>
      {content?.map?.((item: any, index) => {
        return (
          <Grid
            container
            item
            direction={'row'}
            xs={12}
            className={classes.contentItemWrapper}
            key={index}
          >
            <Grid item xs={9} className={classes.label}>
              <Skeleton
                variant="rect"
                width={randomIntFromInterval(70, 150)}
                height={16}
              />
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <Skeleton
                variant="rect"
                width={randomIntFromInterval(10, 20)}
                height={16}
                style={{
                  marginLeft: 'auto',
                }}
              />
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};
