import { action, makeObservable, observable } from 'mobx';
import { CommonListAdvancedStore, Constants } from '@platform/front-lib';
import type { IFormsTemplate } from '@platform/front-lib/dist/models';
import type { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';

import {
  FormsEntityService,
  ICreateOneFormTemplatePayload,
  IDeleteOne,
  IGetOneFormTemplate,
  IUpdateOneFormTempalatePayload,
} from './service';

const { DEFAULT_REQUEST_ID } = Constants;

export class FormsEntityTemplatesStore extends CommonListAdvancedStore<
  FormsEntityService,
  IFormsTemplate & { _id: string }
> {
  service: FormsEntityService;

  errorItem: null | Record<string, any> = null;
  dataDetailedMap: Record<string, IFormsTemplate> = {};

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  getEntities = (
    payload: Record<string, any>,
    meta?: IActionMeta | undefined,
  ) => {
    return this.getList(payload, meta);
  };

  getOne = async (payload: IGetOneFormTemplate, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.getOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    return this.setLoaded(() => {
      if (response?._id) {
        this.dataMap[response._id] = response;
        this.dataDetailedMap[response._id] = response;
      }

      meta?.successFunc?.(response);
    });
  };

  createOne = async (
    payload: ICreateOneFormTemplatePayload,
    meta: IActionMeta,
  ) => {
    const requestId = meta?.requestId || DEFAULT_REQUEST_ID;

    this.setLoading();

    const [error, response] = await this.service.createOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    return this.setLoaded(() => {
      const data = this.dataByRequestId[requestId];

      this.dataMap[response._id] = response;

      data.push(response);

      this.dataByRequestId[requestId] = data.slice();

      meta?.successFunc?.(response);
    });
  };

  addItem = (entity: IFormsTemplate & { _id: string }) => {
    this.dataMap[entity._id] = entity;

    this.data.push(entity);
  };

  updateItem = (entity: IFormsTemplate & { _id: string }) => {
    this.dataMap[entity._id] = entity;
  };

  updateOne = async (
    payload: IUpdateOneFormTempalatePayload,
    meta: IActionMeta,
  ) => {
    const requestId = meta?.requestId || DEFAULT_REQUEST_ID;

    this.setLoading();

    const [error, response] = await this.service.updateOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    return this.setLoaded(() => {
      const data = this.dataByRequestId[requestId];
      this.dataMap[response._id] = response;
      this.dataByRequestId[requestId] = data.map((item) => {
        if (item._id === response._id) {
          return response;
        }
        return item;
      });

      meta?.successFunc?.(response);
    });
  };

  deleteOne = async (payload: IDeleteOne, meta: IActionMeta) => {
    // const requestId = meta?.requestId || DEFAULT_REQUEST_ID;

    this.setLoading();

    const [error, response] = await this.service.deleteOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    return this.setLoaded(() => {
      meta?.successFunc?.(response);
    });
  };

  // common
  setLoading = (func?: () => void) => {
    this.errorItem = null;
    this.isError = false;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();

    // [error, success]
    return [true, false];
  };
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();

    // [error, success]
    return [null, true];
  };

  constructor({ tool }: { tool: string }) {
    super({ sessionKey: tool + 'wt-forms', disableAutoSave: true });
    this.service = new FormsEntityService(tool);

    makeObservable(this, {
      getEntities: action, // alias for getList
      addItem: action,
      createOne: action,
      updateOne: action,
      getOne: action,
      setLoading: action,
      setError: action,
      setLoaded: action,
      // createNodeRubricFromRating: action,

      dataDetailedMap: observable,
      errorItem: observable,

      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      isError: observable,
    });
  }
}
