import { IAnvilarModule } from '@platform/front-lib/dist/models';
import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  AnvButton,
  AnvDatepicker,
  AnvDialog,
  AnvSelect,
  AnvTextField,
  AnvUtils,
  Constants,
  IsLoadingComponent,
  ScopeForm,
  useAccessRequestDates,
  usePrevious,
  usePromptModal,
} from '@platform/front-lib';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStores } from '../../../../stores/root-store';
import { IRequestAccessModule, validationSchema } from './validation';
import { Grid } from '@material-ui/core';
import { ConfirmModal } from './confirm-dialog';

interface IProps {
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

const getDefaultValues = (initialFocusedDate: Date) => ({
  userEmail: '',
  tool: '',
  module: '',
  accessScope: '',
  reasonForRequest: '',
  accessExpirationDate: initialFocusedDate,
  scope: {
    jurisdiction: '',
    organization: '',
    organizationSecondary: '',
    tags: [],
    school: '',
    schoolDepartment: '',
  },
});

const FORCE_APPLY_JURISDICTION_FILTER = ['ESC', 'SEA', 'EMO'] as (
  | 'ESC'
  | 'SEA'
  | 'EMO'
)[];

export const CreateAccessRequestModal: React.FC<IProps> = observer(
  ({ open, handleClose, onSuccess }) => {
    const { formatMessage, locale } = useIntl();

    const {
      accessStore: {
        isFetchingVerifyData,
        verifyData,
        errorVerifyData,
        resetErrorsVerifyData,
        resetVerifyData,
      },
      metaStores,
      profileOperatorStore: {
        profileBaseStore: {
          profileData,
          get: getProfile,
          isLoaded: isLoadedProfile,
          isFetching: isFetchingProfileInfo,
        },
      },
    } = useStores();
    const {
      accessScopeStore,
      toolsStore: {
        getTools,
        isLoaded: isLoadedTools,
        data: tools,
        dataMapModules,
      },
      jurisdictionStore: {
        getList: getJurisdictions,
        getListData: getJurisdictionsListData,
      },
      schoolDepartmentsStore: {
        getList: getSchoolDepartmentsList,
        getListData: getSchoolDepartmentsListListData,
      },

      generalSettingStore: {
        generalSettings,
        getGeneralSettings,
        isLoaded: isLoadedGeneralSettings,
        isFetching: isFetchingGeneralSettings,
      },
    } = metaStores;

    const {
      data: accessScopes,
      getAccessScopes,
      isLoaded: isLoadedAccessScopes,
    } = accessScopeStore;

    const {
      isLoadedList: isLoadedJurisdictions,
      dataList: jurisdictions,
    } = getJurisdictionsListData();

    const [serverErrors, setServerErrors] = React.useState({});

    const [accessScopeState, setAccessScopeState] = React.useState('');
    const [dataVerified, setDataVerified] = React.useState(false);

    const [
      validateDepartmentField,
      setValidateDepartmentField,
    ] = React.useState(false);

    const {
      maxExpirationDate,
      minExpirationDate,
      initialFocusedDate,
    } = useAccessRequestDates(generalSettings);

    const methods = useForm<IRequestAccessModule>({
      defaultValues: getDefaultValues(initialFocusedDate),
      shouldUnregister: false,
      resolver: yupResolver(
        validationSchema(formatMessage, {
          accessScope: accessScopeState,
          validateDepartmentField,
          maxExpirationDate,
          minExpirationDate,
        }),
      ),
    });

    const {
      errors,
      handleSubmit,
      control,
      setValue,
      watch,
      reset,
      getValues,
      formState: { isDirty: isDirtyForm },
    } = methods;

    const { component, handleOpenModalConfirm } = usePromptModal({
      isDirty: isDirtyForm,
      messageText: formatMessage({
        id: 'COMMON.ACCESS.FORM.WARNING_MODAL.TEXT',
        defaultMessage: 'Are you sure you want to close the modal?',
      }),
      actionModalConfirm: () => {
        handleClose();
      },
      isRouterPrompt: false,
    });

    const toolWatch = watch('tool');
    const moduleWatch = watch('module');
    const accessScopeWatch = watch('accessScope');
    const scopeSchoolWatch = watch('scope.school');

    const toolPrev = usePrevious(toolWatch);
    const modulePrev = usePrevious(moduleWatch);
    const accessScopePrev = usePrevious(accessScopeWatch);
    const scopeSchoolPrev = usePrevious(scopeSchoolWatch);

    React.useEffect(() => {
      setServerErrors(
        errorVerifyData &&
          typeof errorVerifyData !== 'string' &&
          errorVerifyData?.data,
      );
    }, [errorVerifyData]);

    React.useEffect(() => {
      if (toolWatch !== toolPrev) {
        const {
          userEmail,
          tool,
          reasonForRequest,
          accessExpirationDate,
        } = getValues();

        reset({
          ...getDefaultValues(initialFocusedDate),
          userEmail,
          tool,
          reasonForRequest,
          accessExpirationDate,
        });
      }
    }, [getValues, initialFocusedDate, reset, toolPrev, toolWatch]);

    React.useEffect(() => {
      if (moduleWatch !== modulePrev && !(toolWatch !== toolPrev)) {
        const {
          userEmail,
          tool,
          reasonForRequest,
          module,
          accessExpirationDate,
        } = getValues();

        reset({
          ...getDefaultValues(initialFocusedDate),
          userEmail,
          tool,
          module,
          reasonForRequest,
          accessExpirationDate,
        });
      }
    }, [
      getValues,
      initialFocusedDate,
      modulePrev,
      moduleWatch,
      reset,
      toolPrev,
      toolWatch,
    ]);

    React.useEffect(() => {
      if (accessScopeWatch !== accessScopePrev) {
        setServerErrors({});
        resetErrorsVerifyData();
      }
    }, [accessScopeWatch, resetErrorsVerifyData, accessScopePrev]);

    React.useEffect(() => {
      setAccessScopeState(accessScopeWatch);

      setValue(
        'scope.jurisdiction',
        profileData?.operatorJurisdictions?.[0] || '',
      );
      setValue('scope.organization', '');
      setValue('scope.school', '');

      // eslint-disable-next-line
    }, [accessScopeWatch]);

    React.useEffect(() => {
      if (!isLoadedTools) {
        getTools({
          filter: {
            disabled: false,
          },
        });
      }
    }, [isLoadedTools, getTools]);

    React.useEffect(() => {
      if (!isLoadedJurisdictions) {
        getJurisdictions({});
      }
    }, [isLoadedJurisdictions, getJurisdictions]);

    React.useEffect(() => {
      if (!isLoadedAccessScopes) {
        getAccessScopes({});
      }
    }, [isLoadedAccessScopes, getAccessScopes]);

    React.useEffect(() => {
      if (!isLoadedGeneralSettings) {
        getGeneralSettings({});
      }
    }, [isLoadedGeneralSettings, getGeneralSettings]);

    React.useEffect(() => {
      if (!isLoadedProfile) {
        getProfile();
      }
    }, [isLoadedProfile, getProfile]);

    const dataMapModulesByTool = React.useMemo(
      () => {
        return Object.keys(dataMapModules).reduce<Record<string, any>>(
          (acc, key) => {
            const module = dataMapModules[key];
            const toolCode = module.toolCode;
            if (!!toolCode) {
              if (!acc[toolCode]) {
                acc[toolCode] = [];
              }
              acc[toolCode].push(module);

              // sort by position
              acc[toolCode].sort((a: IAnvilarModule, b: IAnvilarModule) => {
                const positionA = a?.position || 0;
                const positionB = b?.position || 1;
                return positionA - positionB;
              });
            }

            return acc;
          },
          {},
        );
      },
      // disabled Object.keys(dataMapModules).length
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [Object.keys(dataMapModules).length],
    );

    const toolModules = (toolWatch && dataMapModulesByTool?.[toolWatch]) || [];
    const anvModule = (moduleWatch && dataMapModules?.[moduleWatch]) || null;

    const computedDisabledScopes = React.useMemo(() => {
      return anvModule?.allowedAccessScopes?.length
        ? accessScopes
            ?.filter?.((item) => {
              return anvModule.allowedAccessScopes?.indexOf(item._id) === -1;
            })
            ?.map((item) => item._id) || []
        : undefined;
    }, [accessScopes, anvModule?.allowedAccessScopes]);

    const isAllowedAllJurisdictions = !profileData?.operatorJurisdictions
      ?.length;

    const computedJurisdictions = React.useMemo(() => {
      return jurisdictions.filter((jurisdiction) => {
        const operatorJurisdiction = profileData?.operatorJurisdictions || [];

        if (operatorJurisdiction.length) {
          return operatorJurisdiction.indexOf(jurisdiction._id) !== -1;
        }

        return true;
      });
    }, [jurisdictions, profileData?.operatorJurisdictions]);

    const onSubmit = (data: IRequestAccessModule) => {
      if (!dataVerified) {
        verifyData(data, {
          successFunc: () => {
            setDataVerified(true);
          },
        });
        return;
      }
    };

    const handleCloseConfirmModal = () => {
      setDataVerified(false);
      setServerErrors({});
      resetVerifyData();
    };

    const schoolDepartment =
      !!toolWatch &&
      toolWatch === Constants.TOOLS.COMPLIANCE &&
      anvModule?.moduleCode === Constants.ANVILAR_MODULES.COMPLIANCE.REPORT &&
      accessScopeWatch === Constants.ACCESS_SCOPES.SCHOOL &&
      !!scopeSchoolWatch;

    const schoolDepartmentsReqId = scopeSchoolWatch as string;

    const {
      dataList: schoolsDepartments,
      isFetchingList: isFetchingSchoolsDepartments,
      isLoadedList: isLoadedSchoolDepartments,
    } = getSchoolDepartmentsListListData(schoolDepartmentsReqId);

    const schoolDepartmentField =
      schoolDepartment &&
      !!schoolsDepartments.length &&
      isLoadedSchoolDepartments;

    React.useEffect(() => {
      if (!schoolDepartment) {
        return;
      }
      getSchoolDepartmentsList(
        {
          id: scopeSchoolWatch,
        },
        { requestId: schoolDepartmentsReqId },
      );
    }, [
      getSchoolDepartmentsList,
      schoolDepartment,
      schoolDepartmentsReqId,
      scopeSchoolWatch,
    ]);

    React.useEffect(() => {
      if (scopeSchoolWatch !== scopeSchoolPrev && schoolDepartment) {
        setValue('scope.schoolDepartment', '');
      }
    }, [schoolDepartment, scopeSchoolWatch, scopeSchoolPrev, setValue]);

    React.useEffect(() => {
      if (
        isLoadedSchoolDepartments &&
        schoolsDepartments.length === 1 &&
        schoolDepartment
      ) {
        const department = schoolsDepartments[0];
        setValue('scope.schoolDepartment', department._id);
      }
    }, [
      isLoadedSchoolDepartments,
      schoolDepartment,
      schoolsDepartments,
      setValue,
    ]);

    React.useEffect(() => {
      if (schoolDepartmentField) {
        setValidateDepartmentField(true);
      } else {
        setValidateDepartmentField(false);
      }
    }, [schoolDepartmentField]);

    const schoolsDepartmentsOptions = React.useMemo(() => {
      return AnvUtils.prepareOptions(schoolsDepartments, {
        field: 'name',
        locale,
      });
    }, [locale, schoolsDepartments]);

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, serverErrors);
    };

    if (isFetchingProfileInfo || isFetchingGeneralSettings) {
      return <IsLoadingComponent isLoading />;
    }

    return (
      <AnvDialog
        open={open}
        loading={isFetchingVerifyData}
        handleClose={!dataVerified ? handleOpenModalConfirm : handleClose}
        title={formatMessage({
          id: 'COMMON.ACCESS.FORM_MODAL.TITLE',
          defaultMessage: 'Create Access Request',
        })}
      >
        {component}
        {dataVerified && (
          <ConfirmModal
            open={dataVerified}
            handleClose={handleCloseConfirmModal}
            onSuccess={onSuccess}
          />
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <AnvTextField
              name={'userEmail'}
              control={control}
              required
              id="userEmail"
              type="userEmail"
              label={
                <FormattedMessage
                  id="COMMON.ACCESS.FORM.EMAIL.LABEL"
                  defaultMessage="User email address"
                />
              }
              variant="filled"
              fullWidth
              helperText={formHelper('userEmail')}
              error={!!formHelper('userEmail')}
            />
            <ScopeForm
              displayEmptyJurisdiction={false}
              displayEmptyTool={false}
              displayEmptyAccessScope={false}
              displayEmptyModule={false}
              hideTags
              accessScopeStore={accessScopeStore}
              metaStores={metaStores}
              tools={tools}
              modules={toolModules}
              jurisdictions={computedJurisdictions}
              serverErrors={serverErrors}
              disabledScopes={computedDisabledScopes}
              isOperator={true}
              forceApplyJurisdictionFilter={
                isAllowedAllJurisdictions
                  ? undefined
                  : FORCE_APPLY_JURISDICTION_FILTER
              }
            />
            {schoolDepartmentField && (
              <AnvSelect
                control={control}
                name={'scope.schoolDepartment'}
                loading={isFetchingSchoolsDepartments}
                label={formatMessage({
                  id: 'COMMON.ACCESS.LABEL.ORG_DEPARTMENT',
                  defaultMessage: 'Educational organization',
                })}
                required
                options={schoolsDepartmentsOptions}
                fullWidth
                helperText={formHelper('scope.schoolDepartment')}
                error={!!formHelper('scope.schoolDepartment')}
              />
            )}
            <AnvDatepicker
              control={control}
              label={
                <FormattedMessage id="COMMON.ACCESS.LABEL.EXPIRATION_DATE" defaultMessage="Expiration Date" />
              }
              required
              name="accessExpirationDate"
              minDate={minExpirationDate}
              maxDate={maxExpirationDate}
              initialFocusedDate={initialFocusedDate}
              helperText={formHelper('accessExpirationDate') as string}
              error={!!formHelper('accessExpirationDate')}
            />
            <AnvTextField
              name={'reasonForRequest'}
              control={control}
              required
              id="reasonForRequest"
              type="reasonForRequest"
              label={
                <FormattedMessage
                  id="COMMON.ACCESS.LABEL.REASON_FOR_REQUEST"
                  defaultMessage="Reason for Request"
                />
              }
              multiline
              rows={3}
              variant="filled"
              fullWidth
              helperText={formHelper('reasonForRequest')}
              error={!!formHelper('reasonForRequest')}
            />

            <Grid container spacing={2} direction={'column'}>
              <Grid item>
                <AnvButton
                  data-test={'request-access'}
                  variant={'contained'}
                  type={'submit'}
                  fullWidth
                  disabled={isFetchingVerifyData}
                >
                  <FormattedMessage
                    id="COMMON.ACCESS.FORM_MODAL.ACTION.SAVE"
                    defaultMessage="Request Access"
                  />
                </AnvButton>
              </Grid>

              <Grid item>
                <AnvButton
                  data-test={'request-cancel'}
                  variant={'text'}
                  customColor={'muted'}
                  type={'button'}
                  fullWidth
                  onClick={!dataVerified ? handleOpenModalConfirm : handleClose}
                >
                  <FormattedMessage
                    id="COMMON.ACCESS.FORM_MODAL.ACTION.CLOSE"
                    defaultMessage="Cancel"
                  />
                </AnvButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </AnvDialog>
    );
  },
);
