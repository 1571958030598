import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import Container from './components/layout/container';
import { ComplaintCategoriesPage } from './pages/complaint/categories';

import PrivateRoute from './route-handlers/PrivateRoute';

import { ROUTES } from './constants';
import NotFoundPage from './route-handlers/NotFoundPage';

import RedirectSourceSystemFail from './pages/auth/AuthFail';
import { SignIn } from './pages/auth/sign-in';
import Dashboard from './pages/dashboard';
import Users from './pages/users';
import Invitations from './pages/invitations';
import AccessList from './pages/access/list';
import OperatorProfile from './pages/profile';
import UserDetails from './pages/users/view';
import SupportConnected from './pages/support/support-connected';
import { ComplaintCategorySettingsPage } from './pages/complaint/categories-settings';

interface IProps {}

const Routes: React.FC<IProps> = () => {
  return (
    <BrowserRouter>
      <Container>
        <Switch>
          <Redirect exact to={ROUTES.DASHBOARD} from={'/'} />
          <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
          <PrivateRoute
            exact
            path={ROUTES.PROFILE_PREFIX}
            component={OperatorProfile}
          />
          <Route exact path={ROUTES.AUTH_SIGN_IN} component={SignIn} />
          <Route
            exact
            path={ROUTES.AUTH_FAIL}
            component={RedirectSourceSystemFail}
          />
          <PrivateRoute
            // exact
            path={ROUTES.INVITATIONS}
            component={Invitations}
          />
          <PrivateRoute
            // exact
            path={ROUTES.SUPPORT}
            component={SupportConnected}
          />
          <PrivateRoute
            exact
            path={ROUTES.ACCESS_REQUEST}
            component={AccessList}
          />
          <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
          <PrivateRoute exact path={ROUTES.USERS} component={Users} />
          <PrivateRoute
            exact
            path={ROUTES.USER_DETAILS}
            component={UserDetails}
          />

          {/* COMPLAINT */}
          <PrivateRoute
            exact
            path={ROUTES.COMPLAINT_CATEGORIES}
            component={ComplaintCategoriesPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.COMPLAINT_CATEGORIES_SETTINGS}
            component={ComplaintCategorySettingsPage}
          />

          <Redirect from="//*" to="/*" />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Container>
    </BrowserRouter>
  );
};

export default Routes;
