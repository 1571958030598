import { AnvUtils, axiosInstance } from '@platform/front-lib';
import type { IGetList } from '@platform/front-lib/dist/service/interfaces';

const { axiosCatch } = AnvUtils;

const axios = axiosInstance;

export class FormsTemplatesHistoryService {
  authUrl = process.env.REACT_APP_API_TOOL_ENDPOINT + '/complaint/forms';

  async getList(data: IGetList & { id: string }) {
    const { entityId, id, ...rest } = data;

    return axiosCatch(
      axios.get(`${this.authUrl}/${id}/history`, {
        params: rest,
      }),
    );
  }
}

export default new FormsTemplatesHistoryService();
