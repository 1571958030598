import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(3),
    },
    headerSignIn: {
      marginTop: theme.spacing(6),
    },
    footerSignIn: {
      marginBottom: theme.spacing(7),
    },
    headerCaption: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(3),
    },
    addressHeader: {
      marginTop: theme.spacing(3),
    },
    headerSent: {
      marginBottom: theme.spacing(3),
    },
    caption: {
      marginBottom: theme.spacing(3),
    },
    backToLoginLink: {
      textAlign: 'center',
    },
    captionResend: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(2),
    },
    mailDescribe: {
      '&&': {
        marginBottom: theme.spacing(3),
        fontSize: 16,
        lineHeight: 1.5,
      },
    },
    formControl: {
      marginBottom: theme.spacing(3),
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    commonControlTopMargin: {
      marginTop: theme.spacing(2),
    },
    commonControlWrapper: {
      marginBottom: theme.spacing(2),
    },
    commonControl: {
      marginBottom: theme.spacing(2),
    },
    controlSmallMargin: {
      marginBottom: theme.spacing(1),
    },
    captionControlError: {
      '&&&': {
        color: theme.palette.error.main,
      },
    },
    captionControl: {
      '&&': {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: 12,
        lineHeight: '16px',
      },
    },
    submit: {
      marginBottom: theme.spacing(3),
    },
    submitSignUp: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(7),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(5)
      },
    },
    forgotLink: {
      display: 'inline-block',
      marginBottom: theme.spacing(3),
    },
    buttonLink: {
      cursor: 'pointer',
    },
    buttonLinkDisabled: {
      opacity: 0.6,
      cursor: 'default',
    },
    bottomLink: {
      textAlign: 'center',
    },
    signUpBlock: {
      textAlign: 'center',
    },
    labelError: {
      color: theme.palette.error.main,
    },
    iconContainer: {
      height: 32,
    },
    alertContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      width: 300,
      margin: '0 auto',
    },
    alertRoot: {
      '&&': {
        borderRadius: 12,

        backgroundColor: 'transparent',
        background:
          'linear-gradient(0deg, rgba(0, 122, 255, 0.05), rgba(0, 122, 255, 0.05)), #FFFFFF',
        boxShadow:
          '0px 4px 16px rgba(23, 43, 77, 0.08), 0px 2px 4px rgba(23, 43, 77, 0.06)',
      },
    },
    captchaContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(1),
    },
    inputTextButton: {
      marginRight: `${theme.spacing(-1.5) + 1}px`,
    },
  }),
);
