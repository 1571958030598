import React from 'react';
import clsx from 'clsx';

import { Grid, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import { AnvIcon } from '@platform/front-lib';

interface IProps {
  [key: string]: any;
}

export const AnvDashboardCard: React.FC<IProps> = ({
  children,
  item,
  toolsMap,
}) => {
  const theme = useTheme();
  let customTheme = item.theme;
  if (item?.tool && toolsMap[item?.tool]) {
    customTheme = toolsMap[item?.tool].theme;
  }

  const icon = getIcon({ item, toolsMap });

  const toolTheme = {
    toolColors: customTheme,
    theme: theme,
  };
  const classes = useStyles(toolTheme);

  return (
    <Grid className={clsx(classes.root)}>
      <Grid style={{ position: 'relative', height: 64, marginBottom: 32 }}>
        <div className={classes.colorRectangle} />
        <div className={classes.wrapper}>
          <div className={classes.iconWrapper}>{icon}</div>
        </div>
      </Grid>
      {children}
    </Grid>
  );
};

const getIcon = ({ item, toolsMap }: any) => {
  if (!item || !toolsMap) {
    return null;
  }

  if (item?.tool && toolsMap[item?.tool]) {
    return (
      <AnvIcon icon={toolsMap[item?.tool].icon} size={40} color={'inherit'} />
    );
  }

  if (item?.icon) {
    return <AnvIcon icon={item.icon} size={40} color={'inherit'} />;
  }

  return <AnvIcon icon={'dotsGrid'} size={40} color={'inherit'} />;
};
