import React from 'react';
import { AnvSchoolName } from '@platform/front-lib';

interface IProps {
  entity: Record<string, any>;
}
const CellScope: React.FC<IProps> = ({ entity }) => {
  // const { locale } = useIntl();

  if (entity?.scopeOrganizationRef) {
    const pretendAsSchool = Object.assign({}, entity?.scopeOrganizationRef, {
      address: entity?.scopeOrganizationRef?.physicalAddress,
    });

    return (
      <>
        <AnvSchoolName school={pretendAsSchool} />
      </>
    );
  }
  /** School */
  if (entity?.scopeSchoolRef) {
    return (
      <AnvSchoolName
        school={entity?.scopeSchoolRef}
        schoolDepartment={entity?.schoolDepartmentRef}
      />
    );
  }

  /** Gov Body */
  if (entity?.scopeGovBodyRef) {
    return (
      <>
        <AnvSchoolName school={entity?.scopeGovBodyRef} />
      </>
    );
  }

  return null;
};

export default CellScope;
