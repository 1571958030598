import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Chip, Collapse, Grid } from '@material-ui/core';

import {
  AnvButton,
  AnvDialog,
  AnvDialogActions,
  AnvDialogBody,
  AnvDialogTitle,
  AnvIcon,
  AnvUtils,
  ModalConfirm,
  TextBody,
  TextSubTitleNew,
  useModal,
} from '@platform/front-lib';

import { useStores } from '../../../stores/root-store';
import { generatePassword } from '../../../utils';
import { useStyles } from './styles';

interface IUsersModalPasswordProps {
  handleClose: () => void;
  open: boolean;
  user: Record<string, any>;
}

export const UsersModalPassword: React.FC<IUsersModalPasswordProps> = ({
  handleClose,
  open,
  user: userShort,
}) => {
  const classes = useStyles();
  const {
    usersStore: { dataMap: usersMap, changePassword, error, isFetching },
  } = useStores();

  const user = usersMap?.user || userShort;

  const [isSaved, setIsSaved] = React.useState(false);

  const [newPassword, setNewPassword] = React.useState('');

  const handleGeneratePassword = () => {
    setIsSaved(false);

    const pass = generatePassword();

    setNewPassword(pass);
  };

  const handleChangePassword = async () => {
    await changePassword({
      id: user._id,
      password: newPassword,
    });

    if (!error) {
      setIsSaved(true);
    }
  };

  /** Confirm modal */
  const {
    handleCloseModal: handleCloseModalConfirm,
    isOpen: isOpenConfirm,
    handleOpenModal: handleOpenConfirm,
  } = useModal();
  const handleCloseLocal = () => {
    if (isSaved) {
      return handleOpenConfirm();
    }

    closeModalForce();
  };

  const closeModalForce = () => {
    handleClose();
  };

  return (
    <AnvDialog
      handleClose={isSaved ? handleCloseLocal : handleClose}
      open={open}
    >
      <AnvDialogTitle>
        <FormattedMessage
          id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.TITLE"
          defaultMessage="Change Password"
        />
      </AnvDialogTitle>

      <AnvDialogBody>
        {newPassword ? (
          <TextSubTitleNew>
            <Chip
              label={newPassword}
              className={clsx(classes.chip, {
                [classes.chipSuccess]: isSaved && !error,
              })}
            />
          </TextSubTitleNew>
        ) : (
          <TextBody color={'textSecondary'}>
            <FormattedMessage
              id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.CAPTION.GENERATE"
              defaultMessage="Click to generate password"
            />
          </TextBody>
        )}

        <Collapse in={!isSaved}>
          <div style={{ marginTop: 16 }}>
            <AnvButton
              fullWidth
              color={'primary'}
              variant={'outlined'}
              onClick={handleGeneratePassword}
              disabled={isSaved || isFetching}
            >
              <FormattedMessage
                id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.ACTION.GENERATE"
                defaultMessage="Generate"
              />
            </AnvButton>
          </div>
        </Collapse>

        {error && (
          <TextBody color={'error'} style={{ marginTop: 16 }}>
            <FormattedMessage
              id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.ERROR"
              defaultMessage="Error has occurred, please try again later"
            />
          </TextBody>
        )}
      </AnvDialogBody>

      <AnvDialogActions>
        <Grid container spacing={2} xs={12}>
          {isSaved && !error ? (
            <Grid item xs={12}>
              <AnvButton
                data-test={'person-data-form-submit'}
                color={'primary'}
                variant={'contained'}
                fullWidth
                onClick={AnvUtils.copyTextToClipboard(newPassword)}
                startIcon={<AnvIcon icon={'surveyCopy'} size={16} />}
              >
                <FormattedMessage
                  id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.COPY"
                  defaultMessage="Copy To Buffer Password"
                />
              </AnvButton>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <AnvButton
                  disabled={isSaved || isFetching}
                  data-test={'person-data-form-submit'}
                  color={'primary'}
                  variant={'contained'}
                  fullWidth
                  onClick={handleChangePassword}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </AnvButton>
              </Grid>

              <Grid item xs={12}>
                <AnvButton
                  disabled={isFetching}
                  data-test={'person-data-cancel'}
                  variant={'text'}
                  customColor={'muted'}
                  fullWidth
                  type={'button'}
                  onClick={closeModalForce}
                >
                  <FormattedMessage id="APP.CLOSE" defaultMessage="Close" />
                </AnvButton>
              </Grid>
            </>
          )}
        </Grid>
      </AnvDialogActions>

      <ModalConfirm
        isOpen={isOpenConfirm}
        text={
          <FormattedMessage
            id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.CONFIRM.TEXT"
            defaultMessage="Are you sure to close this dialog? When you closed this dialog, you'll can't see password again."
          />
        }
        acceptText={
          <FormattedMessage
            id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.CONFIRM.YES"
            defaultMessage="Yes"
          />
        }
        confirmAction={closeModalForce}
        cancelText={
          <FormattedMessage
            id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.CONFIRM.NO"
            defaultMessage="No"
          />
        }
        cancelAction={handleCloseModalConfirm}
        handleClose={handleCloseModalConfirm}
      />
    </AnvDialog>
  );
};
