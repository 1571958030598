import { AccessService, axiosInstance } from '@platform/front-lib';
import { envConst } from '../../constants';
import { axiosCatch } from '../../service/utils';

const axios = axiosInstance;
export class AccessServiceEnhanced extends AccessService {
  private apiUrl = envConst.apiUrl + '/access';

  async verifyData(data?: any) {
    return axiosCatch(axios.post(`${this.apiUrl}/verify-data`, data));
  }

  async createOne(data?: any) {
    return axiosCatch(axios.post(`${this.apiUrl}`, data));
  }

  async renewAccessRequest(data?: any) {
    const { accessRequestId, ...rest } = data;

    return axiosCatch(
      axios.post(
        `${this.apiUrl}/${accessRequestId}/renew-access-request`,
        rest,
      ),
    );
  }
  async archiveAccessRequest(data?: any) {
    const { accessRequestId, ...rest } = data;

    return axiosCatch(
      axios.patch(`${this.apiUrl}/${accessRequestId}/archive`, rest),
    );
  }
}
