import { makeStyles } from '@material-ui/core';

import { BORDER_RADIUS } from 'src/styles/constants';

interface IStyleProps {
  toolColors: Record<string, any> | undefined;
  theme: any;
}

const CARD_BOX_SHADOW =
  '0px 0px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.18)';

export const useStyles = makeStyles({
  root: ({ theme, toolColors }: IStyleProps) => ({
    minHeight: 140,
    width: 324,

    boxShadow: CARD_BOX_SHADOW,
    textDecoration: 'none',
    borderRadius: BORDER_RADIUS,
    height: '100%',

    [theme.breakpoints.down(768)]: {
      minHeight: 96,
      width: 'auto',
      backgroundColor: 'none',
    },

    '&.active': {
      backgroundColor: toolColors?.brandColorLight,
      borderRadius: BORDER_RADIUS,
    },
  }),

  cardWrapper: ({ theme }: IStyleProps) => ({
    backgroundColor: 'transparent',
    [theme.breakpoints.down(768)]: {
      width: '100%',
      marginBottom: theme.spacing(1),

      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),

  toolColor: ({ toolColors }: IStyleProps) => ({
    color: toolColors?.brandColor,
  }),

  colorRectangle: ({ theme, toolColors }: IStyleProps) => ({
    height: 64,
    width: '100%',
    backgroundColor: toolColors?.brandColorLight,
    color: toolColors?.brandColorLight,
    position: 'absolute',
    top: 0,
    borderRadius: '6px 6px 0px 0px',
    [theme.breakpoints.down(768)]: {
      minHeight: 0,
      height: '100%',
      width: 48,
      left: 0,
      borderRadius: 'unset',
    },
  }),

  tooltipIcon: ({ theme }: IStyleProps) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),

    // fontSize: 18,
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      top: 0,
      right: 0,
      // fontSize: 18,
    },
  }),

  availableModules: ({ theme }: IStyleProps) => ({
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(0.5, 1),

    fontFamily: 'Inter',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '14px',

    border: '1px solid rgba(3, 57, 61, 0.15)',
    borderRadius: 3,

    textTransform: 'uppercase',
  }),

  title: ({ theme }: IStyleProps) => ({
    paddingBottom: 0,
    marginBottom: 0,
    fontFamily: "'Inter', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',

    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  }),

  badgeWrapper: ({ theme }: IStyleProps) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 16,
      marginBottom: 0,
    },
  }),
  badgeRoot: ({ theme }: IStyleProps) => ({
    '& .MuiBadge-badge:not(.MuiBadge-invisible)': {
      transform: 'unset',
      border: `1px solid ${theme.palette.common.white}`,
    },
  }),

  iconWrapper: ({ theme, toolColors }: IStyleProps) => ({
    width: 64,
    height: 64,
    padding: 12,
    backgroundColor: toolColors?.brandColor,
    borderRadius: '50%',
    zIndex: 5,
    color: theme.palette.common.white,

    position: 'absolute',
    left: 24,
    top: 16,
  }),

  wrapper: ({ theme }: IStyleProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }),
  wrapperBack: ({ theme }: IStyleProps) => ({
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  }),
  toolDescription: ({ theme }: IStyleProps) => ({
    margin: theme.spacing(0, 0, 1, 0),
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
    },
  }),
  viewModulesMobile: ({ theme }: IStyleProps) => ({
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important',
    },
  }),
  cardBackMobileIconButton: ({ theme }: IStyleProps) => ({
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: 4,
      top: 2,
      transform: 'rotate(270deg)',
    },
  }),
});
