import React from 'react';
import { Grid } from '@material-ui/core';

import { TextBody } from '../text';
import { useStyles } from './styles';
import { GridDirection } from '@material-ui/core/Grid/Grid';

type TMd = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 12;
interface IAnvListDataProps {
  labelMd?: TMd;
  label?: string | JSX.Element | null;
  value?: string | JSX.Element | null;
  direction?: GridDirection;
}

export const AnvListData: React.FC<IAnvListDataProps> = ({
  labelMd = 6,
  label,
  value,
  direction = 'column',
}) => {
  const classes = useStyles();

  const valueMd = (12 - labelMd) as TMd;

  return (
    <Grid
      container
      item
      direction={direction}
      className={classes.dataItem}
      alignItems={'center'}
    >
      <Grid item md={labelMd} xs={12}>
        <TextBody className={classes.dataLabel}>{label}</TextBody>
      </Grid>
      <Grid item md={valueMd || 12} xs={12}>
        <TextBody className={classes.dataValue}>{value}</TextBody>
      </Grid>
    </Grid>
  );
};
