import { AccessStore } from '@platform/front-lib';
import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';
import { AccessServiceEnhanced } from './service';

export class AccessStoreEnhanced extends AccessStore {
  service: AccessServiceEnhanced;

  errorVerifyData: Record<string, any> | null | string = null;
  isFetchingVerifyData: boolean = false;
  isLoadedVerifyData: boolean = false;
  isErrorVerifyData: boolean = false;
  verifiedData: Record<string, any> | null = null;

  resetErrors = () => {
    this.error = null;
    this.isError = false;

    this.errorVerifyData = null;
    this.isErrorVerifyData = false;

    this.errorCreateOne = null;
    this.isErrorCreateOne = false;
  };

  resetVerifyData = () => {
    this.errorVerifyData = null;
    this.isFetchingVerifyData = false;
    this.isLoadedVerifyData = false;
    this.isErrorVerifyData = false;
    this.verifiedData = null;
  };

  resetErrorsVerifyData = () => {
    this.errorVerifyData = null;
    this.isErrorVerifyData = false;
  };

  errorCreateOne: Record<string, any> | null | string = null;
  isFetchingCreateOne: boolean = false;
  isLoadedCreateOne: boolean = false;
  isErrorCreateOne: boolean = false;

  resetCreateOne = () => {
    this.errorCreateOne = null;
    this.isFetchingCreateOne = false;
    this.isLoadedCreateOne = false;
    this.isErrorCreateOne = false;
  };

  resetErrorsCreateOne = () => {
    this.errorCreateOne = null;
    this.isErrorCreateOne = false;
  };

  verifyData = async (payload: any, meta?: IActionMeta) => {
    this.setLoadingVerifyData();
    const [error, response] = await this.service.verifyData(payload);

    if (error || response.error) {
      return this.setErrorVerifyData(() => {
        this.errorVerifyData = error || response.error;
      });
    }

    this.setLoadedVerifyData(() => {
      this.verifiedData = response;
      meta?.successFunc?.();
    });
  };

  archiveAccessRequest = async (payload: any, meta?: IActionMeta) => {
    this.setLoading();
    const [error, response] = await this.service.archiveAccessRequest(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
      return response;
    });
  };


  renewAccessRequest = async (payload: any, meta?: IActionMeta) => {
    this.setLoading();
    const [error, response] = await this.service.renewAccessRequest(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
      return response;
    });
  };

  createOne = async (payload: any, meta?: IActionMeta) => {
    this.setLoadingCreateOne();
    const [error, response] = await this.service.createOne(payload);

    if (error || response.error) {
      return this.setErrorCreateOne(() => {
        this.errorCreateOne = error || response.error;
      });
    }

    this.setLoadedCreateOne(() => {
      meta?.successFunc?.(response);
      return response;
    });
  };

  setLoadingCreateOne = (func?: () => void) => {
    this.errorCreateOne = null;
    this.isErrorCreateOne = false;
    this.isFetchingCreateOne = true;

    func?.();
  };

  setErrorCreateOne = (func?: () => void) => {
    this.isFetchingCreateOne = false;
    this.isErrorCreateOne = true;

    func?.();
  };

  setLoadedCreateOne = (func?: () => void) => {
    this.isFetchingCreateOne = false;
    this.isLoadedCreateOne = true;
    this.isErrorCreateOne = false;

    func?.();
  };
  setLoadingVerifyData = (func?: () => void) => {
    this.errorVerifyData = null;
    this.isErrorVerifyData = false;
    this.isFetchingVerifyData = true;

    func?.();
  };

  setErrorVerifyData = (func?: () => void) => {
    this.isFetchingVerifyData = false;
    this.isErrorVerifyData = true;

    func?.();
  };

  setLoadedVerifyData = (func?: () => void) => {
    this.isFetchingVerifyData = false;
    this.isLoadedVerifyData = true;
    this.isErrorVerifyData = false;

    func?.();
  };

  constructor() {
    super();
    this.service = new AccessServiceEnhanced();
    makeObservable(this, {
      errorVerifyData: observable,
      isFetchingVerifyData: observable,
      isLoadedVerifyData: observable,
      isErrorVerifyData: observable,
      verifiedData: observable,
      resetVerifyData: action,
      resetErrorsVerifyData: action,
      verifyData: action,
      setLoadingVerifyData: action,
      setErrorVerifyData: action,
      setLoadedVerifyData: action,

      errorCreateOne: observable,
      isFetchingCreateOne: observable,
      isLoadedCreateOne: observable,
      isErrorCreateOne: observable,
      resetCreateOne: action,
      resetErrorsCreateOne: action,
      createOne: action,
      setLoadingCreateOne: action,
      setErrorCreateOne: action,
      setLoadedCreateOne: action,
      resetErrors: action,
      renewAccessRequest: action,
    });
  }
}
