import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

import { ReactComponent as Survey } from './assets/tools/survey.svg';
import { icons } from './icons';
import { useTheme } from '@material-ui/core';

export type TIcon = keyof typeof icons; // 'dev' | 'manager'
interface IIconProps {
  color?:
    | 'inherit'
    | 'white'
    | 'black'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'error'
    | 'lightIcons'
    | 'textPrimary'
    | 'textSecondary';
  icon: TIcon | string;
  size?: number;
  width?: number;
  height?: number;
  offset?: number;
  leftOffset?: number;
  opacity?: number;
  withBackground?: boolean;
  className?: any;
  style?: Record<string, any>;
}

const DEFAULT_ICON_SIZE = 24;

const AnvIcon: React.FC<IIconProps> = ({
  icon,
  size = DEFAULT_ICON_SIZE,
  width,
  height,
  withBackground,
  className,
  color = 'inherit',
  opacity = 1,
  offset,
  leftOffset,
  style,
  ...props
}: IIconProps) => {
  // @ts-ignore
  const IconTag: React.JSXElementConstructor = icons[icon] || Survey;

  const classes = useStyles();
  const theme = useTheme();

  const styles: Record<string, any> = {
    width: width || size,
    height: height || size,
    ...style,
  };

  if (leftOffset) {
    styles.marginLeft = theme.spacing(leftOffset);
  }
  if (offset) {
    styles.marginRight = theme.spacing(offset);
  }

  const colorClass = color && classes[color];

  return (
    <span
      className={clsx('anv-icon', colorClass, classes.iconWrapper, {
        [className]: !!className,
        [classes.withBackground]: !!withBackground,
        [classes.primary]: !color,
      })}
      style={{ ...styles, opacity }}
      {...props}
    >
      <IconTag width={'100%'} height={'100%'} />
    </span>
  );
};

export default AnvIcon;
