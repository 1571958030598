import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import {
  AnvButton,
  AnvDialog,
  AnvDialogActions,
  AnvDialogBody,
  AnvDialogTitle,
  AnvListContainer,
  AnvListData,
  AnvUtils,
} from '@platform/front-lib';

import type { IAnvListDataProps } from '@platform/front-lib/dist/components/shared/anv-list-data/AnvListData';
import type { IComplaintCategory } from '@platform/front-lib/dist/models';

import { useStores } from '../../../../stores/root-store';

interface IComplaintCategoryDetailModalProps {
  open: boolean;
  handleClose: () => void;

  complaintCategory: IComplaintCategory;
}

const LABEL_MD = 3;

export const ComplaintCategoryDetailModal: React.FC<IComplaintCategoryDetailModalProps> = observer(
  ({ open, handleClose, complaintCategory }) => {
    const { locale } = useIntl();

    const {
      formsStore: {
        FormsTemplateStore: {
          dataDetailedMap: formTemplatesMap,
          getOne: getFormTemplate,
        },
      },
      metaStores: {
        jurisdictionStore: {
          dataMap: jurisdictionMap,
          getList: getJurisdictions,
          isLoadedList: isLoadedJurisdictions,
        },
        reviewSeveritiesStore: {
          getList: getSeverities,
          dataMap: severitiesMap,
          isLoadedList: isLoadedSeverities,
        },
      },
    } = useStores();

    React.useEffect(() => {
      if (!isLoadedJurisdictions) {
        getJurisdictions({});
      }
    }, [getJurisdictions, isLoadedJurisdictions]);

    const formsTemplateEntity =
      complaintCategory.formsTemplate &&
      formTemplatesMap[complaintCategory.formsTemplate];

    React.useEffect(() => {
      if (!formsTemplateEntity && complaintCategory.formsTemplate) {
        // @ts-ignore
        getFormTemplate?.({ id: complaintCategory.formsTemplate });
      }
    }, [complaintCategory.formsTemplate, formsTemplateEntity, getFormTemplate]);

    React.useEffect(() => {
      if (!isLoadedSeverities) {
        getSeverities({});
      }
    }, [isLoadedSeverities, getSeverities]);

    const listDataCommonProps: Partial<IAnvListDataProps> = React.useMemo(() => {
      return {
        direction: 'row',
        omitColon: true,
        labelMd: LABEL_MD,
      };
    }, []);

    return (
      <AnvDialog open={true} handleClose={handleClose}>
        <AnvDialogTitle>
          {AnvUtils.getNameByLangNullable(
            complaintCategory,
            'description',
            locale,
          )}
        </AnvDialogTitle>

        <AnvDialogBody>
          <AnvListContainer>
            {complaintCategory.jurisdiction && (
              <AnvListData
                {...listDataCommonProps}
                label={
                  <FormattedMessage
                    id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.JURISDICTION'}
                    defaultMessage={'Jurisdiction'}
                  />
                }
                value={
                  AnvUtils.getNameByLangNullable(
                    jurisdictionMap[complaintCategory.jurisdiction],
                    'name',
                    locale,
                  ) || complaintCategory.jurisdictionRef?.code
                }
              />
            )}

            <AnvListData
              {...listDataCommonProps}
              label={
                <FormattedMessage
                  id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.REVIEW_PERIOD'}
                  defaultMessage={'Review Period'}
                />
              }
              value={complaintCategory.reviewPeriod}
            />

            {complaintCategory.severity && (
              <AnvListData
                {...listDataCommonProps}
                label={
                  <FormattedMessage
                    id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.SEVERITY'}
                    defaultMessage={'Severity'}
                  />
                }
                value={AnvUtils.getNameByLangNullable(
                  severitiesMap?.[complaintCategory.severity],
                  'name',
                  locale,
                )}
              />
            )}

            {/*<AnvListData*/}
            {/*  {...listDataCommonProps}*/}
            {/*  label={*/}
            {/*    <FormattedMessage*/}
            {/*      id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.ANONYMOUS_PROTECTION'}*/}
            {/*      defaultMessage={'Anonymous Protection'}*/}
            {/*    />*/}
            {/*  }*/}
            {/*  value={*/}
            {/*    complaintCategory.anonymityProtection ? (*/}
            {/*      <FormattedMessage*/}
            {/*        id={*/}
            {/*          'SUPPORT.COMPLAINT.CATEGORIES.VIEW.ANONYMOUS_PROTECTION.YES'*/}
            {/*        }*/}
            {/*        defaultMessage={'Yes'}*/}
            {/*      />*/}
            {/*    ) : (*/}
            {/*      <FormattedMessage*/}
            {/*        id={*/}
            {/*          'SUPPORT.COMPLAINT.CATEGORIES.VIEW.ANONYMOUS_PROTECTION.NO'*/}
            {/*        }*/}
            {/*        defaultMessage={'No'}*/}
            {/*      />*/}
            {/*    )*/}
            {/*  }*/}
            {/*/>*/}

            <AnvListData
              {...listDataCommonProps}
              label={
                <FormattedMessage
                  id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.REVIEWED'}
                  defaultMessage={'Reviewed'}
                />
              }
              value={
                complaintCategory.reviewed ? (
                  <FormattedMessage
                    id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.REVIEWED.YES'}
                    defaultMessage={'Yes'}
                  />
                ) : (
                  <FormattedMessage
                    id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.REVIEWED.NO'}
                    defaultMessage={'No'}
                  />
                )
              }
            />

            {complaintCategory.formsTemplate && formsTemplateEntity && (
              <AnvListData
                {...listDataCommonProps}
                label={
                  <FormattedMessage
                    id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.FORM_TEMPLATE'}
                    defaultMessage={'Form Template'}
                  />
                }
                value={formsTemplateEntity.title}
              />
            )}
          </AnvListContainer>
        </AnvDialogBody>

        <AnvDialogActions>
          <AnvButton customColor={'muted'} fullWidth onClick={handleClose}>
            <FormattedMessage
              id={'SUPPORT.COMPLAINT.CATEGORIES.VIEW.ACTION.CLOSE'}
              defaultMessage={'Close'}
            />
          </AnvButton>
        </AnvDialogActions>
      </AnvDialog>
    );
  },
);
