import React from 'react';

import { MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import {
  AnvIcon,
  AnvMenu,
  DefaultIcons,
  IListActionsComponent,
  useMenu,
} from '@platform/front-lib';

import { IComplaintCategory } from '@platform/front-lib/dist/models';

interface ITableActions extends IListActionsComponent {
  data: IComplaintCategory;

  handleOpenEdit: (id: string) => void;
  handleOpenClone: (id: string) => void;
  handleOpenDelete: (id: string) => void;
}

export const TableActions: React.FC<ITableActions> = observer(
  ({ data, handleOpenClone, handleOpenEdit, handleOpenDelete }) => {
    const { formatMessage } = useIntl();

    const { closeMenu, handleClose: handleCloseMenu } = useMenu();

    if (!data._id) {
      return null;
    }

    return (
      <AnvMenu closeMenu={closeMenu}>
        <MenuItem
          title={formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.MENU.EDIT',
            defaultMessage: 'Edit',
          })}
          onClick={() => {
            handleCloseMenu();
            data._id && handleOpenEdit(data._id);
          }}
        >
          <AnvIcon icon={DefaultIcons.EDIT} color={'primary'} />
          {formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.MENU.EDIT',
            defaultMessage: 'Edit',
          })}
        </MenuItem>

        <MenuItem
          title={formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.MENU.DUPLICATE',
            defaultMessage: 'Duplicate',
          })}
          onClick={() => {
            handleCloseMenu();
            data._id && handleOpenClone(data._id);
          }}
        >
          <AnvIcon icon={'surveyCopy'} color={'primary'} />
          {formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.MENU.DUPLICATE',
            defaultMessage: 'Duplicate',
          })}
        </MenuItem>

        <MenuItem
          title={formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.MENU.DELETE',
            defaultMessage: 'Delete',
          })}
          onClick={() => {
            handleCloseMenu();
            data._id && handleOpenDelete(data._id);
          }}
        >
          <AnvIcon icon={'trash'} color={'error'} />
          {formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.MENU.DELETE',
            defaultMessage: 'Delete',
          })}
        </MenuItem>
      </AnvMenu>
    );
  },
);
