import { action, computed, makeAutoObservable, observable } from 'mobx';
import Cookies from 'js-cookie';
import { IProfile } from '../log-in-store';
import {
  getCookieProfile,
  getCookieToken,
  getCookieTokenRefresh,
  removeCookieTokenAndProfile,
} from '../../../utils/cookie';
import LocalStorage from '../../_helpers/storage';
import { extractRootDomain } from '../../../utils';
import { AUTH_PROFILE_KEY, AUTH_TOKEN_KEY, envConst } from '../../../constants';
import AuthService from '../../../service/auth/auth';
import { ILogInData } from '../../../models/requests';

export const MODULE_FILTER_PREFIX = 'filter_';

class AuthStore {
  @observable
  token: string | null = null;
  @observable
  refresh: string | null = null;
  @observable
  error: Record<string, any> | null | string = null;
  @observable
  isAuthComplete: boolean = false;
  @observable
  profile: IProfile | null = null;
  @observable
  countryDialCode: string | null = '+1';

  @observable isRequested: boolean = false;
  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @observable modulesFilter: Record<string, any> = {};
  @observable modulesCodes: string[] = [];

  @action
  public reset = () => {
    this.token = getCookieTokenRefresh({ isOperator: true }) || '';
    this.refresh = getCookieTokenRefresh({ isOperator: true }) || '';
    this.profile = getCookieProfile({ isOperator: true });
  };

  @action
  resetError = () => {
    this.isError = false;
    this.error = null;
  };

  @action
  public setIsRequested = (state: boolean) => {
    this.isRequested = state;
  };

  @action
  public setIsAuthComplete = (state: boolean) => {
    this.isAuthComplete = state;
  };

  @action
  public setActiveLocale = async (locale: string) => {
    const prevLocale = this.profile?.locale;

    if (this.profile) {
      this.profile.locale = locale;
      LocalStorage.setJSON(AUTH_PROFILE_KEY + '-operator', this.profile);
      Cookies.set(
        'anvilar-profile-operator',
        JSON.stringify(this.profile || {}),
        {
          domain: extractRootDomain(envConst.launchpadUrl),
        },
      );
    }
    LocalStorage.set('ui_locale', locale);

    const [error] = await AuthService.setLocale({ locale });

    if (error && prevLocale && this.profile) {
      setTimeout(() => {
        if (this.profile) this.profile.locale = prevLocale;
      }, 1500);
    }
  };

  @action
  logIn = async (payload: ILogInData) => {
    this.setLoading();

    const [error, response] = await AuthService.logIn(payload);

    if (error) {
      return this.setError(() => {
        this.error = error;
      });
    }

    this.setLoaded(() => {
      this.token = response.access_token;
      this.refresh = response.refresh_token;
      this.profile = response.payload;

      if (this.token && process.env.NODE_ENV !== 'production') {
        LocalStorage.set(AUTH_TOKEN_KEY + '-operator', this.token);
        LocalStorage.setJSON(AUTH_PROFILE_KEY + '-operator', this.profile);
      }
    });
  };

  @action
  logOut = async () => {
    this.setLoading();
    const [error] = await AuthService.logOut();

    if (error) {
      return this.setError(() => {
        this.error = error;
      });
    }

    this.setLoaded(() => {
      this.token = null;
      this.profile = null;
      removeCookieTokenAndProfile({ isOperator: true });
    });
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };
  @computed
  get isLoggedIn() {
    if (!this.token) {
      return false;
    }

    const jwt = this.decodeToken(this.token);

    if ((jwt?.exp && jwt.exp * 1000) > Date.now()) {
      return true;
    }

    if (!this.refresh) {
      return false;
    }

    const refresh = this.decodeToken(this.refresh);

    if ((refresh?.exp && refresh.exp * 1000) > Date.now()) {
      return true;
    }

    return false;
  }

  constructor() {
    makeAutoObservable(this);

    const defaultState = this.generateDefaultState();

    this.token = defaultState?.token || null;
    this.refresh = defaultState?.refresh || null;
    this.profile = defaultState?.profile || null;
  }

  @action
  updateDataFromCookie = () => {
    this.token = getCookieToken({ isOperator: true }) || '';
    this.refresh = getCookieTokenRefresh({ isOperator: true }) || '';
    this.profile = getCookieProfile({ isOperator: true });
  };

  generateDefaultState = () => {
    return {
      token: getCookieToken({ isOperator: true }) || '',
      refresh: getCookieTokenRefresh({ isOperator: true }) || '',
      profile: getCookieProfile({ isOperator: true }),
    };
  };
  isTokenNotExpired() {
    if (!this.token) {
      return;
    }

    const jwt = this.decodeToken(this.token);

    return (jwt?.exp && jwt.exp * 1000) > Date.now() || false;
  }

  decodeToken(jwtToken: string) {
    if (!jwtToken) {
      return null;
    }

    try {
      const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
      return jwt || null;
    } catch (e) {
      return null;
    }
  }
}

export default AuthStore;
