import { Constants } from '@platform/front-lib';

const { TOOLS } = Constants;

export const envConst = {
  version: process.env.REACT_APP_VERSION || '',

  apiUrl: process.env.REACT_APP_API_TOOL_ENDPOINT,
  apiAuthUrl: process.env.REACT_APP_API_AUTH_ENDPOINT,
  toolCode: process.env.REACT_APP_TOOL_CODE,

  launchpadUrl:
    process.env.REACT_APP_LAUNCHPAD_BASE_URL ||
    'https://anvilar.quartsoft.com/auth',
  launchpadUrlLogOut:
    (process.env.REACT_APP_LAUNCHPAD_BASE_URL ||
      'https://anvilar.quartosft.com/auth') + '',

  tools: {
    [TOOLS.COMPLAINT]: { front: process.env.REACT_APP_COMPLAINT_FRONT },
    [TOOLS.DIRECTORY]: { front: process.env.REACT_APP_DIRECTORY_FRONT },
    [TOOLS.SUPPORT]: {
      front: process.env.REACT_APP_SUPPORT_FRONT,
    },
  },
};
