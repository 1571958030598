import { TCommonDate } from '@platform/front-lib/dist/models';
import GeneralSettingStore from '@platform/front-lib/dist/stores/meta/general-settings';
import { addDays, endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';
import React from 'react';
import { useStores } from '../stores/root-store';

const SETTING_KEY = 'PERIOD_EXPIRED_SOON';

export interface IAdminSetting {
  _id?: string;

  key: string;
  value: string;
  description?: string;
  tool?: string;
  changeHistoryEvents?: string[];
  createdAt?: TCommonDate;
  updatedAt?: TCommonDate;
}

export function useGeneralSettings({
  generalSettingStore,
  key,
}: {
  key: string | string[];
  generalSettingStore: GeneralSettingStore;
}) {
  const {
    getGeneralSetting,
    generalSettings,
    isFetching,
  } = generalSettingStore;

  const keys = React.useMemo(() => (Array.isArray(key) ? key : [key]), [key]);

  const notFound = React.useMemo(() => {
    return keys.filter((id) => {
      return !generalSettings[id];
    });
  }, [generalSettings, keys]);

  React.useEffect(() => {
    if (!notFound.length) {
      return;
    }
    const idsPrepared = notFound.filter(Boolean);

    if (!idsPrepared.length) {
      return;
    }

    idsPrepared.forEach((id) => {
      getGeneralSetting({ key: id }, { onlyMapUpdate: true });
    });
  }, [getGeneralSetting, notFound]);

  const preparedList = keys
    .map((id) => {
      return generalSettings[id];
    })
    .filter(Boolean);

  return {
    settings: preparedList,
    isFetchingSettings: isFetching,
  };
}

export function useAccessRequestRenewPeriod() {
  const {
    metaStores: { generalSettingStore },
  } = useStores();

  const { settings } = useGeneralSettings({
    generalSettingStore,
    key: SETTING_KEY,
  });

  const adminSettings = settings[0] as IAdminSetting;

  const checkIsRenewAvailable = React.useCallback(
    ({
      startDate,
      endDate,
      expirationDate,
    }: {
      startDate: Date;
      endDate: Date;
      expirationDate: Date;
    }) => {
      if (!startDate || !endDate || !expirationDate) {
        return false;
      }

      return (
        isAfter(expirationDate, startDate) && isBefore(expirationDate, endDate)
      );
    },
    [],
  );

  return React.useMemo(() => {
    if (!adminSettings) {
      return null;
    }

    const valueParsed = parseInt(adminSettings.value, 10);

    const isValueValid = !isNaN(valueParsed) && valueParsed >= 0;

    if (!isValueValid) {
      return null;
    }
    const startDate = startOfDay(new Date());
    const endDate = endOfDay(addDays(startDate, valueParsed));

    return {
      startDate,
      endDate,
      checkIsRenewAvailable: (expirationDate: Date) =>
        checkIsRenewAvailable({ startDate, endDate, expirationDate }),
    };
  }, [adminSettings, checkIsRenewAvailable]);
}
