import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  AnvButton,
  AnvDialog,
  AnvDialogActionsGrid,
  AnvListContainer,
  AnvListData,
  AnvUtils,
  Constants,
} from '@platform/front-lib';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStores } from '../../../../stores/root-store';
import { IRequestAccessModule } from './validation';
import { Grid } from '@material-ui/core';

interface IProps {
  open: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
}

const LABEL_MD = 4;

interface IVerifiedData extends IRequestAccessModule {
  requestedBy: string;
  firstName: string;
  lastName: string;
}

export const ConfirmModal: React.FC<IProps> = observer(
  ({ open, handleClose, onSuccess }) => {
    const { formatMessage, locale } = useIntl();

    const {
      metaStores,
      accessStore: { verifiedData, createOne, isFetchingCreateOne },
    } = useStores();

    const {
      toolsStore: { dataMap: dataMapTools, dataMapModules },
      schoolsStore: { schoolsMap },
      organizationsStore: { organizationsMap },
    } = metaStores;

    const values = verifiedData as IVerifiedData;

    const tool = dataMapTools[values.tool];
    const anvModule = dataMapModules[values.module];
    const eduEntity =
      schoolsMap[values?.scope?.school || ''] ||
      organizationsMap[values?.scope?.organization || ''] ||
      null;

    const tooName = AnvUtils.getNameByLangNullable(tool, 'name', locale) || '';
    const anvModuleName =
      AnvUtils.getNameByLangNullable(anvModule, 'name', locale) || '';
    const eduEntityName =
      AnvUtils.getNameByLangNullable(eduEntity, 'name', locale) || '';

    const userName = `${values.firstName} ${values.lastName}`;

    const handleConfirm = () => {
      createOne(verifiedData, {
        successFunc: () => {
          handleClose();
          onSuccess && onSuccess();
        },
      });
    };

    return (
      <AnvDialog
        loading={isFetchingCreateOne}
        open={open}
        handleClose={handleClose}
        title={formatMessage({
          id: 'COMMON.ACCESS.MODAL_CONFIRM.TITLE',
          defaultMessage: 'Confirmation',
        })}
        subTitle={formatMessage({
          id: 'COMMON.ACCESS.MODAL_CONFIRM.SUB_TITLE',
          defaultMessage: 'Please confirm the creation of access request',
        })}
      >
        <AnvListContainer>
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="COMMON.ACCESS.MODAL_CONFIRM.LABEL.USER"
                defaultMessage="User"
              />
            }
            value={userName}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="COMMON.ACCESS.MODAL_CONFIRM.LABEL.EMAIL_ADDRESS"
                defaultMessage="Email address"
              />
            }
            value={values.userEmail}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="COMMON.ACCESS.MODAL_CONFIRM.LABEL.EDU_ENTITY"
                defaultMessage="School / Organization"
              />
            }
            value={eduEntityName}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="COMMON.ACCESS.MODAL_CONFIRM.LABEL.TOOL"
                defaultMessage="Tool"
              />
            }
            value={tooName}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="COMMON.ACCESS.MODAL_CONFIRM.LABEL.MODULE"
                defaultMessage="Module"
              />
            }
            value={anvModuleName}
          />
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage
                id="COMMON.ACCESS.MODAL_CONFIRM.LABEL.ACCESS_REASON"
                defaultMessage="Reason"
              />
            }
            value={values.reasonForRequest}
          />{' '}
          <AnvListData
            direction={'row'}
            labelMd={LABEL_MD}
            label={
              <FormattedMessage id="COMMON.ACCESS.MODAL_CONFIRM.LABEL.EXPIRATION_DATE" defaultMessage="Expiration Date" />
            }
            value={AnvUtils.formatWLocale(
              values.accessExpirationDate,
              Constants.DATE_FORMATS.DEFAULT_FORMAT,
            )}
          />
        </AnvListContainer>

        <AnvDialogActionsGrid>
          <Grid item xs={12}>
            <AnvButton
              variant={'contained'}
              fullWidth
              onClick={handleConfirm}
              disabled={isFetchingCreateOne}
            >
              {formatMessage({
                id: 'COMMON.ACCESS.MODAL_CONFIRM.ACTION.SAVE',
                defaultMessage: 'Confirm',
              })}
            </AnvButton>
          </Grid>
          <Grid item xs={12}>
            <AnvButton
              variant={'text'}
              customColor={'muted'}
              fullWidth
              onClick={handleClose}
            >
              {formatMessage({
                id: 'COMMON.ACCESS.MODAL_CONFIRM.ACTION.CLOSE',
                defaultMessage: 'Cancel',
              })}
            </AnvButton>
          </Grid>
        </AnvDialogActionsGrid>
      </AnvDialog>
    );
  },
);
