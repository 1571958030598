import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Collapse, Grid, TextField } from '@material-ui/core';
import AnvDialog from '../../../../components/shared/anv-modal';
import { useStyles } from './styles';
import {
  REQUEST_ACCESS_TYPES,
  validationsMessages,
} from '../../../../constants';
import {
  formatWLocale,
  formErrorHelper,
  getItemById,
  getNameByLangNullable,
} from '../../../../utils';
import CellScope from '../cells/CellScope';
import CellMilestones from '../cells/CellMilestones';

import { AnvListContainer } from '../../../../components/shared/anv-list-data/AnvListContainer';
import { AnvListData } from '../../../../components/shared/anv-list-data/AnvListData';
import {
  AccessStore as AccessStoreClass,
  AnvButton,
  AnvDatepicker,
  AnvDialogTitle,
} from '@platform/front-lib';
import { TCommonDate } from '@platform/front-lib/dist/models';

interface IProps {
  entity: Record<string, any>;
  handleClose: (reloadList: boolean) => void;
  statuses?: any;
  minExpirationDate?: TCommonDate;
  maxExpirationDate?: TCommonDate;
  initialFocusedDate?: TCommonDate;
}

interface IValidation {
  maxDate?: Date | string | null;
  minDate?: Date | string | null;
  status?: string | null;
  denyForm: boolean;
}

const validationSchema = (
  fm: (arg: any, values?: any) => string,
  { maxDate, minDate, status, denyForm }: IValidation,
) => {
  const shape: Record<string, any> = {};

  if (status === REQUEST_ACCESS_TYPES.REVIEW) {
    shape.reviewComments = yup
      .string()
      .required(fm(validationsMessages.required()));

    const minDateValueArgs = validationsMessages.dateMinVal(
      formatWLocale(minDate),
    );
    const maxDateValueArgs = validationsMessages.dateMaxVal(
      formatWLocale(maxDate),
    );
    if (!denyForm) {
      shape.accessExpirationDate = yup
        .date()
        .typeError(fm(validationsMessages.date()))
        .nullable()
        .min(minDate, fm(minDateValueArgs[0], minDateValueArgs[1]))
        .max(maxDate, fm(maxDateValueArgs[0], maxDateValueArgs[1]))
        .required(fm(validationsMessages.required()));
    }
  }
  if (status === REQUEST_ACCESS_TYPES.APPROVED) {
    shape.reviewComments = yup
      .string()
      .required(fm(validationsMessages.required()));
  }

  return yup.object().shape(shape);
};

interface IFormsState {
  approveForm: boolean;
  denyForm: boolean;
  showForm: boolean;
}
interface IFormsStateConstants {
  APPROVE_FORM: 'approveForm';
  DENY_FORM: 'denyForm';
}

const FORM_FORMATS: IFormsStateConstants = {
  APPROVE_FORM: 'approveForm',
  DENY_FORM: 'denyForm',
};

const generateList = ({ entity, locale }: any) => {
  if (!entity) {
    return [];
  }

  let list = [
    {
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.MODULE"
          defaultMessage="Module"
        />
      ),
      value:
        getNameByLangNullable(entity?.anvilarModule, 'name', locale) ||
        entity?.anvilarModule?.moduleCode ||
        null,
    },
    {
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.ACCESS_SCOPE"
          defaultMessage="Access Scope"
        />
      ),
      value: getNameByLangNullable(
        entity?.accessScopeRef,
        'description',
        locale,
      ),
    },
    {
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.SCOPE"
          defaultMessage="Scope"
        />
      ),
      value: <CellScope entity={entity} />,
    },
    {
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.REQUESTED_BY"
          defaultMessage="Requested By"
        />
      ),
      value: (
        <>
          {entity?.requestedByRef?.lastName}&nbsp;
          {entity?.requestedByRef?.firstName}
        </>
      ),
    },
  ];

  if (entity?.requestedByRef?.email) {
    list.push({
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.EMAIL"
          defaultMessage="Email"
        />
      ),
      value: entity.requestedByRef.email,
    });
  }

  list = [
    ...list,
    {
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.REASON_FOR_REQUEST"
          defaultMessage="Reason for request"
        />
      ),
      value: entity?.reasonForRequest,
    },
    {
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.MILESTONES"
          defaultMessage="Milestones"
        />
      ),
      value: <CellMilestones entity={entity} />,
    },
  ];

  if (entity?.accessExpirationDate) {
    list.push({
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.EXPIRATION"
          defaultMessage="Expiration"
        />
      ),
      value: formatWLocale(entity?.accessExpirationDate),
    });
  }

  if (entity?.reviewedByRef) {
    list.push({
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.REVIEWED_BY"
          defaultMessage="Reviewed By"
        />
      ),
      value: (
        <>
          {' '}
          {entity?.reviewedByRef?.lastName}&nbsp;
          {entity?.reviewedByRef?.firstName}
        </>
      ),
    });
  }

  if (entity?.reviewComments) {
    list.push({
      label: (
        <FormattedMessage
          id="COMMON.ACCESS.LABEL.REVIEW_COMMENTS"
          defaultMessage="Review Comments"
        />
      ),
      value: entity?.reviewComments,
    });
  }

  list.push({
    label: (
      <FormattedMessage
        id="COMMON.ACCESS.LABEL.STATUS"
        defaultMessage="Status"
      />
    ),
    value: getNameByLangNullable(entity?.requestStatusRef, 'caption', locale),
  });

  return list;
};

const AccessStore = new AccessStoreClass();

const AccessRequestModal: React.FC<IProps> = ({
  entity,
  handleClose,
  statuses,
  minExpirationDate,
  maxExpirationDate,
  initialFocusedDate,
}) => {
  const { locale, formatMessage } = useIntl();
  const classes = useStyles();

  const [openForm, setOpenForm] = React.useState<IFormsState>({
    approveForm: false,
    denyForm: false,
    showForm: false,
  });

  const toggleForms = (formName: 'approveForm' | 'denyForm') => {
    setOpenForm((prevState) => ({
      ...prevState,
      [formName]: !prevState[formName],
      showForm: !prevState.showForm,
    }));
  };

  const {
    updateAccessRequestToFinish,
    updateAccessRequestToRevoke,
  } = AccessStore;

  const [reviewCommentsState, setReviewComments] = React.useState('');
  const handleChangeReviewComments = (ev: any) => {
    setReviewComments(ev.target.value);
  };

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(
      validationSchema(formatMessage, {
        status: entity?.requestStatus,
        maxDate: maxExpirationDate,
        minDate: minExpirationDate,
        denyForm: openForm.denyForm,
      }),
    ),
    defaultValues: {
      accessExpirationDate: entity?.accessExpirationDate || initialFocusedDate,
      reviewComments: '',
    },
  });

  if (!entity) {
    return null;
  }

  const onSubmit = (status?: string) => (data: any) => {
    if (status && Object.keys(REQUEST_ACCESS_TYPES).indexOf(status) > -1) {
      if (REQUEST_ACCESS_TYPES.REVOKED === status) {
        updateAccessRequestToRevoke(
          {
            id: entity._id,
            reviewComments: data?.reviewComments || '',
          },
          {
            successFunc: () => {
              handleClose(true);
            },
          },
        );
        return;
      }

      return updateAccessRequestToFinish(
        {
          id: entity._id,
          status,
          reviewComments: data?.reviewComments,
          accessExpirationDate: data.accessExpirationDate,
        },
        {
          successFunc: () => {
            handleClose(true);
          },
        },
      );
    } else {
      return null;
    }
  };

  const formHelper = (field: string) => {
    return formErrorHelper(field, errors, {});
  };

  const buttonLabel = {
    REVOKED:
      getNameByLangNullable(
        getItemById(statuses, REQUEST_ACCESS_TYPES.REVOKED),
        'actionButtonLabel',
        locale,
      ) || getItemById(statuses, REQUEST_ACCESS_TYPES.REVOKED)?.code,
    DENY:
      getNameByLangNullable(
        getItemById(statuses, REQUEST_ACCESS_TYPES.DENIED),
        'actionButtonLabel',
        locale,
      ) || getItemById(statuses, REQUEST_ACCESS_TYPES.DENIED)?.code,
    APPROVED:
      getNameByLangNullable(
        getItemById(statuses, REQUEST_ACCESS_TYPES.APPROVED),
        'actionButtonLabel',
        locale,
      ) || getItemById(statuses, REQUEST_ACCESS_TYPES.APPROVED)?.code,
    REVIEW:
      getNameByLangNullable(
        getItemById(statuses, REQUEST_ACCESS_TYPES.REVIEW),
        'actionButtonLabel',
        locale,
      ) || getItemById(statuses, REQUEST_ACCESS_TYPES.REVIEW)?.code,
  };

  const dataList = generateList({ entity, locale });

  return (
    <AnvDialog open={true} handleClose={() => handleClose(false)}>
      <AnvDialogTitle>
        <FormattedMessage
          id="COMMON.ACCESS.VIEW.TITLE"
          defaultMessage="Access Request"
        />
      </AnvDialogTitle>

      <AnvListContainer>
        {dataList.map((item) => {
          return (
            <AnvListData
              labelMd={3}
              label={item.label}
              value={item.value}
              direction={'row'}
            />
          );
        })}
      </AnvListContainer>

      <div className={classes.footerActions}>
        {entity?.requestStatus === REQUEST_ACCESS_TYPES.REVIEW && (
          <>
            <Collapse in={openForm.showForm}>
              <form
                noValidate
                onSubmit={
                  openForm.approveForm
                    ? handleSubmit(onSubmit(REQUEST_ACCESS_TYPES.APPROVED))
                    : handleSubmit(onSubmit(REQUEST_ACCESS_TYPES.DENIED))
                }
              >
                <Controller
                  name="reviewComments"
                  defaultValue={''}
                  as={
                    <TextField
                      className={classes.reviewCommentControl}
                      variant={'filled'}
                      multiline
                      rows={3}
                      fullWidth
                      required
                      label={
                        openForm.approveForm ? (
                          <FormattedMessage
                            id="COMMON.ACCESS.ACTION.REVIEW_COMMENT_APPROVE"
                            defaultMessage="Reason for approving"
                          />
                        ) : (
                          <FormattedMessage
                            id="COMMON.ACCESS.ACTION.REVIEW_COMMENT_DENY"
                            defaultMessage="Reason for denial"
                          />
                        )
                      }
                      id={'reviewComments'}
                      name={'reviewComments'}
                      value={reviewCommentsState}
                      onChange={handleChangeReviewComments}
                      helperText={formHelper('reviewComments')}
                      error={!!formHelper('reviewComments')}
                    />
                  }
                  control={control}
                />
                {!openForm.denyForm && (
                  <AnvDatepicker
                    className={classes.reviewCommentControl}
                    control={control}
                    label={
                      <FormattedMessage
                        id="COMMON.ACCESS.LABEL.ACCESS_EXPIRATION_DATE"
                        defaultMessage="Access Expiration Date"
                      />
                    }
                    name="accessExpirationDate"
                    required
                    initialFocusedDate={initialFocusedDate}
                    minDate={minExpirationDate}
                    maxDate={maxExpirationDate}
                    helperText={formHelper('accessExpirationDate')}
                    error={!!formHelper('accessExpirationDate')}
                  />
                )}

                <Grid container spacing={1} direction={'column'}>
                  <Grid item>
                    <AnvButton
                      variant={'contained'}
                      customColor={'primary'}
                      fullWidth
                      type={'submit'}
                    >
                      <FormattedMessage
                        id="APP.SUBMIT"
                        defaultMessage="Submit"
                      />
                    </AnvButton>
                  </Grid>
                  <Grid item>
                    <AnvButton
                      variant={'outlined'}
                      customColor={'muted'}
                      fullWidth
                      onClick={() => {
                        if (openForm.approveForm) {
                          toggleForms(FORM_FORMATS.APPROVE_FORM);
                        }
                        if (openForm.denyForm) {
                          toggleForms(FORM_FORMATS.DENY_FORM);
                        }
                      }}
                    >
                      <FormattedMessage
                        id="APP.CANCEL"
                        defaultMessage="Cancel"
                      />
                    </AnvButton>
                  </Grid>
                </Grid>
              </form>
            </Collapse>

            <Collapse in={!openForm.showForm}>
              <Grid container spacing={1} direction={'column'}>
                <Grid item>
                  <AnvButton
                    variant={'contained'}
                    customColor={'primary'}
                    fullWidth
                    onClick={() => {
                      toggleForms(FORM_FORMATS.APPROVE_FORM);
                    }}
                  >
                    {buttonLabel.APPROVED}
                  </AnvButton>
                </Grid>

                <Grid item>
                  <AnvButton
                    variant={'outlined'}
                    customColor={'error'}
                    fullWidth
                    onClick={() => {
                      toggleForms(FORM_FORMATS.DENY_FORM);
                    }}
                  >
                    {buttonLabel.DENY}
                  </AnvButton>
                </Grid>
              </Grid>
            </Collapse>
          </>
        )}

        {entity?.requestStatus === REQUEST_ACCESS_TYPES.APPROVED && (
          <>
            <Controller
              name="reviewComments"
              defaultValue={''}
              as={
                <TextField
                  className={classes.reviewCommentControl}
                  variant={'filled'}
                  multiline
                  rows={3}
                  fullWidth
                  required
                  label={
                    <FormattedMessage
                      id="COMMON.ACCESS.ACTION.REVIEW_COMMENT_REVOKE"
                      defaultMessage="Reason for revoke"
                    />
                  }
                  id={'reviewComments'}
                  name={'reviewComments'}
                  value={reviewCommentsState}
                  onChange={handleChangeReviewComments}
                  helperText={formHelper('reviewComments')}
                  error={!!formHelper('reviewComments')}
                />
              }
              control={control}
            />
            <AnvButton
              variant={'contained'}
              color={'primary'}
              fullWidth
              onClick={handleSubmit(onSubmit(REQUEST_ACCESS_TYPES.REVOKED))}
            >
              {buttonLabel.REVOKED}
            </AnvButton>
          </>
        )}
      </div>
    </AnvDialog>
  );
};

export default AccessRequestModal;
