import { AnvButton, AnvButtonGroup } from '@platform/front-lib';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import { USER_DETAILS_TABS } from '../../../constants';
import { IUserViewDetailsParams } from '../../../models';
import { useTabsStyles } from './tabs.styles';

interface IUserDetailsTabs {}

export const UserDetailsTabs: React.FC<IUserDetailsTabs> = observer(() => {
  const tabsClasses = useTabsStyles();

  const { formatMessage } = useIntl();

  const { id, tab } = useParams<IUserViewDetailsParams>();

  const [currentFilterTab, setCurrentFilterTab] = React.useState<string>(tab);

  React.useEffect(() => {
    if (!!tab && tab !== currentFilterTab) {
      setCurrentFilterTab(tab);
    }
  }, [tab, currentFilterTab]);

  const changeStatusFilter = (statusTab: string) => {
    setCurrentFilterTab(statusTab);
  };

  return (
    <AnvButtonGroup
      size={'small'}
      color={'primary'}
      disableElevation
      className={tabsClasses.container}
    >
      {Object.entries(USER_DETAILS_TABS(id)).map(([tabKey, tabValue]) => {
        return (
          <AnvButton
            key={`${tabKey}`}
            //@ts-ignore
            component={NavLink}
            to={tabValue.to}
            data-test={`ttt-${tabKey}`}
            variant={tabKey === currentFilterTab ? 'contained' : 'outlined'}
            inactive={tabKey === currentFilterTab}
            size={'small'}
            onClick={() => {
              // @ts-ignore
              changeStatusFilter(tabKey);
            }}
          >
            {formatMessage(tabValue.fm)}
          </AnvButton>
        );
      })}
    </AnvButtonGroup>
  );
});
