import { action, makeAutoObservable, observable } from 'mobx';
import { IPagination } from '../_helpers/interfaces';
import { defaultPagination } from '../_helpers/defaults';
import InvitationsService from '../../service/invitations';
import { AnvUtils } from '@platform/front-lib';

// interface IPayloadDelete {
//   _id: string;
// }

//todo user interface
class InvitationsStore {
  @observable
  error: Record<string, any> | null | string = null;
  @observable
  dataMap: Record<string, Record<string, any>> = {};
  @observable
  data: Record<string, any>[] | null = null;
  @observable
  pagination: IPagination = defaultPagination;
  @observable
  sort: string = '';
  @observable
  filter: any[] | Record<string, any> = {};

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @action
  private reset() {
    this.data = null;
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  @action
  getEntities = async (payload: { [index: string]: any }) => {
    this.setLoading();

    // todo module
    const [error, response] = await InvitationsService.getAll(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      const { docs, distinct, ...pagination } = response;
      this.pagination = pagination;
      this.data = docs || [];
      this.data?.forEach?.((item) => {
        this.dataMap[item._id] = item;
      });
    });
  };
  // @action
  // delete = async (payload: IPayloadDelete, meta: IActionMeta) => {
  //   this.setLoading();

  //   // todo module
  //   const [error, response] = await UserService.deleteOne(payload);

  //   if (error || response?.error) {
  //     return this.setError(() => {
  //       this.error = error || response?.error;
  //     });
  //   }

  //   this.setLoaded(() => {
  //     meta.successFunc?.();
  //   });
  // };

  @action
  setPage = (page: number, { history }: Record<string, any>) => {
    if (history) {
      AnvUtils.historyPushPagination({ payload: { page }, history });
    }

    this.pagination.page = page;
  };
  @action
  setSort = (sort: string, { history }: Record<string, any>) => {
    if (history) {
      AnvUtils.historyPushPagination({ payload: { sort }, history });
    }

    this.sort = sort;
  };
  @action
  setFilter = (
    filter: any[] | Record<string, any>,
    { history, ignorePage }: Record<string, any>,
  ) => {
    let filterParam = AnvUtils.preparePaginationFilterData(filter, {
      ignorePage,
    });
    if (history) {
      AnvUtils.historyPushPagination({
        payload: filterParam,
        history,
        isFilters: true,
      });
    }

    this.filter = filter;
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isSaving = false;
    this.isError = true;

    func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isSaving = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor() {
    makeAutoObservable(this);
  }
}
export default new InvitationsStore();
