const passwordStrength = 'To weak password';

const lowercase = 'abcdefghijkmnpqrstuvwxyz';
const uppercase = 'ABCDEFGHJKLMNPQRSTUVWXYZ';
const numbers = '0123456789';
const specials = '@#$%?!*&.';

export const validatePassword = (
  value: string | null,
  { nullable = false } = {}
) => {
  if (!value) {
    if (nullable) {
      return true;
    }

    return passwordStrength;
  }

  if (!/((?=.*[a-z])(?=.*[A-Z]))+/.test(value)) {
    return passwordStrength;
  }

  if (!/([^a-zA-Z]|[0-9])+/.test(value)) {
    return passwordStrength;
  }

  if (!/([@#$%?!*&.])+/.test(value)) {
    return passwordStrength;
  }

  if (value.length < 8) {
    return passwordStrength;
  }

  return true;
};

export const generatePassword = () => {
  let isValid = false;

  let password = '';
  while (!isValid) {
    password = generateString();
    isValid = validatePassword(password) === true;
  }

  return password;
};

export const generateString = () => {
  const all = specials + lowercase + uppercase + numbers;

  let password = '';
  password += pick(specials, { min: 1 });
  password += pick(lowercase, { min: 1 });
  password += pick(uppercase, { min: 1 });
  password += pick(numbers, { min: 1 });
  password += pick(all, { min: 4, max: 6 });

  password = shuffle(password);

  return password;
};

const pick = (string: string, { min, max }: { min: number; max?: number }) => {
  let n;
  let chars = '';

  if (typeof max === 'undefined') {
    n = min;
  } else {
    n = min + Math.floor(Math.random() * (max - min + 1));
  }

  for (let i = 0; i < n; i++) {
    chars += string.charAt(Math.floor(Math.random() * string.length));
  }

  return chars;
};

const shuffle = (string: string) => {
  const array = string.split('');
  let tmp,
    current,
    top = array.length;

  if (top)
    while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }

  return array.join('');
};
