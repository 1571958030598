import { CancelToken } from 'axios';
import { ApiServiceCommon } from '@platform/front-lib';
import type { IGetList } from '@platform/front-lib/dist/service/interfaces';

export class UsersAccessRequestService extends ApiServiceCommon {
  async getList(params: IGetList, source: { token?: CancelToken }) {
    return this.axiosCatch(
      this.axios.get(
        `${this.apiUrl}/organizations/${params.entityId}/${this.collection}`,
        {
          params,
          cancelToken: source.token,
        },
      ),
    );
  }
}
