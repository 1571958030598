import { CancelToken } from 'axios';
import { AnvUtils, axiosInstance } from '@platform/front-lib';
import type { IGetList } from '@platform/front-lib/dist/service/interfaces';

const axios = axiosInstance;

export interface IGetOneFormTemplate {
  id: string;
  entityId?: string;
  subId?: string;
}

export interface IDeleteOne extends IGetOneFormTemplate {}

export interface IGetListFormTemplates extends IGetList {
  entityId: string;
  subId?: string;
}
export interface ICreateOneFormTemplatePayload {
  entityId: string;
  subId?: string;
  data: any;
}
export interface IUpdateOneFormTempalatePayload
  extends ICreateOneFormTemplatePayload {
  id: string;
  entityId: string;
  subId?: string;
  data: any;
}

export class FormsEntityService {
  tool = '';

  private get apiUrl() {
    const toolApi = process.env.REACT_APP_API_TOOL_ENDPOINT;

    return toolApi + `/${this.tool}/forms`;
  }

  private getApiUrl({
    entityId,
    subId,
    id,
  }: {
    entityId: string;
    subId?: string;
    id?: string;
  }): string {
    let url = `${this.apiUrl}/${entityId}`;

    if (subId) {
      url += `/${subId}`;
    }

    // url += '/forms';

    if (id) {
      url += `/${id}`;
    }

    return url;
  }

  async getList(
    payload: IGetListFormTemplates,
    source: { token?: CancelToken },
  ) {
    const { id, entityId, subId, ...params } = payload;

    const url = this.getApiUrl({ id, entityId, subId });

    // `${this.apiUrl}/${params.templateId}/forms`
    return AnvUtils.axiosCatch(
      axios.get(url, {
        params,
        cancelToken: source.token,
      }),
    );
  }

  async getOne({ id, entityId, subId, ...params }: IGetOneFormTemplate) {
    // const url = this.getApiUrl({ id, entityId, subId: subId });

    let url = `${this.apiUrl}/get-one/${id}`;

    // `${this.apiUrl}/${templateId}/forms/${id}`
    return AnvUtils.axiosCatch(axios.get(url, { params }));
  }

  async createOne({ entityId, subId, data }: ICreateOneFormTemplatePayload) {
    // const url = this.getApiUrl({ entityId: entityId, subId: subId });

    // `${this.apiUrl}/${templateId}/forms`
    return AnvUtils.axiosCatch(axios.post(this.apiUrl, data, {}));
  }

  async updateOne({
    entityId,
    subId,
    id,
    data,
  }: IUpdateOneFormTempalatePayload) {
    // const url = this.getApiUrl({ id, entityId: entityId, subId: subId });

    // `${this.apiUrl}/${templateId}/forms/${id}`
    return AnvUtils.axiosCatch(axios.patch(this.apiUrl, data, {}));
  }

  // @deprecated
  async updatePositions({
    templateId,
    taskId,
    data,
  }: {
    templateId: string;
    taskId?: string;
    data: { _id: string; position: number }[];
  }) {
    const url = this.getApiUrl({ entityId: templateId, subId: taskId });

    // `${this.apiUrl}/${templateId}/forms/update-positions`
    return AnvUtils.axiosCatch(axios.put(url, { forms: data }, {}));
  }

  async deleteOne({ entityId, subId, id }: IDeleteOne) {
    // const url = this.getApiUrl({ id, entityId: entityId, subId: subId });

    // `${this.apiUrl}/${templateId}/forms/${id}`
    return AnvUtils.axiosCatch(axios.delete(this.apiUrl, {}));
  }

  constructor(tool: string) {
    this.tool = tool;
  }
}
