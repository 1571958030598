import React, { useEffect } from 'react';
import axios from 'axios';

import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { useStores } from 'src/stores/root-store';
import LocalStorage from 'src/stores/_helpers/storage';

// import { IsLoadingComponent } from "src/components/layout/loading";

import browserLocale from './getLanguage';
import { usePrevious } from 'src/hooks';

import { envConst } from '../constants';

export const defaultLanguage = 'en';

// Fallback to English before defaultMessage
const messages = {
  en: {},
  es: {},
};

export const supportedLanguages = Object.keys(messages);

const isLanguageSupport = (
  lang: string | undefined | null,
  languages: Record<string, any>[] | null,
) => {
  if (languages) {
    return lang && languages.map((item) => item.code).indexOf(lang) > -1;
  } else {
    return lang && supportedLanguages.indexOf(lang) > -1;
  }
};

export const IntlProvider: React.FC = observer(({ children }) => {
  const {
    authStores: { profile, isLoggedIn },
    uiStores: { languages, setLanguages },
  } = useStores();

  let locale: string | undefined = defaultLanguage;

  if (isLoggedIn) {
    locale =
      profile?.locale || LocalStorage?.get('ui_locale') || defaultLanguage;
  } else {
    locale = LocalStorage?.get('ui_locale') || defaultLanguage;
  }

  const [languagesFetching, setLanguagesFetching] = React.useState<boolean>(
    false,
  );

  const isLanguages = !!languages;

  const [translationsFetching, setTranslationsFetching] = React.useState(false);
  const [localStorageUpdated, setLocalStorageUpdated] = React.useState(false);

  const [languagesError, setLanguagesError] = React.useState(false);
  const [langState, setLangState] = React.useState<Record<string, any>>({});

  const prevIsLoggedIn = usePrevious(isLoggedIn);

  useEffect(() => {
    if (isLanguages) {
      updateCurrentLanguage();
    }
    // eslint-disable-next-line
  }, [isLanguages]);

  useEffect(() => {
    if (isLanguages) {
      updateCurrentLanguage();
    }

    if (
      isLanguages &&
      isLoggedIn &&
      !prevIsLoggedIn &&
      !localStorageUpdated &&
      !translationsFetching
    ) {
      updateLocalStorage();
    }
    // eslint-disable-next-line
  }, [prevIsLoggedIn, isLoggedIn, translationsFetching, localStorageUpdated]);

  const getAllLanguages = async () => {
    if (!languagesError) {
      try {
        const url = `${envConst.apiUrl}/meta/languages`;

        const langs = await axios.get(url);

        if (langs?.data) {
          setLanguages(langs?.data);
        }
      } catch (e) {
        setLanguagesError(true);
        console.log('--e--', { e });
      }
      setLanguagesFetching(false);
    }
  };

  if (!languages && !languagesFetching) {
    setLanguagesFetching(true);
    getAllLanguages();
  }

  const updateLocalStorage = async () => {
    setTranslationsFetching(true);
    const url = `${envConst.apiUrl}/meta/translations`;

    const translationsRes = await axios.get(url);
    const translations = translationsRes?.data;

    const newState: Record<string, any> = {};
    languages?.forEach(({ code }) => {
      if (translations[code]) {
        let messages = translations[code];

        LocalStorage.setJSON(`locale_${code}`, messages);

        newState[code] = messages;
      } else {
        LocalStorage.setJSON(`locale_${code}`, {});
      }
    });

    setLocalStorageUpdated(true);

    setLangState((state) => ({
      ...state,
      ...newState,
    }));

    setTranslationsFetching(false);
    updateCurrentLanguage();
  };

  const updateCurrentLanguage = () => {
    const langCode = _getCurrentLanguage(locale);
    if (
      (document && langCode && document.documentElement.lang !== langCode) ||
      !langState[langCode]
    ) {
      LocalStorage.set('ui_locale', langCode);

      if (!langState[langCode]) {
        try {
          const newState: Record<string, any> = {};
          let messages = LocalStorage.getJSON(`locale_${langCode}`);

          // load if local storage empty
          if (!messages) {
            updateLocalStorage();
            return;
          }

          newState[langCode] = messages;

          if (langCode !== defaultLanguage && !langState[defaultLanguage]) {
            let messagesDefault = LocalStorage.getJSON(
              `locale_${defaultLanguage}`,
            );

            newState[defaultLanguage] = messagesDefault;

            if (!messagesDefault) {
              updateLocalStorage();
              return;
            }
          }

          setLangState((state) => ({
            ...state,
            ...newState,
          }));
          return;
        } catch (e) {}
      }

      document.documentElement.lang = langCode;
    }
  };

  const _getCurrentLanguage = (definedLocale: string | undefined): string => {
    if (definedLocale && isLanguageSupport(definedLocale, languages)) {
      return definedLocale;
    }

    if (locale && isLanguageSupport(locale, languages)) {
      return locale;
    }

    const localeStorageLocale = LocalStorage.get('ui_locale');

    if (
      localeStorageLocale &&
      isLanguageSupport(localeStorageLocale, languages)
    ) {
      return localeStorageLocale;
    }

    if (isLanguageSupport(browserLocale, languages)) {
      return browserLocale;
    }

    return defaultLanguage || 'en';
  };

  const lang = _getCurrentLanguage('');
  // date-fns
  window.__localeId__ = lang;

  let actualMessages = langState?.[lang] || null;
  // const isLoadingProfile = isLoggedIn && !locale;

  if (lang !== defaultLanguage) {
    actualMessages = Object.assign(
      {},
      langState?.[defaultLanguage] || {},
      actualMessages,
    );
  }

  return (
    <ReactIntlProvider
      locale={lang}
      messages={actualMessages}
      defaultLocale={defaultLanguage}
    >
      {/* <IsLoadingComponent isLoading={isLoading} /> */}
      {children}
      {/*{isLoading ? <IsLoadingComponent isLoading={isLoading} /> : children}*/}
    </ReactIntlProvider>
  );
});
