import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ROUTES } from 'src/constants';
import { useStores } from 'src/stores/root-store';
import IsLoadingComponent from '../components/layout/loading';

interface IProps {
  // component: typeof React.Component;
  component: React.FC;
  Container?: typeof React.Component | React.FC<any>;
  authFallback?: typeof React.Component;
  isAdminRoute?: boolean;
  isOperatorRoute?: boolean;
  path?: string;
  module?: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<IProps> = observer(
  ({
    component: Component,
    authFallback: AuthFallback,
    isAdminRoute,
    isOperatorRoute,
    Container,
    module,
    ...rest
  }) => {
    const {
      authStores: { isLoggedIn, isFetching },
    } = useStores();

    if (isFetching ) {

      return <IsLoadingComponent isLoading />;
    }

    let Wrapper = Container || null;

    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? (
            Wrapper ? (
              <Wrapper module={module}>
                <Component {...props} {...rest} />
              </Wrapper>
            ) : (
              <Component {...props} {...rest} />
            )
          ) : AuthFallback ? (
            <AuthFallback {...props} />
          ) : (
            <Redirect to={ROUTES.AUTH_SIGN_IN} />
          )
        }
      />
    );
  },
);

export default PrivateRoute;
