import React from 'react';
import { darken, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { observer } from 'mobx-react-lite';

import { createMuiTheme } from '@material-ui/core/styles';

import { themePlain } from '../styles/theme';
import { useStores } from '../stores/root-store';
import { palette } from '../styles/palette';
import IsLoadingComponent from '../components/layout/loading';

interface IProps {}

const AnvMuiThemeProvider: React.FC<IProps> = observer(({ children }) => {
  const {
    metaStores: {
      generalSettingStore: {
        generalTheme,
        getGeneralTheme,
        isLoadedGeneralTheme,
      },
    },
  } = useStores();

  React.useEffect(() => {
    if (!isLoadedGeneralTheme) {
      getGeneralTheme({ prepared: true });
    }
  }, [getGeneralTheme, isLoadedGeneralTheme]);

  if (generalTheme && isLoadedGeneralTheme) {
    palette.generalTheme = {
      ...palette.generalTheme,
      ...generalTheme,
    };

    palette.brand = {
      ...palette.brand,

      main: generalTheme.button.buttonBackground,
      light: generalTheme.layout.headerBackground,
      dark: darken(generalTheme.button.buttonBackground, 0.5),
    };
  }

  // @ts-ignore
  const newTheme = createMuiTheme(themePlain(palette));

  return (
    <MuiThemeProvider theme={newTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {!isLoadedGeneralTheme ? <IsLoadingComponent isLoading /> : children}
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
});

export default AnvMuiThemeProvider;
