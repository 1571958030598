import { AnvUtils, axiosInstance } from '@platform/front-lib';

import type {
  IFormsCopyTemplatePayload,
  IFormsCreatePayload,
  IFormsDeletePayload,
  IFormsPublishPayload,
  IFormsUnPublishPayload,
  IFormsUpdatePayload,
} from '@platform/front-lib-forms/dist/forms/forms-lib/builder.store';
import type { IIdCommonPayloadForms } from '@platform/front-lib-forms/dist/forms/forms-lib/services/interfaces';

import {
  helperFormsDataPatchDown,
  helperFormsDataPostDown,
} from '@platform/front-lib-forms';

const axios = axiosInstance;

const { axiosCatch, getCookieToken } = AnvUtils;

export class CFormsTemplateServiceCommon {
  readonly apiUrl: string;

  constructor({ tool = 'complaint' }: { tool?: string }) {
    this.apiUrl = process.env.REACT_APP_API_TOOL_ENDPOINT;

    this.apiUrl += `/${tool}/forms`;
  }

  private getApiUrl({
    entityId,
    id,
  }: {
    entityId: string;
    subId?: string;
    id?: string;
  }): string {
    let url = this.apiUrl;

    if (entityId) {
      url += `/${entityId}`;
    }

    // url += '/forms';

    if (id) {
      url += `/${id}`;
    }

    return url;
  }

  async getList(data?: any, sourceCancelToken?: any) {
    return this.getAll(data, sourceCancelToken);
  }
  async getAll(data?: any, sourceCancelToken?: any) {
    const url = this.getApiUrl(data);

    // `${this.apiUrl}/forms`
    return axiosCatch(
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${getCookieToken()}`,
          params: data,
          cancelToken: sourceCancelToken?.token,
        },
      }),
    );
  }

  async getOne({ entityId, subId, id }: IIdCommonPayloadForms) {
    // const url = this.getApiUrl({ entityId, subId, id });
    const url = `${this.apiUrl}/get-one/${id}`;

    // `${this.apiUrl}/${entityId}/forms/${id}`
    return axiosCatch(
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${getCookieToken()}`,
        },
      }),
    );
  }

  async getOneParent({ entityId, subId, id }: IIdCommonPayloadForms) {
    // const url = this.getApiUrl({ entityId, subId, id });
    const url = `${this.apiUrl}/get-one/${id}/parent`;

    // `${this.apiUrl}/${entityId}/forms/${id}`
    return axiosCatch(
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${getCookieToken()}`,
        },
      }),
    );
  }

  // temp
  getOneUnauth({ id }: IIdCommonPayloadForms) {
    return axiosCatch(axios.get(`${this.apiUrl}/templates/${id}/unauth`, {}));
  }

  async getOneSystem({ id, templateId, subId }: IIdCommonPayloadForms) {
    const url = this.getApiUrl({ entityId: templateId, subId, id }) + '/system';

    // `${this.apiUrl}/${templateId}/forms/${id}/system`
    return axiosCatch(
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${getCookieToken()}`,
        },
      }),
    );
  }

  // async create(payload: Omit<IFormsTemplate, '_id'>) {
  async create(payload: IFormsCreatePayload & { entityId: string }) {
    const formData = helperFormsDataPostDown(payload.data);

    // const url = this.getApiUrl(payload);

    // `${this.apiUrl}/${payload.entityId}/forms/`
    return axiosCatch(axios.post(this.apiUrl, formData, {}));
  }

  async update(
    payload: IFormsUpdatePayload & { id: string; entityId: string },
  ) {
    const {
      data: { _id, ...data },
    } = payload;

    const formData = helperFormsDataPatchDown(data);

    // const url = this.getApiUrl({ ...payload, id: payload.id || _id });

    return axiosCatch(
      axios.patch(this.apiUrl + `/${payload.id || _id}`, formData, {}),
    );
  }

  async copyTemplate(
    payload: IFormsCopyTemplatePayload & { id: string; entityId: string },
  ) {
    // const url = this.getApiUrl(payload) + '/copy';
    const url = this.apiUrl + `/${payload.id}/copy`;

    return axiosCatch(axios.post(url, {}));
  }

  async publish({
    id,
    entityId,
    subId,
  }: IFormsPublishPayload & { id: string; entityId: string }) {
    const url =
      this.getApiUrl({
        id,
        entityId,
        subId,
      }) + '/publish';

    // `${this.apiUrl}/${entityId}/forms/${id}/publish`
    return axiosCatch(axios.patch(url));
  }

  async unpublish({
    id,
    entityId,
    subId,
  }: IFormsUnPublishPayload & { id: string; entityId: string }) {
    const url =
      this.getApiUrl({
        id,
        entityId,
        subId,
      }) + '/publish';

    // `${this.apiUrl}/${entityId}/forms/${id}/unpublish`
    return axiosCatch(axios.patch(url));
  }

  async delete({
    id,
    entityId,
    subId,
  }: IFormsDeletePayload & { id: string; entityId: string }) {
    // const url =
    //   this.getApiUrl({
    //     id,
    //     entityId,
    //     subId,
    //   }) + '/publish';

    // `${this.apiUrl}/${entityId}/forms/${id}`
    return axiosCatch(axios.delete(`${this.apiUrl}/${id}`, {}));
  }

  async archive(payload: IFormsPublishPayload): Promise<any> {
    return;
  }
}
