import React from 'react';
import { observer } from 'mobx-react-lite';
import { generatePath, NavLink } from 'react-router-dom';

import {
  AnvIcon,
  AnvMenu,
  AnvPaginationMui,
  AnvTableTitle,
  AnvTableWrapper,
  useCommonTable,
  useMenu,
  useModal,
  AnvBreadcrumbs,
  AnvTable,
  AnvUtils,
  Constants,
  IsLoadingComponent,
} from '@platform/front-lib';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuItem } from '@material-ui/core';

import { useStores } from '../../stores/root-store';

import {
  PAGINATION_LIMIT,
  ROUTES,
  USER_DETAILS_TABS_PARAMS,
} from '../../constants';
import { userColumns } from './columns';
import { UsersModalPassword } from './change-password';
import { UsersModalActivation } from './activate-account';

interface IProps {}

export const defaultFilterState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

const Users: React.FC<IProps> = observer(() => {
  const { locale, formatMessage } = useIntl();
  const { closeMenu, handleClose: handleCloseMenu } = useMenu();

  const {
    usersStore: {
      getEntities: getAllUsers,
      data: users,
      dataMap,
      isLoaded,
      isFetching,
      pagination,
      setPage,
      setFilter,
      setSort,
    },
    metaStores,
  } = useStores();

  const {
    accountStatusesStore: {
      dataMap: accStatusesMap,
      data: accStatuses,
      getEntities: getAccStatuses,
      isLoaded: isLoadedAccStatuses,
    },
  } = metaStores || {};

  React.useEffect(() => {
    if (!isLoadedAccStatuses) {
      getAccStatuses({
        paging: { limit: 0 },
      });
    }
  }, [getAccStatuses, isLoadedAccStatuses]);

  const accStatusesOptions = React.useMemo(() => {
    return AnvUtils.prepareOptions(accStatuses || [], { locale });
  }, [locale, accStatuses]);

  const [userId, setUserId] = React.useState<null | string>(null);

  /** Change Pass */
  const {
    handleOpenModal: handleOpenModalPassword,
    isOpen: isOpenPassword,
    handleToggleModal: handleToggleModalPassword,
  } = useModal();

  const handleOpenChangePassword = (data: Record<string, any>) => {
    setUserId(data?.original?._id || data?._id);
    handleCloseMenu();
    handleOpenModalPassword();
  };

  /** Activation */
  const {
    handleOpenModal: handleOpenModalActivation,
    isOpen: isOpenActivation,
    handleToggleModal: handleToggleModalActivation,
  } = useModal();
  const handleOpenChangeActivation = (data: Record<string, any>) => {
    setUserId(data?.original?._id || data?._id);
    handleCloseMenu();
    handleOpenModalActivation();
  };

  const {
    querySort = 'lastName,firstName',
    // queryFilter,
    queryPage,
    filterState,
    memoQueryFilter,
    filterMemo,
    handleChangeFilterDate,
    handleChangeFilter,
    handleChangePhone,
    handleChangePage,
  } = useCommonTable({
    defaultFilterState,
    pagination,
    setPage,
    setFilter,
    setSort,

    storeSorting: '',
    storeFilter: Constants.mockArr,
  });

  React.useEffect(() => {
    getAllUsers({
      page: +queryPage,
      sort: querySort,
      filter: memoQueryFilter,
      limit: PAGINATION_LIMIT,
    });
  }, [getAllUsers, memoQueryFilter, queryPage, querySort]);

  const columns = React.useMemo(() => {
    return userColumns(locale, formatMessage, {
      accountStatus: accStatusesOptions,
      accountStatusMap: accStatusesMap,
    });
  }, [formatMessage, locale, accStatusesOptions, accStatusesMap]);

  const data = React.useMemo(() => {
    return users || [];
  }, [users]);

  return (
    <>
      <AnvBreadcrumbs
        isOperator
        items={[
          {
            component: (
              <>
                <AnvIcon size={24} icon={'user'} offset={1} />
                <FormattedMessage
                  tagName={'span'}
                  id="APP.COMMON.SUPPORT.DRAWER.USERS"
                  defaultMessage="Users"
                />
              </>
            ),
          },
        ]}
        moduleCode={''}
        tool={undefined}
      />
      <IsLoadingComponent isLoading={isFetching} />
      <AnvTableWrapper>
        <AnvTableTitle
          tableTitle={
            <FormattedMessage
              id="SUPPORT.USERS.LIST.LABEL"
              defaultMessage="Users"
            />
          }
        />

        <AnvTable
          filterState={filterState}
          handleChangeFilterDate={handleChangeFilterDate}
          handleChangeFilter={handleChangeFilter}
          handleChangePhone={handleChangePhone}
          //common
          data={data}
          columns={columns}
          querySort={querySort}
          setSort={setSort}
          TableActionComponent={({ data }: any) => (
            <>
              <AnvMenu closeMenu={closeMenu}>
                <MenuItem
                  component={NavLink}
                  to={generatePath(ROUTES.USER_DETAILS, {
                    id: data?._id,
                    tab: USER_DETAILS_TABS_PARAMS.INFO,
                  })}
                  title={formatMessage({
                    id: 'SUPPORT.USERS.ACTION.VIEW_DETAILS',
                    defaultMessage: 'View details',
                  })}
                >
                  <AnvIcon icon={'eyeOutline'} color={'inherit'} />
                  <span>
                    <FormattedMessage
                      id="SUPPORT.USERS.ACTION.VIEW_DETAILS"
                      defaultMessage="View details"
                    />
                  </span>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    handleOpenChangePassword(data);
                  }}
                >
                  <AnvIcon icon={'keyOutline'} />
                  {formatMessage({
                    id: 'SUPPORT.USERS.LIST.ACTION.CHANGE_PASSWORD',
                    defaultMessage: 'Change Password',
                  })}
                </MenuItem>

                {data?.registrationStatus === 0 && (
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      handleOpenChangeActivation(data);
                    }}
                  >
                    <AnvIcon icon={'toggleOn'} />
                    {formatMessage({
                      id: 'SUPPORT.USERS.LIST.ACTION.ACTIVATE_ACCOUNT',
                      defaultMessage: 'Activate Account',
                    })}
                  </MenuItem>
                )}
              </AnvMenu>
            </>
          )}
          filterMemo={filterMemo}
          setFilter={setFilter}
          isLoadedList={isLoaded}
          isShowTable={true}
        />

        <AnvPaginationMui
          pagination={pagination}
          totalPages={pagination?.totalPages}
          handleChangePage={handleChangePage}
          page={+queryPage}
        />
      </AnvTableWrapper>

      {isOpenPassword && userId && (
        <UsersModalPassword
          open={isOpenPassword}
          user={dataMap[userId]}
          handleClose={handleToggleModalPassword}
        />
      )}
      {isOpenActivation && userId && (
        <UsersModalActivation
          open={isOpenActivation}
          user={dataMap[userId]}
          handleClose={handleToggleModalActivation}
        />
      )}
    </>
  );
});

export default Users;
