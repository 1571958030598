import {
  IGetMyModulesAction,
  ILogInData,
  ISetLocale,
} from 'src/models/requests';
import { axiosCatch } from '../utils';
import { axiosInstance } from '@platform/front-lib';
import { envConst } from '../../constants';

const axios = axiosInstance;

class LogInService {
  private authUrl = process.env.REACT_APP_API_TOOL_ENDPOINT + '/auth';

  async logIn(data: ILogInData) {
    return axiosCatch(
      axios.post(`${envConst.apiAuthUrl}/auth/login-operator`, data, {
        withCredentials: true,
      }),
    );
  }

  async setLocale(data: ISetLocale) {
    return axiosCatch(axios.post(`${this.authUrl}/set-locale`, data, {}));
  }

  async getMyModules(data?: IGetMyModulesAction) {
    return axiosCatch(
      axios.post(`${this.authUrl}/get-my-modules`, data || {}, {}),
    );
  }

  async logOut() {
    return axiosCatch(axios.get(`${this.authUrl}/log-out-operator`));
  }
}

export default new LogInService();
