import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

const AnvHeaderLogo: React.FC<any> = ({
  // width = 186,
  // height = 40,
  isToolLogo,
}) => {
  const theme = useTheme();
  const { palette } = theme;
  const screenMD = useMediaQuery(theme.breakpoints.up('md'));

  const anvilarLogoTextColor = isToolLogo
    ? palette.brand.main
    : palette.generalTheme.logo?.textColor || '#114447';
  const anvilarLogoAnvilColor = isToolLogo
    ? palette.brand.dark
    : palette.generalTheme.logo?.iconColor || '#114447';
  const anvilarLogoUnderlineColor = isToolLogo
    ? palette.brand.dark
    : palette.generalTheme.logo?.iconColor;

  const logoSize = screenMD
    ? { width: 186, height: 40 }
    : { width: 139, height: 29 };
  return (
    <svg
      width={logoSize.width}
      height={logoSize.height}
      viewBox="0 0 287 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* TEXT */}
      <path
        d="M90 42.2399L102.755 2.60606H118.999L132.19 42.2399H121.724L111.477 9.66414L114.366 10.75H107.17L110.114 9.66414L100.139 42.2399H90ZM100.411 33.0644L103.464 24.8119H118.563L121.615 33.0644H100.411Z"
        fill={anvilarLogoTextColor}
      />
      <path
        d="M134.863 42.2399V12.2702H142.712V25.1376H142.276C142.276 22.0248 142.676 19.455 143.476 17.428C144.275 15.3649 145.456 13.8266 147.019 12.8131C148.618 11.7997 150.544 11.2929 152.797 11.2929H153.233C156.649 11.2929 159.283 12.4331 161.137 14.7134C163.026 16.9575 163.971 20.4141 163.971 25.0833V42.2399H154.05V24.8119C154.05 23.4365 153.632 22.3144 152.797 21.4457C151.997 20.5408 150.889 20.0884 149.472 20.0884C148.054 20.0884 146.91 20.5408 146.038 21.4457C145.202 22.3144 144.784 23.4726 144.784 24.9205V42.2399H134.863Z"
        fill={anvilarLogoTextColor}
      />
      <path
        d="M174.728 42.2399L166.116 12.2702H176.254L184.267 42.2399H174.728ZM177.89 42.2399V33.9874H187.538V42.2399H177.89ZM181.378 42.2399L188.192 12.2702H197.676L190.263 42.2399H181.378Z"
        fill={anvilarLogoTextColor}
      />
      <path
        d="M202.33 42.2399V12.2702H212.251V42.2399H202.33ZM198.514 19.4369V12.2702H212.251V19.4369H198.514ZM206.418 9.88132C204.601 9.88132 203.257 9.42887 202.385 8.52399C201.549 7.58291 201.131 6.38847 201.131 4.94066C201.131 3.49285 201.549 2.3165 202.385 1.41162C203.257 0.470539 204.583 0 206.364 0C208.181 0 209.507 0.470539 210.343 1.41162C211.179 2.3165 211.597 3.49285 211.597 4.94066C211.597 6.38847 211.179 7.58291 210.343 8.52399C209.507 9.42887 208.199 9.88132 206.418 9.88132Z"
        fill={anvilarLogoTextColor}
      />
      <path
        d="M218.617 42.2399V2.60606H228.538V42.2399H218.617ZM215.238 9.77273V2.60606H228.538V9.77273H215.238Z"
        fill={anvilarLogoTextColor}
      />
      <path
        d="M252.697 42.2399V33.4445H251.062V24.0518C251.062 22.6763 250.735 21.6629 250.081 21.0114C249.463 20.3237 248.428 19.9798 246.974 19.9798C246.283 19.9798 245.266 19.9979 243.921 20.0341C242.577 20.0703 241.16 20.1246 239.67 20.197C238.18 20.2694 236.817 20.3418 235.582 20.4141V12.1616C236.454 12.0892 237.489 12.0168 238.689 11.9444C239.888 11.8721 241.123 11.8178 242.395 11.7816C243.703 11.7454 244.903 11.7273 245.993 11.7273C249.082 11.7273 251.698 12.1797 253.842 13.0846C256.022 13.9895 257.676 15.3468 258.802 17.1566C259.965 18.9663 260.547 21.2647 260.547 24.0518V42.2399H252.697ZM243.158 43C241.051 43 239.179 42.62 237.544 41.8599C235.909 41.0998 234.619 40.0139 233.674 38.6023C232.765 37.1545 232.311 35.4352 232.311 33.4445C232.311 31.2003 232.911 29.3906 234.11 28.0152C235.309 26.6035 236.962 25.572 239.07 24.9205C241.178 24.2689 243.594 23.9432 246.32 23.9432H252.152V29.0467H246.32C244.975 29.0467 243.94 29.3906 243.213 30.0783C242.486 30.7298 242.123 31.6166 242.123 32.7386C242.123 33.8245 242.486 34.7113 243.213 35.399C243.94 36.0505 244.975 36.3763 246.32 36.3763C247.192 36.3763 247.955 36.2315 248.609 35.9419C249.3 35.6162 249.863 35.0732 250.299 34.3131C250.735 33.553 250.989 32.5034 251.062 31.1641L253.352 33.3359C253.17 35.399 252.661 37.1545 251.825 38.6023C251.026 40.0139 249.899 41.0998 248.446 41.8599C247.028 42.62 245.266 43 243.158 43Z"
        fill={anvilarLogoTextColor}
      />
      <path
        d="M265.813 42.2399V12.2702H273.662V25.3005H273.553C273.553 21.1019 274.425 17.8081 276.17 15.4192C277.95 13.0303 280.494 11.8359 283.801 11.8359H285V20.3056H282.711C280.494 20.3056 278.768 20.9028 277.532 22.0972C276.333 23.2917 275.733 25.0109 275.733 27.2551V42.2399H265.813Z"
        fill={anvilarLogoTextColor}
      />
      {/* TEXT */}
      {/* LOGO */}
      <path
        d="M60.9437 4.98124C60.9437 4.43824 60.498 3.99805 59.9483 3.99805H13.4328C12.8831 3.99805 12.4375 4.43824 12.4375 4.98124V6.49818H0.995357C0.445644 6.49818 0 6.93837 0 7.48137L3.79698e-05 11.372C3.79698e-05 17.0736 4.6792 21.6956 10.4512 21.6956H12.4375V23.0316C12.4422 23.5706 12.886 24.0061 13.4328 24.0061H59.9483C60.498 24.0061 60.9437 23.5659 60.9437 23.0229V21.6956H60.96C74.7112 21.6956 84.5411 12.9454 88.0029 8.64175C88.7324 7.73483 88.061 6.49818 86.8883 6.49818H60.9437V4.98124Z"
        fill={anvilarLogoAnvilColor}
      />
      <path
        d="M52.6911 25.2492C53.4776 31.5195 57.8294 34.1247 59.8851 35.0563C60.4871 35.3291 60.9221 35.9097 60.9221 36.5639V41.7834C60.9221 42.3264 60.4765 42.7666 59.9267 42.7666H41.805C41.805 40.5946 40.0224 39.3932 37.8236 39.3932H35.8328C33.634 39.3932 31.8514 40.5946 31.8514 42.7666H13.4463C12.8966 42.7666 12.4509 42.3264 12.4509 41.7834V36.5639C12.4509 35.9097 12.8859 35.3291 13.4879 35.0563C15.5436 34.1247 20.1154 31.5195 20.9018 25.2492H52.6911Z"
        fill={anvilarLogoAnvilColor}
      />
      {/* LOGO */}
      {/* UNDERLINE */}
      <path
        d="M2.1543 44.9207C2.1543 44.3259 2.63644 43.8438 3.2312 43.8438H285.302C285.897 43.8438 286.379 44.3259 286.379 44.9207C286.379 45.5154 285.897 45.9976 285.302 45.9976H3.23121C2.63645 45.9976 2.1543 45.5154 2.1543 44.9207Z"
        fill={anvilarLogoUnderlineColor}
        opacity="0.5"
      />
      {/* UNDERLINE */}
    </svg>
  );
};

export default AnvHeaderLogo;
