import React from 'react';
import { observer } from 'mobx-react-lite';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';

import {
  AnvButton,
  AnvModalInfo,
  AnvTextField,
  useModal,
} from '@platform/front-lib';

import { validationsMessages } from '../../../constants';
import { TFormatMessage } from '../../../@types/intl/intl';

import { formErrorHelper, validatePassword } from '../../../utils';

import useStylesCommon from '../styles';
import { useStores } from '../../../stores/root-store';
// import { passwordHelperText } from '../../auth/sign-up/SignUpFinish';
// import { IUpdatePassword } from '../../../stores/profile/security';
import IsLoadingComponent from '../../../components/layout/loading';
import { IUpdatePassword } from '../../../stores/profile-operator/security';

interface IProfileSecurityProps {}

const validationSchema = (fm: TFormatMessage) =>
  yup.object().shape({
    currentPassword: yup.string().required(fm(validationsMessages.required())),
    password: yup
      .string()
      .test(
        'passwordStrength',
        fm(validationsMessages.passwordStrength()),
        function () {
          return validatePassword(this.parent.password) === true;
        },
      )
      .required(fm(validationsMessages.required())),
    passwordRepeat: yup
      .string()
      .required(fm(validationsMessages.required()))
      .oneOf(
        [yup.ref('password'), ''],
        fm(validationsMessages.passwordRepeat()),
      ),
  });

const defaultValues = {
  currentPassword: '',
  password: '',
  passwordRepeat: '',
};
export const passwordHelperText = (
  <FormattedMessage
    id="LAUNCHPAD.VALIDATION.PASSWORD.STRENGTH"
    defaultMessage="Password must be at least 8 characters long with combination of at least one each of the following: upper case letters, lower cases letters, digits, and certain special characters such as @#$%?."
  />
);

export const ProfileSecurity: React.FC<IProfileSecurityProps> = observer(() => {
  const { formatMessage } = useIntl();
  const classes = useStylesCommon();
  const {
    profileOperatorStore: {
      profileSecurityStore: { error, isFetching, update },
    },
  } = useStores();

  const { control, errors, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema(formatMessage)),
    defaultValues,
  });

  /** modal */
  const { handleToggleModal, isOpen } = useModal();

  const onSubmit = (fields: IUpdatePassword) => {
    update(fields, {
      successFunc: () => {
        reset(defaultValues);
        handleToggleModal();
      },
    });
  };

  const handleDiscard = () => {
    reset(defaultValues);
  };

  const formDisabled = isFetching;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <IsLoadingComponent isLoading={isFetching} />

        <div className={classes.form}>
          <AnvTextField
            disabled={formDisabled}
            name={'currentPassword'}
            control={control}
            required
            id="currentPassword"
            type="password"
            label={
              <FormattedMessage
                id="LAUNCHPAD.PROFILE.SECURITY.LABEL.OLD_PASSWORD"
                defaultMessage="Old Password"
              />
            }
            fullWidth
            helperText={formErrorHelper('currentPassword', errors, error)}
            error={!!formErrorHelper('currentPassword', errors, error)}
          />

          <AnvTextField
            disabled={formDisabled}
            name={'password'}
            control={control}
            required
            id="password"
            type="password"
            label={
              <FormattedMessage
                id="LAUNCHPAD.PROFILE.SECURITY.LABEL.NEW_PASSWORD"
                defaultMessage="New Password"
              />
            }
            fullWidth
            helperText={
              formErrorHelper('password', errors, error) || passwordHelperText
            }
            error={!!formErrorHelper('password', errors, error)}
          />

          <AnvTextField
            disabled={formDisabled}
            name={'passwordRepeat'}
            control={control}
            required
            id="passwordRepeat"
            type="password"
            label={
              <FormattedMessage
                id="LAUNCHPAD.PROFILE.SECURITY.LABEL.PASSWORD_CONFIRM"
                defaultMessage="Confirm New Password"
              />
            }
            fullWidth
            helperText={formErrorHelper('passwordRepeat', errors, error)}
            error={!!formErrorHelper('passwordRepeat', errors, error)}
          />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AnvButton
              disabled={formDisabled}
              fullWidth
              variant={'contained'}
              color={'primary'}
              type={'submit'}
            >
              <FormattedMessage
                id="APP.SAVE_CHANGES"
                defaultMessage="Save Changes"
              />
            </AnvButton>
          </Grid>

          <Grid item xs={12}>
            <AnvButton
              disabled={formDisabled}
              fullWidth
              variant={'outlined'}
              customColor={'error'}
              onClick={handleDiscard}
            >
              <FormattedMessage
                id="APP.DISCARD_CHANGES"
                defaultMessage="Discard Changes"
              />
            </AnvButton>
          </Grid>
        </Grid>

        <AnvModalInfo handleClose={handleToggleModal} open={isOpen}>
          <FormattedMessage
            id="LAUNCHPAD.PROFILE.SECURITY.MODAL.SUCCESS.TEXT"
            defaultMessage="Password successfully changed"
          />
        </AnvModalInfo>
      </form>
    </>
  );
});
