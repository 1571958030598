import React from 'react';

import { MenuItem } from '@material-ui/core';
import { ROUTES } from '../../../constants';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import {
  AnvIcon,
  AnvMenu,
  IListActionsComponent,
  useMenu,
} from '@platform/front-lib';

import { IComplaintCategory } from '@platform/front-lib/dist/models';
import { generatePath, NavLink } from 'react-router-dom';

interface ITableActions extends IListActionsComponent {
  data: IComplaintCategory;
  handleOpenDetails: (id: string) => void;
}

export const TableActions: React.FC<ITableActions> = observer(
  ({ data, handleOpenDetails }) => {
    const { formatMessage } = useIntl();

    const { closeMenu, handleClose: handleCloseMenu } = useMenu();

    return (
      <AnvMenu closeMenu={closeMenu}>
        <MenuItem
          title={formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.ACTION.VIEW_CUSTOMIZATIONS',
            defaultMessage: 'View Customizations',
          })}
          component={NavLink}
          to={generatePath(ROUTES.COMPLAINT_CATEGORIES_SETTINGS, {
            id: data._id,
          })}
          onClick={() => {
            handleCloseMenu();
          }}
        >
          <AnvIcon icon={'listBrowsing'} color={'primary'} />
          {formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.ACTION.VIEW_CUSTOMIZATIONS',
            defaultMessage: 'View Customizations',
          })}
        </MenuItem>

        <MenuItem
          title={formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.ACTION.SETTINGS',
            defaultMessage: 'Settings',
          })}
          onClick={() => {
            handleCloseMenu();

            if (data._id) {
              handleOpenDetails(data._id);
            }
          }}
        >
          <AnvIcon icon={'viewDetails'} color={'primary'} />
          {formatMessage({
            id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.ACTION.SETTINGS',
            defaultMessage: 'Settings',
          })}
        </MenuItem>
      </AnvMenu>
    );
  },
);
