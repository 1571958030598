import { ISelectOption } from '@platform/front-lib';
import { Column } from 'react-table';

import { IFormatMessage } from 'src/models/_helpers/react-intl';
import { DATE_FORMATS, FILTER_TYPES } from '../../constants';
import {
  formatPhoneNumber,
  formatWLocale,
  getNameByLangNullable,
} from '../../utils';

export function invitationColumns(
  locale: string | undefined,
  formatMessage: IFormatMessage,
  invitationStatusesMap: Record<string, any>,
  {
    toolsOptions,
    invitationStatusesOptions,
  }: {
    toolsOptions: ISelectOption[];
    invitationStatusesOptions: ISelectOption[];
  },
) {
  const columns: Record<string, any>[] = [
    {
      id: 'fullName',
      accessor: 'firstName',
      filterable: true,
      filterMinLength: 2,
      // filterType: FILTER_TYPES.select,
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.INVITATIONS.LABEL.NAME',
          defaultMessage: 'Name',
        });
      },
      headerString: formatMessage({
        id: 'SUPPORT.INVITATIONS.LABEL.NAME',
        defaultMessage: 'Name',
      }),

      Cell: ({ row }: Record<string, any>) => {
        const firstName = row?.original?.firstName;
        const middleName = row?.original?.middleName;
        const lastName = row?.original?.lastName;

        return `${firstName} ${(middleName && middleName) || ''} ${lastName}`;
      },
    },
    {
      id: 'email',
      accessor: 'email',
      filterable: true,
      filterMinLength: 2,

      Header: () => {
        return formatMessage({
          id: 'SUPPORT.INVITATIONS.LABEL.EMAIL',
          defaultMessage: 'Email',
        });
      },
      headerString: formatMessage({
        id: 'SUPPORT.INVITATIONS.LABEL.EMAIL',
        defaultMessage: 'Email',
      }),
    },
    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.INVITATIONS.LABEL.PHONE',
          defaultMessage: 'Phone',
        });
      },
      headerString: formatMessage({
        id: 'SUPPORT.INVITATIONS.LABEL.PHONE',
        defaultMessage: 'Phone',
      }),
      accessor: 'phone',
      id: 'phone',
      Cell: ({ row }: Record<string, any>) => {
        const phone = row?.original?.phone;
        return formatPhoneNumber(phone);
      },
    },
    {
      id: 'toolCode',
      accessor: 'toolCode',
      filterable: true,
      filterType: FILTER_TYPES.select,
      filterOptions: toolsOptions,
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.INVITATIONS.LABEL.TOOL',
          defaultMessage: 'Tool',
        });
      },
      headerString: formatMessage({
        id: 'SUPPORT.INVITATIONS.LABEL.TOOL',
        defaultMessage: 'Tool',
      }),

      Cell: ({ row }: Record<string, any>) => {
        const toolName = row?.original?.toolCodeRef;
        return getNameByLangNullable(toolName, 'name', locale) || '';
      },
    },
    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.INVITATIONS.LABEL.INVITED_BY',
          defaultMessage: 'Invited By',
        });
      },
      headerString: formatMessage({
        id: 'SUPPORT.INVITATIONS.LABEL.INVITED_BY',
        defaultMessage: 'Invited By',
      }),
      accessor: 'invitedByRef',
      id: 'invitedByRef',

      Cell: ({ row }: Record<string, any>) => {
        const firstName = row?.original?.invitedByRef?.firstName;
        const middleName = row?.original?.invitedByRef?.middleName;
        const lastName = row?.original?.invitedByRef?.lastName;
        return `${firstName} ${(middleName && middleName) || ''} ${lastName}`;
      },
    },
    {
      id: 'status',
      accessor: 'status',
      filterable: true,
      filterType: FILTER_TYPES.select,
      filterOptions: invitationStatusesOptions,
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.INVITATIONS.LABEL.STATUS',
          defaultMessage: 'Status',
        });
      },
      headerString: formatMessage({
        id: 'SUPPORT.INVITATIONS.LABEL.STATUS',
        defaultMessage: 'Status',
      }),

      Cell: ({ row }: Record<string, any>) => {
        const invitationStatus = invitationStatusesMap?.[row?.original?.status];
        return getNameByLangNullable(invitationStatus, 'name', locale) || '';
      },
    },

    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.INVITATIONS.LABEL.EXPIRATION_DATE',
          defaultMessage: 'Expiration Date',
        });
      },
      headerString: formatMessage({
        id: 'SUPPORT.INVITATIONS.LABEL.EXPIRATION_DATE',
        defaultMessage: 'Expiration Date',
      }),
      accessor: 'expiredAt',
      id: 'expiredAt',

      Cell: ({ row }: Record<string, any>) => {
        return formatWLocale(
          row?.original?.expiredAt,
          DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
        );
      },
    },
  ];

  return columns as Column<Record<string, any>>[];
}
