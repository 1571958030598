import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Link, Grid } from '@material-ui/core';
import AuthFormWrapper from '../components/layout/container-auth/AuthFormWrapper';
import ContainerAuth from '../components/layout/container-auth';

const NotFoundPage = () => {
  return (
    <ContainerAuth>
      <AuthFormWrapper>
        <Grid container>
          <Grid item xs={12}>
            <FormattedMessage id="APP.NOT_FOUND" defaultMessage="Not found" />
          </Grid>
          <Grid item xs={12}>
            <Link component={NavLink} to={'/'}>
              <FormattedMessage
                id="APP.COMMON.GO_HOME"
                defaultMessage="Go home"
              />
            </Link>
          </Grid>
        </Grid>
      </AuthFormWrapper>
    </ContainerAuth>
  );
};

export default NotFoundPage;
