import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ILogInData } from 'src/models/requests';
import { useStores } from 'src/stores/root-store';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import ContainerAuth from 'src/components/layout/container-auth';
import AuthFormWrapper from 'src/components/layout/container-auth/AuthFormWrapper';

import { ROUTES, validationsMessages } from 'src/constants';
import { formErrorHelper } from 'src/utils';
import { useStyles } from '../styles';
import clsx from 'clsx';
import { TextH1 } from '@platform/front-lib';
import AuthLanguageMenu from '../common/AuthLanguageMenu';

interface IProps {}

const validationSchema = (fm: (arg: any) => string) =>
  yup.object().shape({
    email: yup.string().required(fm(validationsMessages.required())),
    password: yup.string().required(fm(validationsMessages.required())),
  });

export const SignIn: React.FC<IProps> = observer(() => {
  const classes = useStyles();
  // const { org, school, tool, module } = useParams<IParams>();
  // const {
  //   organization,
  //   school,
  //   tool,
  //   module,
  //   type: accessScope,
  // }: TQueryToolRedirect = useQuery();
  const {
    authStores: { isLoggedIn, error, isError, isFetching, logIn, resetError },
  } = useStores();

  React.useEffect(() => {
    return () => {
      resetError();
    };
  }, [resetError]);

  const { formatMessage } = useIntl();

  const { errors, handleSubmit, control } = useForm<ILogInData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema(formatMessage)),
  });

  const handleLogIn = (fields: ILogInData) => {
    logIn({
      ...fields,
    });
  };

  const formHelper = (field: string) => {
    return formErrorHelper(field, errors, error);
  };

  if (isLoggedIn) {
    // todo if redirect to particular tools check rights and redirect there
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  const errorUnknown = isError && !(error && Object.keys(error)?.length) && (
    <FormattedMessage
      id="APP.ERROR_HAS_OCCURRED.TRY_AGAIN"
      defaultMessage="Error has occurred, please try again later"
    />
  );

  const formDisabled = isFetching;

  return (
    <ContainerAuth>
      <AuthFormWrapper>
        <TextH1 className={clsx(classes.header, classes.headerSignIn)}>
          <FormattedMessage
            id="SUPPORT.SIGN_IN.TITLE"
            defaultMessage="Sign In"
          />
        </TextH1>

        <form onSubmit={handleSubmit(handleLogIn)} noValidate>
          <Controller
            disabled={formDisabled}
            name={'email'}
            control={control}
            as={TextField}
            required
            className={classes.commonControl}
            id="email"
            inputMode="email"
            label={
              <FormattedMessage
                id="SUPPORT.SIGN_IN.LABEL.EMAIL_ADDRESS"
                defaultMessage="Email address"
              />
            }
            fullWidth
            helperText={formHelper('email') || errorUnknown}
            error={!!formHelper('email') || !!errorUnknown}
          />

          <Controller
            disabled={formDisabled}
            name={'password'}
            control={control}
            as={TextField}
            required
            className={classes.commonControl}
            id="password"
            type="password"
            label={
              <FormattedMessage
                id="SUPPORT.SIGN_IN.LABEL.PASSWORD"
                defaultMessage="Password"
              />
            }
            fullWidth
            helperText={formHelper('password')}
            error={!!formHelper('password')}
          />

          <Button
            disabled={formDisabled}
            className={classes.submitSignUp}
            variant={'contained'}
            color={'primary'}
            fullWidth
            type={'submit'}
          >
            <FormattedMessage
              id="SUPPORT.SIGN_IN.ACTION"
              defaultMessage="Sign In"
            />
          </Button>
        </form>

        <AuthLanguageMenu />
      </AuthFormWrapper>
    </ContainerAuth>
  );
});
