import { IGetList } from 'src/service/interfaces';
import { axiosCatch } from 'src/service/utils';
import { axiosInstance } from '@platform/front-lib';

const axios = axiosInstance;

class InvitationsService {
  private apiUrl = process.env.REACT_APP_API_TOOL_ENDPOINT + '/invitations';

  async getAll(data?: IGetList) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/list`, {
        params: data,
      }),
    );
  }
}

export default new InvitationsService();
