// extend palette https://medium.com/javascript-in-plain-english/extend-material-ui-theme-in-typescript-a462e207131f
export const palette = {
  primary: {
    main: '#174D51',
  },
  brand: {
    main: '#174D51',
    dark: '#FBC357',
    light: '#FFFFFF',
    extraLight: 'rgba(23,77,81,0.1)',
  },
  generalTheme: {
    logo: {
      textColor: '#114447',
      iconColor: '#114447',
    },
    layout: {
      contentBackground: '#E0FBFC',
      headerBackground: '#98C1D9',
    },
    button: {
      buttonBackground: '#114447',
      buttonHoverBackground: '#114447',
    },
    link: {
      color: '#083E42',
    },
  },
  error: {
    main: '#F44D4D',
  },
  common: {
    white: '#FFFFFF',
  },
  custom: {
    lightIcons: '#9DADAF',
    disabled: '#818181',
    textLight: '#8C8C8C',
  },
  background: {
    default: '#F2F5F5',
    paper: '#FFFFFF',
  },
  text: {
    // primary: "#174D51",
    primary: 'rgba(0, 0, 0, 0.88)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    hyperlink: '#18A0FB',
    disabled: '#818181',
    redcolor: '#ff1744',
    white: '#FFFFFF',
    lightIcons: '#9DADAF',
  },
  border: {
    primary: '#CCCCCC',
  },
};
