import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface IProps extends TypographyProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  bold?: boolean;
  semiBold?: boolean;
  muted?: boolean;
  className?: string;
}
export const TextBody2: React.FC<IProps> = ({
  children,
  className,
  component,
  semiBold,
  bold,
  muted,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.textBody, {
        // @ts-ignore
        [className]: !!className,
        [classes.semiBold]: !!semiBold,
        [classes.bold]: !!bold,
        [classes.muted]: !!muted,
      })}
      component={component || 'span'}
      {...other}
    >
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    textBody: {
      fontFamily: 'Inter',
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 400,
    },

    semiBold: {
      fontWeight: 600,
    },
    bold: {
      fontWeight: 'bold',
    },

    muted: {
      color: theme.palette.text.secondary,
    },
  }),
);
