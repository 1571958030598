import React from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import {
  Constants,
  SupportTicketContext,
  SupportTicketPage,
} from '@platform/front-lib';

// import { Support } from './support';
// import { SupportContext } from './support-context';
import { useStores } from '../../stores/root-store';
import { ROUTES, TOOL_CODE } from '../../constants';
// import { SupportTicketPage } from './support';

const { USER_ROLES } = Constants;
interface IProps {}

const SupportConnected: React.FC<IProps> = observer(() => {
  const { authStores } = useStores();

  const { formatMessage } = useIntl();

  return (
    <SupportTicketContext.Provider
      value={{
        tool: TOOL_CODE,
        role: USER_ROLES.ROLE_OPERATOR,
        authStores,
        route: ROUTES.SUPPORT,
        translationPrefix: 'SUPPORT.TICKETS.VIEW_DETAILS',
        breadcrumbs: [
          {
            component: formatMessage({
              id: 'SUPPORT.TICKETS.BREADCRUMBS.SUPPORT',
              defaultMessage: 'Support',
            }),
          },
        ],
      }}
    >
      <SupportTicketPage />
    </SupportTicketContext.Provider>
  );
});

export default SupportConnected;
