import React, { lazy, Suspense } from 'react';
import {
  AnvViewHeader,
  TextH1,
  AnvIcon,
  AnvBreadcrumbs,
  AnvUtils,
  IsLoadingComponent,
} from '@platform/front-lib';
import { Box, Grid, Paper } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { USER_DETAILS_TABS_PARAMS } from '../../../constants';
import { IUserViewDetailsParams } from '../../../models';
import { useStores } from '../../../stores/root-store';

import { drawerOperatorMap } from '../../../constants/drawer-map';
import { TIcon } from '../../../components/shared/icons';
import { useContainerStyles } from './container.styles';
import { UserDetailsTabs } from './tabs';

const TAB_COMPONENTS = {
  [USER_DETAILS_TABS_PARAMS.INFO]: React.lazy(() => import('./info')),
  [USER_DETAILS_TABS_PARAMS.ADDRESS]: React.lazy(() => import('./address')),
  [USER_DETAILS_TABS_PARAMS.LAUNCHPAD_SETTINGS]: lazy(
    () => import('../user-settings'),
  ),
  [USER_DETAILS_TABS_PARAMS.ACCESS_REQUESTS]: lazy(
    () => import('../access-requests'),
  ),
};

interface IProps {}

const UserDetails: React.FC<IProps> = observer(() => {
  const { id, tab } = useParams<IUserViewDetailsParams>();

  const containerClasses = useContainerStyles();

  const {
    usersStore: { getEntity, detailedMap },
  } = useStores();

  React.useEffect(() => {
    if (!detailedMap[id]) {
      getEntity({ id });
    }
  }, [detailedMap, getEntity, id]);

  const user = detailedMap[id] || null;

  const userName = AnvUtils.formatFullName({
    entity: user,
  });

  const TabComponent = TAB_COMPONENTS[tab] || null;

  return (
    <Suspense fallback={<IsLoadingComponent isLoading />}>
      <AnvBreadcrumbs
        isOperator
        items={[
          {
            component: (
              <>
                <AnvIcon
                  size={24}
                  icon={drawerOperatorMap.users.icon as TIcon}
                  offset={1}
                />
                <FormattedMessage
                  tagName={'span'}
                  id={drawerOperatorMap.users.nameI18n}
                  defaultMessage={drawerOperatorMap.users.name}
                />
              </>
            ),
            to: drawerOperatorMap.users.to,
          },
          {
            component: userName,
          },
        ]}
      />

      <Paper className={containerClasses.container}>
        <AnvViewHeader
          customTitle={
            <Grid
              container
              direction={'row'}
              wrap={'nowrap'}
              alignItems={'center'}
              spacing={2}
              justify={'space-between'}
            >
              <Grid item>
                <TextH1>{userName}</TextH1>
              </Grid>
            </Grid>
          }
        />

        <Box
          display={'flex'}
          flexDirection={'column'}
          className={containerClasses.contentWrapper}
        >
          <UserDetailsTabs />

          {!!TabComponent && <TabComponent />}
        </Box>
      </Paper>
    </Suspense>
  );
});

export default UserDetails;
