import { axiosCatch } from 'src/service/utils';

import { getCookieToken } from '../../utils/cookie';
import { axiosInstanceOperator } from '../instance-operator';

const axios = axiosInstanceOperator;

class DashboardService {
  private apiUrl = process.env.REACT_APP_API_TOOL_ENDPOINT + '/dashboard';

  async getTestData() {
    return axiosCatch(
      axios.get(`${this.apiUrl}/data`, {
        headers: {
          Authorization: `Bearer ${getCookieToken({ isOperator: true })}`,
        },
      }),
    );
  }
}

export default new DashboardService();
