import { action, makeAutoObservable, observable } from 'mobx';

import ProfileBaseService from 'src/service/profile-operator/base';
import { IActionMeta } from '../_helpers/interfaces';
import { IProfileOperatorBaseForm } from '../../pages/profile/tabs/_interfaces';

export interface IGetCodeForPhoneParams {
  phone: string;
}
export interface IUpdateProfileBaseParams extends IProfileOperatorBaseForm {}

export default class ProfileBaseStore {
  @observable
  error: Record<string, any> | null | string = null;
  @observable
  profileData: Record<string, any> | null = null;

  @observable
  dataEventsCountMap: Record<string, number> = {};

  @observable isSmsSent: boolean = false;
  @observable smsSentTo: string | null = null;
  @observable smsCodeDev: string | null = null;

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @action
  private reset() {
    this.profileData = null;
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  @action
  get = async (meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await ProfileBaseService.get();

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      if (response) {
        Object.keys(response).forEach((key) => {
          if (!response[key]) {
            response[key] = '';
          }
        });
      }
      this.profileData = response;
    });
  };

  @action
  sendCode = async (data: IGetCodeForPhoneParams, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await ProfileBaseService.sendCode(data);

    if (error || response.error) {
      return this.setError(() => {
        meta?.failFunc?.();
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      this.isSmsSent = true;
      this.smsSentTo = data.phone;
      this.smsCodeDev = response?.resetKey;
    });
  };

  @action
  resetSmsSent = () => {
    this.isSmsSent = false;
  };

  @action
  update = async (data: IUpdateProfileBaseParams, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await ProfileBaseService.updateBase(data);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      this.profileData = response;

      this.isSmsSent = false;

      meta?.successFunc?.(response);
    });
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor() {
    makeAutoObservable(this);
  }
}
