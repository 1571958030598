import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(6),
    },
    linkStyle: {
      color: theme.palette.brand.main,
      paddingTop: 4,
    },

    title: {
      display: 'flex',
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'unset',
      },
    },
    contentWrapper: {
      marginLeft: 32, // icon size + icon right offset
      paddingBottom: theme.spacing(2),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: -0.18,
    },
    entityTitle: {
      fontSize: 16,
      lineHeight: '24px',
      color: 'rgba(0, 0, 0, 0.88)',
    },
    entityCaption: {
      fontSize: 13,
      lineHeight: '22px',
      color: 'rgba(0, 0, 0, 0.7)',
    },
    deleteButtonWrapper: { position: 'relative' },

    deleteButton: {
      padding: 8,
      position: 'absolute',
      top: -8,
    },
    actionButton: {
      lineHeight: '18px',
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      fontSize: '15px !important',
      justifyContent: 'start',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      marginLeft: 28, // icon width + icon right offset
    },

    formsContentWrapper: {
      paddingLeft: 32, // icon size + icon right offset

      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: -0.18,
    },
  }),
);
