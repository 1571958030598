import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Skeleton } from '@material-ui/lab';

import {
  AnvBreadcrumbs,
  AnvButton,
  AnvIcon,
  AnvPaginationMui,
  AnvTable,
  AnvTableWrapper,
  AnvTitle,
  AnvUtils,
  Constants,
  IsLoadingComponent,
  ModalConfirm,
  useCheckIsTableView,
  useCommonTable,
  useModal,
} from '@platform/front-lib';

import { useStores } from '../../../stores/root-store';
import { prepareColumns } from './columns';
import { TableActions } from './actions';
import { IComplaintCategory } from '@platform/front-lib/dist/models';
import { ROUTES } from '../../../constants';
import { ComplaintSettingForm } from './form-modal';

interface IComplaintCategoriesSettingsProps {}

const defaultFilterState = {};

const requestId = Constants.DEFAULT_REQUEST_ID;

export const ComplaintCategorySettingsPage: React.FC<IComplaintCategoriesSettingsProps> = observer(
  () => {
    const { id: categoryId } = useParams<{ id: string }>();

    const { locale, formatMessage } = useIntl();

    const {
      profileOperatorStore: {
        profileBaseStore: {
          profileData,
          isLoaded: isProfileLoaded,
          get: getProfile,
        },
      },

      metaStores: {
        jurisdictionStore: {
          getList: getJurisdictions,
          data: jurisdictions,
          isLoadedList: isLoadedJurisdictions,
        },
        accessScopeStore: {
          data: accessScopes,
          getAccessScopes,
          isLoaded: isLoadedAccessScopes,
        },
        reviewSeveritiesStore: {
          getList: getSeverities,
          dataMap: severitiesMap,
          data: severities,
          isLoadedList: isLoadedSeverities,
        },
      },

      complaintStore: {
        complaintCategoriesStore: {
          dataMap: categoriesMap,
          getOne: getOneCategory,
        },
        complaintCategorySettingsStore: {
          dataMap,
          deleteOne,
          getList,
          setPage,
          setFilter,
          setSort,
          getListMetaDataByRequestId,
        },
      },
    } = useStores();

    React.useEffect(() => {
      if (!isLoadedSeverities) {
        getSeverities({});
      }
    }, [isLoadedSeverities, getSeverities]);

    const severitiesOptions = React.useMemo(() => {
      return AnvUtils.prepareOptions(severities, {
        field: 'name',
        locale,
      });
    }, [severities, locale]);

    React.useEffect(() => {
      if (!isLoadedJurisdictions) {
        getJurisdictions({});
      }
    }, [isLoadedJurisdictions, getJurisdictions]);

    React.useEffect(() => {
      if (!isProfileLoaded) {
        getProfile({});
      }
    }, [isProfileLoaded, getProfile]);

    const jurisdictionOptions = React.useMemo(() => {
      const jurisdictionsFiltered = profileData?.operatorJurisdictions?.length
        ? jurisdictions.filter((item) => {
            return profileData?.operatorJurisdictions?.includes(item._id);
          })
        : jurisdictions;

      return AnvUtils.prepareOptions(jurisdictionsFiltered, {
        field: 'name',
        locale,
      });
    }, [jurisdictions, profileData?.operatorJurisdictions, locale]);
    //////

    const categoryEntity: IComplaintCategory = categoriesMap[categoryId];

    React.useEffect(() => {
      if (!categoryEntity) {
        getOneCategory({ id: categoryId });
      }
    }, [getOneCategory, categoryId, categoryEntity]);

    const {
      dataList,
      isFetchingList,
      pagination,
      storeFilter,
      storeSorting,
      isLoadedList,
    } = getListMetaDataByRequestId(requestId);

    const {
      filterMemo,
      filterThrottle,
      sorting,
      page,
      filterState,
      handleChangeFilterDate,
      handleChangeFilter,
      handleChangePhone,
      handleChangePage,
    } = useCommonTable({
      defaultFilterState,
      pagination,
      setPage,
      setFilter,
      setSort,
      storeSorting,
      storeFilter,
      requestId,
    });

    const getEntities = React.useCallback(() => {
      let filter = filterThrottle;

      getList(
        {
          id: categoryId,
          page: +page,
          sort: sorting,
          filter,
          limit: Constants.PAGINATION_LIMIT,
        },
        {
          requestId,
        },
      );
    }, [filterThrottle, getList, page, sorting, categoryId]);

    React.useEffect(() => {
      getEntities();
    }, [getEntities]);

    React.useEffect(() => {
      if (!isLoadedAccessScopes) {
        getAccessScopes({});
      }
    }, [isLoadedAccessScopes, getAccessScopes]);

    const entityTypesOptions = React.useMemo(() => {
      // const accessScopesFiltered = accessScopes?.filter((item) => {
      //   return [ACCESS_SCOPES.LEA, ACCESS_SCOPES.SEA].includes(item.code);
      // });

      return AnvUtils.prepareOptions(accessScopes, {
        field: 'description',
        locale,
      });

      // options.push({
      //   value: ACCESS_SCOPES.SCHOOL,
      //   label: formatMessage({
      //     id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.ENTITY_TYPE.SCHOOL',
      //     defaultMessage: 'School',
      //   }),
      // });
    }, [accessScopes, locale]);

    const columns = React.useMemo(() => {
      const entityTypesMap = Object.fromEntries(
        entityTypesOptions.map((item) => [item.value, item.label]),
      );

      return prepareColumns({
        formatMessage,
        locale,
        jurisdictionOptions,
        entityTypesMap,
        entityTypes: entityTypesOptions,
        severitiesMap,
        severitiesOptions,
      });
    }, [
      entityTypesOptions,
      severitiesMap,
      severitiesOptions,
      jurisdictionOptions,
      formatMessage,
      locale,
    ]);

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoadedList,
      tableDataLength: dataList?.length || 0,
      memoQueryFilter: filterMemo,
    });

    /* Handle delete action */
    const {
      isOpen: isOpenDeleteModal,
      handleOpenModal: handleOpenDeleteModalDirty,
      handleCloseModal: handleCloseDeleteModalDirty,
    } = useModal();

    const [deleteId, setDeleteId] = React.useState<string | null>(null);

    const handleOpenDeleteModal = React.useCallback(
      (id: string) => {
        handleOpenDeleteModalDirty();
        setDeleteId(id);
      },
      [setDeleteId, handleOpenDeleteModalDirty],
    );
    const handleCloseDeleteModal = React.useCallback(() => {
      handleCloseDeleteModalDirty();
      setDeleteId(null);
    }, [handleCloseDeleteModalDirty]);

    const handleDelete = () => {
      if (deleteId) {
        deleteOne(
          { id: categoryId, settingId: deleteId },
          {
            successFunc: () => {
              handleCloseDeleteModal();
              getEntities();
            },
          },
        );
      }
    };

    /* Handle delete end */

    const {
      isOpen,
      handleOpenModal,
      handleCloseModal: handleCloseModalDirty,
    } = useModal();
    const [editId, setEditId] = React.useState<string | null>(null);
    const [duplicateId, setDuplicateId] = React.useState<string | null>(null);

    const handleCloseModal = React.useCallback(() => {
      handleCloseModalDirty();
      setEditId(null);
      setDuplicateId(null);
    }, [handleCloseModalDirty]);

    const handleOpenAddNew = React.useCallback(() => {
      handleOpenModal();
    }, [handleOpenModal]);

    const handleOpenEdit = React.useCallback(
      (id: string) => {
        handleOpenModal();
        setEditId(id);
      },
      [handleOpenModal],
    );

    const handleOpenClone = React.useCallback(
      (id: string) => {
        handleOpenModal();
        setDuplicateId(id);
      },
      [handleOpenModal],
    );

    const duplicatedOrEditEntity = React.useMemo(() => {
      if (isOpen) {
        if (editId) {
          return dataMap[editId];
        }

        if (duplicateId) {
          return {
            ...dataMap[duplicateId],
            entityType: '',
            entity: '',
          };
        }

        return {
          jurisdiction: categoryEntity.jurisdiction,
          severity: categoryEntity.severity,

          // @ts-ignore
          // anonymityProtection: categoryEntity.anonymityProtection,
        };
      }

      return null;
    }, [isOpen, editId, duplicateId, categoryEntity, dataMap]);

    return (
      <>
        <AnvBreadcrumbs
          isOperator
          items={[
            {
              to: ROUTES.COMPLAINT_CATEGORIES,
              component: (
                <>
                  <AnvIcon size={24} icon={'viewDashboard'} offset={1} />
                  <FormattedMessage
                    tagName={'span'}
                    id="SUPPORT.COMPLAINT.CATEGORIES.BREADCRUMBS"
                    defaultMessage="Complaint Categories"
                  />
                </>
              ),
            },
            {
              component: (
                <>
                  <FormattedMessage
                    tagName={'span'}
                    id="SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.BREADCRUMBS"
                    defaultMessage="Category Settings"
                  />
                </>
              ),
            },
          ]}
          moduleCode={''}
          tool={undefined}
        />

        <AnvTableWrapper>
          <IsLoadingComponent isLoading={isFetchingList} />

          <AnvTitle
            direction={'row'}
            alignItems={'center'}
            justify={'space-between'}
            spacing={2}
            tableTitle={
              AnvUtils.getNameByLangNullable(
                categoryEntity,
                'description',
                locale,
              ) || <Skeleton width={75} />
            }
            rightActions={
              <AnvButton
                onClick={handleOpenAddNew}
                startIcon={<AnvIcon size={16} icon={'plus'} />}
                variant={'contained'}
                size={'small'}
              >
                <FormattedMessage
                  id={'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.ACTION.EDIT'}
                  defaultMessage={'Add Setting'}
                />
              </AnvButton>
            }
          />

          <AnvTable
            setFilter={setFilter}
            filterMemo={filterMemo}
            isShowTable={isShowTable}
            filterState={filterState}
            handleChangeFilterDate={handleChangeFilterDate}
            handleChangeFilter={handleChangeFilter}
            handleChangePhone={handleChangePhone}
            //common
            requestId={requestId}
            isLoadedList={isLoadedList}
            data={dataList}
            setSort={setSort}
            columns={columns}
            querySort={sorting}
            TableActionComponent={({ data }) => {
              return (
                <TableActions
                  data={data as IComplaintCategory}
                  handleOpenClone={handleOpenClone}
                  handleOpenEdit={handleOpenEdit}
                  handleOpenDelete={handleOpenDeleteModal}
                />
              );
            }}
            noDataMessage={formatMessage({
              id: 'SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.EMPTY',
              defaultMessage: 'There is no data in this list',
            })}
          />

          <AnvPaginationMui
            pagination={pagination}
            totalPages={pagination?.totalPages}
            handleChangePage={handleChangePage}
            page={+page}
          />
        </AnvTableWrapper>

        {isOpen && duplicatedOrEditEntity && (
          <ComplaintSettingForm
            categoryEntity={categoryEntity}
            open={isOpen}
            handleClose={handleCloseModal}
            id={editId}
            getEntities={getEntities}
            existsValues={duplicatedOrEditEntity}
            //   duplicateId={duplicateId}
          />
        )}

        {isOpenDeleteModal && (
          <ModalConfirm
            isOpen={isOpenDeleteModal}
            handleClose={handleCloseDeleteModal}
            cancelAction={handleCloseDeleteModal}
            confirmAction={handleDelete}
            text={formatMessage({
              id: `SUPPORT.COMPLAINT.CATEGORIES.SETTINGS.LIST.DELETE.WARN.MESSAGE`,
              defaultMessage: 'Are you sure you want to delete this row?',
            })}
          />
        )}
      </>
    );
  },
);
