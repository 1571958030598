import React from 'react';

import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';

import {
  AnvButton,
  AnvDialog,
  AnvDialogActions,
  AnvDialogBody,
  AnvDialogTitle,
  AnvUtils,
  TextBody,
} from '@platform/front-lib';

import { useStores } from '../../../stores/root-store';
import { envConst } from '../../../constants';
import { generateUrl } from '../../../utils';

interface IUsersModalPasswordProps {
  handleClose: () => void;
  open: boolean;
  user: Record<string, any>;
}

export const UsersModalActivation: React.FC<IUsersModalPasswordProps> = observer(
  ({ handleClose, open, user: userShort }) => {
    const {
      usersStore: { dataMap: usersMap, error, isFetching },
    } = useStores();

    const user = usersMap?.user || userShort;

    const link = generateUrl(
      envConst.launchpadUrl,
      `sign-up/finish/${user?.requestEmailCode}`,
    );

    return (
      <AnvDialog handleClose={handleClose} open={open}>
        <AnvDialogTitle>
          <FormattedMessage
            id="SUPPORT.USERS.MODAL.ACTIVATION.TITLE"
            defaultMessage="Activate Account"
          />
        </AnvDialogTitle>

        <AnvDialogBody>
          <>
            <p>
              <FormattedMessage
                id="SUPPORT.USERS.MODAL.ACTIVATION.CAPTION"
                defaultMessage="You can activate the account, or copy and send activation link to the user"
              />
            </p>

            <div>{link}</div>
          </>

          {error && (
            <div style={{ marginTop: 16 }}>
              <TextBody color={'error'}>
                <FormattedMessage
                  id="SUPPORT.USERS.MODAL.CHANGE_PASSWORD.ERROR"
                  defaultMessage="Error has occurred, please try again later"
                />
              </TextBody>
            </div>
          )}
        </AnvDialogBody>

        <AnvDialogActions>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12}>
              <AnvButton
                disabled={isFetching}
                data-test={'activation-copy-activate-link'}
                color={'primary'}
                variant={'outlined'}
                fullWidth
                onClick={AnvUtils.copyTextToClipboard(link)}
              >
                <FormattedMessage
                  id="SUPPORT.USERS.MODAL.ACTIVATION.ACTION.COPY"
                  defaultMessage="Copy Activation Link"
                />
              </AnvButton>
            </Grid>

            <Grid item xs={12}>
              <AnvButton
                disabled={isFetching}
                data-test={'activation-cancel'}
                variant={'text'}
                customColor={'muted'}
                fullWidth
                type={'button'}
                onClick={handleClose}
              >
                <FormattedMessage id="APP.CLOSE" defaultMessage="Close" />
              </AnvButton>
            </Grid>
          </Grid>
        </AnvDialogActions>
      </AnvDialog>
    );
  },
);
