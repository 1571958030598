import {
  AnvBreadcrumbs,
  AnvIcon,
  AnvPaginationMui,
  AnvTable,
  AnvTableWrapper,
  AnvTitle,
  AnvUtils,
  Constants,
  IsLoadingComponent,
  useCheckIsTableView,
  useCommonTable,
} from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStores } from '../../../stores/root-store';
import { prepareColumns } from './columns';
import { TableActions } from './actions';
import { IComplaintCategory } from '@platform/front-lib/dist/models';
import { ComplaintCategoryDetailModal } from './modal-details';

interface IComplaintCategoriesSettingsProps {}

const defaultFilterState = {};

const requestId = Constants.DEFAULT_REQUEST_ID;

export const ComplaintCategoriesPage: React.FC<IComplaintCategoriesSettingsProps> = observer(
  () => {
    const { locale, formatMessage } = useIntl();

    const {
      profileOperatorStore: {
        profileBaseStore: {
          profileData,
          isLoaded: isProfileLoaded,
          get: getProfile,
        },
      },
      metaStores: {
        jurisdictionStore: {
          getList: getJurisdictions,
          data: jurisdictions,
          isLoadedList: isLoadedJurisdictions,
        },
      },
      complaintStore: {
        complaintCategoriesStore: {
          dataMap,
          customizationRecordsMap,
          getList,
          setPage,
          setFilter,
          setSort,
          getListMetaDataByRequestId,
        },
      },
    } = useStores();

    const {
      dataList,
      isFetchingList,
      pagination,
      storeFilter,
      storeSorting,
      isLoadedList,
    } = getListMetaDataByRequestId(requestId);

    React.useEffect(() => {
      if (!isLoadedJurisdictions) {
        getJurisdictions({});
      }
    }, [isLoadedJurisdictions, getJurisdictions]);

    React.useEffect(() => {
      if (!isProfileLoaded) {
        getProfile({});
      }
    }, [isProfileLoaded, getProfile]);

    const jurisdictionOptions = React.useMemo(() => {
      const jurisdictionsFiltered = profileData?.operatorJurisdictions?.length
        ? jurisdictions.filter((item) => {
            return profileData?.operatorJurisdictions?.includes(item._id);
          })
        : jurisdictions;

      return AnvUtils.prepareOptions(jurisdictionsFiltered, {
        field: 'name',
        locale,
      });
    }, [jurisdictions, profileData?.operatorJurisdictions, locale]);
    //////

    const {
      filterMemo,
      filterThrottle,
      sorting,
      page,
      filterState,
      handleChangeFilterDate,
      handleChangeFilter,
      handleChangePhone,
      handleChangePage,
    } = useCommonTable({
      defaultFilterState,
      pagination,
      setPage,
      setFilter,
      setSort,
      storeSorting,
      storeFilter,
      requestId,
    });

    const getEntities = React.useCallback(() => {
      let filter = filterThrottle;

      getList(
        {
          page: +page,
          sort: sorting,
          filter,
          limit: Constants.PAGINATION_LIMIT,
        },
        {
          requestId,
        },
      );
    }, [filterThrottle, getList, page, sorting]);

    React.useEffect(() => {
      getEntities();
    }, [getEntities]);

    const columns = React.useMemo(() => {
      return prepareColumns({
        formatMessage,
        locale,
        customizationRecordsMap,
        jurisdictionOptions,
      });
    }, [customizationRecordsMap, jurisdictionOptions, formatMessage, locale]);

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoadedList,
      tableDataLength: dataList?.length || 0,
      memoQueryFilter: filterMemo,
    });

    const [showDetailsId, setShowDetailsId] = React.useState<null | string>(
      null,
    );

    const isOpenDetails = !!showDetailsId;

    const handleCloseDetails = React.useCallback(() => {
      setShowDetailsId(null);
    }, []);

    const handleOpenDetails = React.useCallback((id: string) => {
      setShowDetailsId(id);
    }, []);

    return (
      <>
        <AnvBreadcrumbs
          isOperator
          items={[
            {
              component: (
                <>
                  <AnvIcon size={24} icon={'viewDashboard'} offset={1} />
                  <FormattedMessage
                    tagName={'span'}
                    id="SUPPORT.COMPLAINT.CATEGORIES.BREADCRUMBS"
                    defaultMessage="Complaint Categories"
                  />
                </>
              ),
            },
          ]}
          moduleCode={''}
          tool={undefined}
        />

        <AnvTableWrapper>
          <IsLoadingComponent isLoading={isFetchingList} />

          <AnvTitle
            direction={'row'}
            alignItems={'center'}
            justify={'space-between'}
            spacing={2}
            tableTitle={formatMessage({
              id: 'SUPPORT.COMPLAINT.CATEGORIES.TITLE',
              defaultMessage: 'Complaint Categories',
            })}
          ></AnvTitle>

          <AnvTable
            setFilter={setFilter}
            filterMemo={filterMemo}
            isShowTable={isShowTable}
            filterState={filterState}
            handleChangeFilterDate={handleChangeFilterDate}
            handleChangeFilter={handleChangeFilter}
            handleChangePhone={handleChangePhone}
            //common
            requestId={requestId}
            isLoadedList={isLoadedList}
            data={dataList}
            setSort={setSort}
            columns={columns}
            querySort={sorting}
            TableActionComponent={({ data }) => {
              return (
                <TableActions
                  data={data as IComplaintCategory}
                  handleOpenDetails={handleOpenDetails}
                />
              );
            }}
            noDataMessage={formatMessage({
              id: 'SUPPORT.COMPLAINT.CATEGORIES.LIST.EMPTY',
              defaultMessage: 'There is no data in this list',
            })}
          />

          <AnvPaginationMui
            pagination={pagination}
            totalPages={pagination?.totalPages}
            handleChangePage={handleChangePage}
            page={+page}
          />
        </AnvTableWrapper>

        {!!showDetailsId && (
          <ComplaintCategoryDetailModal
            open={isOpenDetails}
            complaintCategory={dataMap[showDetailsId]}
            handleClose={handleCloseDetails}
          />
        )}
      </>
    );
  },
);
