import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

import mixins from './mixins';

// Create a theme instance.
const defaultTheme = createMuiTheme();

export const themePlain = (palette: any) => ({
  typography: {
    fontFamily: `"Inter", "Helvetica", "Arial", sans-serif`,
  },
  palette,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          scrollBehavior: 'smooth',
          scrollbarWidth: 'thin',
        },
        div: {
          ...mixins.scrollBarBody,
        },
        a: {
          color: palette.primary.main,
        },

        fb_dialog: {
          zIndex: `${defaultTheme.zIndex.mobileStepper - 1} !important`,
        },

        body: {
          margin: 0,
          fontFamily: 'Inter',

          ...mixins.scrollBarBody,
        },
      },
    },
    MuiLink: {
      root: {
        color: palette.text.hyperlink,

        '&.MuiTypography-colorSecondary ': {
          color: palette.primary.main,
        },
      },
    },
    MuiAppBar: {
      root: {
        maxHeight: '64px',
        display: 'flex',
        margin: 0,

        '&&': {
          backgroundColor: palette.generalTheme.layout.headerBackground,
        },
      },
      colorPrimary: {
        backgroundColor: '#fff',
        color: palette.text.primary,
      },
    },
    MuiToolbar: {
      gutters: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: defaultTheme.spacing(2),
        paddingRight: defaultTheme.spacing(3),
        '@media (min-width: 600px)': {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(3),
        },
      },
    },
    MuiTypography: {
      root: {
        '&$h6': {
          fontFamily: 'Sora, Roboto, Helvetica, Arial, sans-serif',
          fontSize: 32,
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: '40px',
          display: 'flex',
        },
        '&$button': {
          fontFamily: "'Inter', sans-serif",
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
          letterSpacing: '-0.039px',
          textTransform: 'none',
          color: palette.text.primary,
          cursor: 'pointer',
        },
      },
    },
    MuiButtonGroup: {
      root: {
        minHeight: 40,
      },
    },
    MuiButton: {
      root: {
        fontFamily: "'Inter', sans-serif",
        textTransform: 'none',
        '&$outlined': {
          padding: defaultTheme.spacing(2, 4),
          borderRadius: '3px',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 15,
          lineHeight: '18px',
          color: palette.brand.main,
          borderColor: palette.brand.main,

          '&$sizeSmall': {
            padding: defaultTheme.spacing(1.5, 3),
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 13,
            lineHeight: '14px',
          },

          '&$sizeLarge': {
            padding: defaultTheme.spacing(2, 4),
            fontSize: 22,
            lineHeight: '22px',
            fontStyle: 'normal',
            fontWeight: 700,
          },
        },

        '&$contained': {
          padding: defaultTheme.spacing(2, 4),
          borderRadius: '3px',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 15,
          lineHeight: '18px',
          color: palette.text.white,
          backgroundColor: palette.brand.main,

          '&$sizeSmall': {
            padding: defaultTheme.spacing(1.5, 3),
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 13,
            lineHeight: '14px',
          },

          '&$sizeLarge': {
            padding: defaultTheme.spacing(2, 4),
            fontSize: 22,
            lineHeight: '22px',
            fontStyle: 'normal',
            fontWeight: 700,
          },
        },

        '&$text': {
          padding: defaultTheme.spacing(2, 4),
          borderRadius: '3px',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 15,
          lineHeight: '18px',
          // color: palette.text.brand,

          '&$sizeSmall': {
            padding: defaultTheme.spacing(1.5, 3),
            borderRadius: '3px',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 13,
            lineHeight: '14px',
          },

          '&$sizeLarge': {
            padding: defaultTheme.spacing(2, 4),
            borderRadius: '3px',
            fontSize: 22,
            lineHeight: '22px',
            fontStyle: 'normal',
            fontWeight: 700,
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        minHeight: '56px',
        borderRadius: '6px',
        margin: defaultTheme.spacing(0, 0, 2),
      },
    },
    MuiFormControlLabel: {
      root: {
        cursor: 'default',
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: palette.text.primary,
        },
      },
      filled: {
        color: palette.custom.textLight,
      },
      asterisk: {
        color: palette.text.redcolor,
      },
    },
    MuiInputLabel: {
      filled: {
        color: palette.custom.textLight,

        '&$shrink$marginDense$marginDense$marginDense ': {
          transform: 'translate(14px, 8px)',
        },

        '&$shrink, &$shrink$marginDense$marginDense ': {
          transform: 'translate(16px, 10px)',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 11,
          lineHeight: '14px',
          letterSpacing: '0.05px',
        },
      },
    },
    MuiSelect: {
      root: {
        paddingTop: '20px',
        '&$filled': {
          padding: defaultTheme.spacing(3, 2, 1, 2),
          borderRadius: '6px',
        },
      },
      iconOutlined: {
        right: defaultTheme.spacing(2),
      },
    },

    MuiMenu: {
      paper: {
        // marginTop: defaultTheme.spacing(2),
        // minHeight: '100px',
        minHeight: '50px',
      },
      list: {
        maxHeight: '458px',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 24,
        marginRight: defaultTheme.spacing(1),
      },
    },

    MuiMenuItem: {
      root: {
        height: '56px',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '-0.18px',
        '&$gutters': {
          padding: defaultTheme.spacing(2, 2.5),
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: defaultTheme.spacing(0.5),
      },
    },
    MuiInputBase: {
      input: {
        backgroundColor: palette.common.white,
        borderRadius: '5px',
        lineHeight: '20px',
        height: '20px',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: palette.common.white,
        border: '1px solid #CCCCCC',
        borderRadius: '6px',

        '&&:hover.Mui-focused, &&:hover, &&.Mui-focused, &&.Mui-disabled': {
          backgroundColor: palette.common.white,
        },

        '.filterInput &&': {
          backgroundColor: palette.common.white,
        },

        '.withoutLabel & .MuiFilledInput-input': {
          padding: defaultTheme.spacing(1),
        },
      },
      input: {
        padding: defaultTheme.spacing(3, 2, 1, 2),
      },
      underline: {
        '&:hover:not($disabled):before': {
          backgroundColor: 'none',
          borderBottom: 'none',
        },

        '&.Mui-disabled': {
          borderBottom: `1px solid ${palette.border.primary}`,
        },

        '&:after, &&.Mui-disabled:after': {
          borderBottom: 'none',
        },
        '&&.Mui-disabled:after': {
          borderBottom: `1px solid ${palette.border.primary}`,
        },
        '&:before, &.Mui-disabled:before': {
          borderBottom: 'none',
        },
      },
    },

    MuiTableCell: {
      root: {
        fontFamily: 'Inter',
        fontSize: 13,
        lineHeight: '22px',
      },

      head: {
        '&:first-child': {
          borderRadius: '6px 0 0 6px',
        },
        '&:last-child': {
          borderRadius: '0 6px 6px 0',
        },
      },

      stickyHeader: {
        verticalAlign: 'bottom',

        fontSize: 13,
        lineHeight: '22px',
        fontFamily: 'Inter',
        fontWeight: 600,
        // backgroundColor: palette.custom.background, // todo
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          content: '',
          flex: 0,
          padding: 0,
        },
      },
    },

    MuiTooltip: {
      tooltip: {
        padding: defaultTheme.spacing(1, 1.5),
        // backgroundColor: palette.text.contrast,  // todo
      },
      arrow: {
        '&::before': {
          // color: palette.text.contrast,  // todo
        },
      },
    },

    MuiDivider: {
      root: {
        margin: defaultTheme.spacing(0.5, 0),
        // backgroundColor: palette.custom.divider,  // todo
      },
    },

    MuiAutocomplete: {
      listbox: {
        ...mixins.scrollBar,
      },
    },

    MuiPickersToolbarButton: {
      toolbarBtn: {
        '&&': {
          padding: 0,
        },
      },
    },
  },
  props: {
    MuiTextField: {
      variant: 'filled',
    },
  },
});

// @ts-ignore
export const theme = createMuiTheme(themePlain);
