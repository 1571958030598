import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: 'calc(100vh - 180px)',
    },

    contentWrapper: {
      padding: theme.spacing(0, 4, 5, 4),
      marginBottom: theme.spacing(4),
    },
  }),
);
