import { ReactComponent as ChoiceSchools } from './assets/tools/choice-schools.svg';
import { ReactComponent as Complaint } from './assets/tools/complaint.svg';
import { ReactComponent as Compliance } from './assets/tools/compliance.svg';
import { ReactComponent as EnrollmentApplication } from './assets/tools/enrollment-application.svg';
import { ReactComponent as FamilyEngagement } from './assets/tools/family-engagement.svg';
import { ReactComponent as FoodServices } from './assets/tools/food-services.svg';
import { ReactComponent as OrgTracker } from './assets/tools/org-tracker.svg';
import { ReactComponent as Payments } from './assets/tools/payments.svg';
import { ReactComponent as PrivateDataVault } from './assets/tools/private-data-vault.svg';
import { ReactComponent as SchoolImprovement } from './assets/tools/school-improvement.svg';
import { ReactComponent as SchoolPerformance } from './assets/tools/school-performance.svg';
import { ReactComponent as Survey } from './assets/tools/survey.svg';
import { ReactComponent as Teachers } from './assets/tools/teachers.svg';
import { ReactComponent as Transportation } from './assets/tools/transportation.svg';

import { ReactComponent as ToolDefault } from './assets/tool-default.svg';
import { ReactComponent as CommentPlus } from './assets/comment-plus.svg';
import { ReactComponent as DotsGrid } from './assets/dots-grid.svg';
import { ReactComponent as DotsVertical } from './assets/dots-vertical.svg';
import { ReactComponent as Edit } from './assets/edit.svg';
import { ReactComponent as EyeOutline } from './assets/eye-outline.svg';
import { ReactComponent as EyeOff } from './assets/eye-off.svg';
import { ReactComponent as KeyOutline } from './assets/key-outline.svg';
import { ReactComponent as Search } from './assets/search.svg';
import { ReactComponent as GridPlus } from './assets/view-grid-plus.svg';
import { ReactComponent as ViewDashboard } from './assets/view-dashboard.svg';
import { ReactComponent as Chevron } from './assets/chevron.svg';
import { ReactComponent as Plus } from './assets/plus.svg';
import { ReactComponent as Calendar } from './assets/calendar.svg';
import { ReactComponent as Clock } from './assets/clock.svg';
import { ReactComponent as Avatar } from './assets/avatar.svg';
import { ReactComponent as LaunchpadRocket } from './assets/rocket-launch.svg';
import { ReactComponent as BreadcrumbsSeparator } from './assets/breadcrumbs-separator.svg';
import { ReactComponent as School } from './assets/school.svg';
import { ReactComponent as ReviewList } from './assets/review-list-icon.svg';
import { ReactComponent as Moderation } from './assets/moderation.svg';
import { ReactComponent as ModerationList } from './assets/moderation-list.svg';
import { ReactComponent as ListBrowsing } from './assets/list-browsing.svg';

import { ReactComponent as Web } from './assets/web.svg';
import { ReactComponent as Filter } from './assets/filter.svg';
import { ReactComponent as FilterFilled } from './assets/filter-filled.svg';
import { ReactComponent as User } from './assets/user.svg';
import { ReactComponent as LogOut } from './assets/logout.svg';
import { ReactComponent as LogOutNew } from './assets/logOutNew.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { ReactComponent as CloseCross } from './assets/closeCross.svg';
import { ReactComponent as Trash } from './assets/trash.svg';
import { ReactComponent as UserAccess } from './assets/user-access.svg';
import { ReactComponent as PaperClip } from './assets/paperclip.svg';
import { ReactComponent as Delete } from './assets/trash.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as File } from './assets/file.svg';
import { ReactComponent as Info } from './assets/information-outline.svg';
import { ReactComponent as Person } from './assets/person.svg';
import { ReactComponent as PersonGroup } from './assets/person-group.svg';
import { ReactComponent as Link } from './assets/link.svg';
import { ReactComponent as History } from './assets/history.svg';
import { ReactComponent as BuildOrg } from './assets/build-org.svg';
import { ReactComponent as StructureOrg } from './assets/structure-org.svg';
import { ReactComponent as Archive } from './assets/archive.svg';
import { ReactComponent as ArrowRevoke } from './assets/arrow-revoke.svg';
import { ReactComponent as HelpCircle } from './assets/help-circle-outline.svg';
import { ReactComponent as SettingsGear } from './assets/settingsGear.svg';
import { ReactComponent as CloseDrawer } from './assets/close-drawer.svg';
import { ReactComponent as HelpBox } from './assets/help-box.svg';
import { ReactComponent as Lock } from './assets/lock.svg';
import { ReactComponent as Unlock } from './assets/unlock.svg';
import { ReactComponent as RedirectIcon } from './assets/redirect-icon.svg';

import { ReactComponent as CodeTagIcon } from './format-text/code-tags.svg';
import { ReactComponent as FormatBoldIcon } from './format-text/format-bold.svg';
import { ReactComponent as FormatItalicIcon } from './format-text/format-italic.svg';
import { ReactComponent as FormatListBulletedIcon } from './format-text/format-list-bulleted.svg';
import { ReactComponent as FormatListNumeredIcon } from './format-text/format-list-numbered.svg';
import { ReactComponent as FormatQuoteCloseIcon } from './format-text/format-quote-close.svg';
import { ReactComponent as FormatUnderline } from './format-text/format-underline.svg';
import { ReactComponent as Numeric1Icon } from './format-text/numeric-1-box.svg';
import { ReactComponent as Numeric2Icon } from './format-text/numeric-2-box.svg';

import { ReactComponent as StatusAccepted } from './status/accepted.svg';
import { ReactComponent as StatusAction } from './status/action.svg';
import { ReactComponent as StatusDraft } from './status/draft.svg';
import { ReactComponent as StatusSubmitted } from './status/submitted.svg';
import { ReactComponent as StatusRectified } from './status/reactified.svg';
import { ReactComponent as StatusInvestigated } from './status/investigated.svg';
import { ReactComponent as StatusIncomplete } from './status/incomplete.svg';
import { ReactComponent as StatusWithdrawn } from './status/withdrawn.svg';
import { ReactComponent as StatusUnresolved } from './status/unresolved.svg';
import { ReactComponent as StatusResolved } from './status/resolved.svg';
import { ReactComponent as StatusDismissed } from './status/dismissed.svg';
import { ReactComponent as StatusAbandoned } from './status/abandoned.svg';
import { TIcon } from './index';

export const icons = {
  choiceSchools: ChoiceSchools,
  complaint: Complaint,
  compliance: Compliance,
  enrollmentApplication: EnrollmentApplication,
  familyEngagement: FamilyEngagement,
  foodServices: FoodServices,
  orgTracker: OrgTracker,
  payments: Payments,
  privateDataVault: PrivateDataVault,
  schoolImprovement: SchoolImprovement,
  schoolPerformance: SchoolPerformance,
  survey: Survey,
  teachers: Teachers,
  transportation: Transportation,

  toolDefault: ToolDefault,
  commentPlus: CommentPlus,
  dotsGrid: DotsGrid,
  dotsVertical: DotsVertical,
  edit: Edit,
  eyeOutline: EyeOutline,
  eyeOff: EyeOff,
  keyOutline: KeyOutline,
  search: Search,
  gridPlus: GridPlus,
  viewDashboard: ViewDashboard,
  plus: Plus,
  chevron: Chevron,
  clock: Clock,
  calendar: Calendar,
  avatar: Avatar,
  launchpadRocket: LaunchpadRocket,
  breadcrumbsSeparator: BreadcrumbsSeparator,
  reviewList: ReviewList,
  moderation: Moderation,
  moderationList: ModerationList,
  listBrowsing: ListBrowsing,

  web: Web,
  filter: Filter,
  filterFilled: FilterFilled,
  user: User,
  logOut: LogOut,
  logOutNew: LogOutNew,
  menu: Menu,
  closeCross: CloseCross,
  userAccess: UserAccess,
  trash: Trash,
  paperClip: PaperClip,
  delete: Delete,
  check: Check,
  file: File,
  info: Info,
  //
  school: School,
  person: Person,
  personGroup: PersonGroup,
  link: Link,
  history: History,
  buildOrg: BuildOrg,
  structureOrg: StructureOrg,
  archive: Archive,
  arrowRevoke: ArrowRevoke,
  helpCircle: HelpCircle,
  settingsGear: SettingsGear,
  closeDrawer: CloseDrawer,
  helpBox: HelpBox,
  lock: Lock,
  unlock: Unlock,
  redirectIcon: RedirectIcon,

  tagIcon: CodeTagIcon,
  boldIcon: FormatBoldIcon,
  italicIcon: FormatItalicIcon,
  listBulletedIcon: FormatListBulletedIcon,
  listNumeredIcon: FormatListNumeredIcon,
  quoteCloseIcon: FormatQuoteCloseIcon,
  underlineIcon: FormatUnderline,
  numeric1Icon: Numeric1Icon,
  numeric2Icon: Numeric2Icon,

  // complaint statuses
  acceptedStatus: StatusAccepted,
  actionStatus: StatusAction,
  draftStatus: StatusDraft,
  submittedStatus: StatusSubmitted,
  reactifiedStatus: StatusRectified,
  rectifiedStatus: StatusRectified,
  investigatedStatus: StatusInvestigated,
  incompleteStatus: StatusIncomplete,
  withdrawnStatus: StatusWithdrawn,
  unresolvedStatus: StatusUnresolved,
  resolvedStatus: StatusResolved,
  dismissedStatus: StatusDismissed,
  abandonedStatus: StatusAbandoned,
  //
  moduleDefault: DotsGrid,
  moduleSubmit: CommentPlus,
  moduleReview: Search,
  moduleResponse: Edit,
  moduleView: EyeOutline,
  moduleRespond: EyeOutline,
  moduleAccess: KeyOutline,
};
const iconsKeys: string[] = Object.keys(icons);

export const iconsVariants = iconsKeys as (TIcon | string)[];
