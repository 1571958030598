import { action, makeAutoObservable, observable } from 'mobx';

import ProfileSecurityService from 'src/service/profile-operator/security';
import { IActionMeta } from '../_helpers/interfaces';

export interface IUpdatePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export default class ProfileSecurityStore {
  @observable
  error: Record<string, any> | null | string = null;

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @action
  private reset() {
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  @action
  update = async (data: IUpdatePassword, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await ProfileSecurityService.updatePassword(data);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
    });
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor() {
    makeAutoObservable(this);
  }
}
