import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import clsx from 'clsx';

import {
  AnvBreadcrumbs,
  AnvButton,
  AnvButtonGroup,
  AnvViewHeader,
  TextH1,
} from '@platform/front-lib';
import ContentWrapperRestyle from 'src/components/layout/content-wrapper';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { ROUTES } from '../../constants';
import { profileRoutesMap } from './routes-map';

import useStylesCommon from './styles';

interface IProps {}

interface IRouteParamsTab {
  tab?: string;
}
const OperatorProfile: React.FC<IProps> = observer(() => {
  const classes = useStylesCommon();
  const theme = useTheme();
  const screenSM = useMediaQuery(theme.breakpoints.down('sm'));
  const match = useRouteMatch<IRouteParamsTab>({
    path: ROUTES.PROFILE_PREFIX,
    exact: true,
  });

  return (
    <>
      <AnvBreadcrumbs
        isOperator
        items={[
          {
            component: (
              <>
                <FormattedMessage
                  tagName={'span'}
                  id="LAUNCHPAD.PROFILE.TITLE"
                  defaultMessage="Personal Profile"
                />
              </>
            ),
          },
        ]}
      />

      <ContentWrapperRestyle noPadding>
        <AnvViewHeader
          textAlign={'left'}
          customTitle={
            <div className={classes.commonWrapper}>
              <TextH1>
                <FormattedMessage
                  id="LAUNCHPAD.PROFILE.TITLE"
                  defaultMessage="Personal Profile"
                />
              </TextH1>
            </div>
          }
        >
          <div className={clsx(classes.commonWrapper, classes.contentWrapper)}>
            <AnvButtonGroup
              orientation={screenSM ? 'vertical' : 'horizontal'}
              className={classes.tabs}
              fullWidth={screenSM}
            >
              {profileRoutesMap?.map((item) => {
                const isActive = item.tab === match?.params?.tab;
                return (
                  <AnvButton
                    fullWidth={screenSM}
                    key={item.tab}
                    // @ts-ignore
                    component={NavLink}
                    to={item.path}
                    variant={isActive ? 'contained' : 'outlined'}
                    inactive={isActive}
                  >
                    {item.tabLabel}
                  </AnvButton>
                );
              })}
            </AnvButtonGroup>

            <Switch>
              <Redirect
                exact
                from={ROUTES.PROFILE_ROOT}
                to={ROUTES.PROFILE_BASE}
              />
              {profileRoutesMap?.map((item) => {
                return (
                  <Route
                    key={item.tab}
                    path={item.path}
                    exact
                    component={item.component}
                  />
                );
              })}
            </Switch>
          </div>
        </AnvViewHeader>
      </ContentWrapperRestyle>
    </>
  );
});

export default OperatorProfile;
