import { Column } from 'react-table';

import type { ISelectOption } from '@platform/front-lib';

import { IFormatMessage } from 'src/models/_helpers/react-intl';
import { DATE_FORMATS } from '../../constants';
import { formatPhoneNumber, formatWLocale } from '../../utils';
import type { IAccountStatus } from '@platform/front-lib/dist/models';
import { AnvUtils, Constants } from '@platform/front-lib';

export function userColumns(
  locale: string | undefined,
  formatMessage: IFormatMessage,
  {
    accountStatus,
    accountStatusMap,
  }: {
    accountStatus: ISelectOption[];
    accountStatusMap: Record<string, IAccountStatus>;
  },
) {
  const columns: Record<string, any>[] = [
    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.USERS.LIST.HEADER.LABEL.LAST_NAME',
          defaultMessage: 'Last Name',
        });
      },
      sortable: true,
      filterable: true,
      filterMinLength: 2,
      headerString: formatMessage({
        id: 'SUPPORT.USERS.LIST.HEADER.LABEL.LAST_NAME',
        defaultMessage: 'Last Name',
      }),
      accessor: 'lastName',

      Cell: ({ row }: Record<string, any>) => {
        return row?.original?.lastName || '';
      },
    },
    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.USERS.LIST.HEADER.LABEL.FIRST_NAME',
          defaultMessage: 'First Name',
        });
      },
      headerString: formatMessage({
        id: 'SUPPORT.USERS.LIST.HEADER.LABEL.FIRST_NAME',
        defaultMessage: 'First Name',
      }),
      sortable: true,
      filterable: true,
      filterMinLength: 2,
      accessor: 'firstName',

      Cell: ({ row }: Record<string, any>) => {
        return row?.original?.firstName || '';
      },
    },
    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.USERS.LIST.HEADER.LABEL.EMAIL',
          defaultMessage: 'Email',
        });
      },
      sortable: true,
      filterable: true,
      filterMinLength: 2,
      headerString: formatMessage({
        id: 'SUPPORT.USERS.LIST.HEADER.LABEL.EMAIL',
        defaultMessage: 'Email',
      }),
      accessor: 'email',
      id: 'email',
    },
    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.USERS.LIST.HEADER.LABEL.PHONE',
          defaultMessage: 'Phone',
        });
      },
      sortable: true,
      filterable: true,
      filterMinLength: 2,
      headerString: formatMessage({
        id: 'SUPPORT.USERS.LIST.HEADER.LABEL.PHONE',
        defaultMessage: 'Phone',
      }),
      accessor: 'phone',
      id: 'phone',
      Cell: ({ row }: Record<string, any>) => {
        const phone = row?.original?.phone;
        return formatPhoneNumber(phone);
      },
    },
    {
      filterable: true,
      filterType: Constants.FILTER_TYPES.select,
      filterOptions: accountStatus,
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.USERS.LIST.HEADER.LABEL.ACCOUNT_STATUS',
          defaultMessage: 'Account Status',
        });
      },
      headerString: '',
      accessor: 'registrationStatus',
      id: 'registrationStatus',

      Cell: ({ row }: Record<string, any>) => {
        const value = row?.original?.registrationStatus;
        return (
          AnvUtils.getNameByLangNullable(
            accountStatusMap[value],
            'name',
            locale,
          ) || value
        );
      },
    },
    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.USERS.LIST.HEADER.LABEL.CREATED_AT',
          defaultMessage: 'Created At',
        });
      },
      sortable: true,
      headerString: formatMessage({
        id: 'SUPPORT.USERS.LIST.HEADER.LABEL.CREATED_AT',
        defaultMessage: 'Created At',
      }),
      accessor: 'createdAt',
      id: 'createdAt',

      Cell: ({ row }: Record<string, any>) => {
        return formatWLocale(
          row?.original?.createdAt,
          DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
        );
      },
    },
    {
      Header: () => {
        return formatMessage({
          id: 'SUPPORT.USERS.LIST.HEADER.LABEL.LAST_ACCESS',
          defaultMessage: 'Last access',
        });
      },
      sortable: true,
      headerString: formatMessage({
        id: 'SUPPORT.USERS.LIST.HEADER.LABEL.LAST_ACCESS',
        defaultMessage: 'Last access',
      }),
      accessor: 'lastAccessAt',
      id: 'lastAccessAt',

      Cell: ({ row }: Record<string, any>) => {
        return formatWLocale(
          row?.original?.lastAccessAt,
          DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
        );
      },
    },
  ];

  return columns as Column<Record<string, any>>[];
}
