import { axiosInstance } from '@platform/front-lib';
import { axiosCatch } from '../../../service/utils';
import { CancelToken } from 'axios';

const axios = axiosInstance;

export class ComplaintCategoriesService {
  private apiUrl =
    process.env.REACT_APP_API_TOOL_ENDPOINT + '/complaint/categories';

  async getList(
    { ...params }: { [index: string]: any },

    source: { token?: CancelToken },
  ) {
    return axiosCatch(
      axios.get(`${this.apiUrl}`, {
        params,
        cancelToken: source.token,
      }),
    );
  }

  async getOne({ id }: { id: string }) {
    return axiosCatch(axios.get(`${this.apiUrl}/${id}`, {}));
  }
}
