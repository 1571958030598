import { action, makeObservable, observable } from 'mobx';

import { ComplaintCategoriesSettingsService } from './service';
import { CommonListAdvancedStore } from '@platform/front-lib';
import { IComplaintCategorySetting } from '@platform/front-lib/dist/models';
import { IActionMeta } from '../../_helpers/interfaces';
import {
  IComplaintCategorySettingCreate,
  IComplaintCategorySettingDelete,
  IComplaintCategorySettingUpdate,
} from './types';

export class ComplaintCategorySettingsStore extends CommonListAdvancedStore<
  ComplaintCategoriesSettingsService,
  IComplaintCategorySetting & { _id: string }
> {
  service = new ComplaintCategoriesSettingsService();
  error: Record<string, any> | null | string = null;

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  getOne = async (
    payload: { id: string; settingId: string },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.service.getOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      if (response?._id) {
        this.dataMap[response._id] = response;
      }

      meta?.successFunc?.(response);
    });
  };

  createOne = async (
    payload: IComplaintCategorySettingCreate,
    meta: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.service.createOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
    });
  };

  updateOne = async (
    payload: IComplaintCategorySettingUpdate,
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.service.updateOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
    });
  };

  deleteOne = async (
    payload: IComplaintCategorySettingDelete,
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.service.deleteOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.error = error || response.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
    });
  };

  reset = () => {
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  };

  cleanError = () => {
    this.isError = false;
    this.error = null;
  };

  // common
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor() {
    super({
      sessionKey: 'complaint-categories-settings',
      disableAutoSave: true,
    });

    makeObservable(this, {
      getOne: action,
      updateOne: action,
      createOne: action,
      deleteOne: action,

      reset: action,
      cleanError: action,

      setError: action,
      setLoaded: action,
      setLoading: action,

      error: observable,
      isFetching: observable,
      isLoaded: observable,
      isError: observable,
    });
  }
}
