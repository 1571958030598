import { axiosCatch } from '../utils';

import { envConst } from '../../constants';
import { axiosInstance } from '@platform/front-lib';
import { IUpdatePassword } from '../../stores/profile-operator/security';

const axios = axiosInstance;

class ProfileBaseService {
  private authUrl = envConst.apiUrl + '/profile';

  async updatePassword(data: IUpdatePassword) {
    return axiosCatch(axios.patch(`${this.authUrl}/change-password`, data, {}));
  }
}

export default new ProfileBaseService();
