import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from '../../constants';

import { ProfileBase } from './tabs/base';
import { ProfileSecurity } from './tabs/security';

export const profileRoutesMap = [
  {
    tab: 'base-info',
    tabLabel: (
      <FormattedMessage
        id="LAUNCHPAD.PROFILE.TAB.BASE"
        defaultMessage="Base Info"
      />
    ),
    path: ROUTES.PROFILE_BASE,
    component: ProfileBase,
  },
  {
    tab: 'security',
    tabLabel: (
      <FormattedMessage
        id="LAUNCHPAD.PROFILE.TAB.SECURITY"
        defaultMessage="Security"
      />
    ),
    path: ROUTES.PROFILE_SECURITY,
    component: ProfileSecurity,
  },
];
