import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStylesContent = makeStyles((theme) =>
  createStyles({
    contentWrapper: {
      padding: '0 24px 16px 24px',
    },
    contentItemWrapper: {
      marginTop: theme.spacing(1),

      '& + .sub-item': {
        marginTop: theme.spacing(0.5),
      },
    },

    itemSubItemWrapper: {
      marginTop: theme.spacing(0.5),

      '& + .root-item': {
        marginTop: theme.spacing(1),
      },
      '& + .sub-item': {
        marginTop: theme.spacing(0.5),
      },
    },

    label: {
      color: theme.palette.text.body,
    },
    labelSubItem: {
      marginLeft: theme.spacing(1),
    },

    value: {
      textAlign: 'right',
      color: theme.palette.text.primary,
      fontWeight: 600,
    },

    valueSubItem: {},
  }),
);
